@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap');

*{
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}
.leftNavMain{
    width: 100% !important;
    max-width: 250px !important;
    background: #747474 !important;
    position: fixed !important;
    height: 100%;
    overflow: auto;
    left: 0;
    top: 0;
    color: #fff;
    padding: 0 !important;
    transition: .8s;
    z-index: 9;
    overflow-x: hidden;
}
.leftNavMain svg{
    color: #fff;
}
.leftTopSec{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    padding: 10px 9px;
    position: sticky;
    top: 0;
    background: #000000;
    z-index: 9;
    height: 70px;
}
.leftTopSec img{
    width: 40px;
    max-width: 40px;
}
.leftTopSec b{
    font-size: 17px;
    margin-left: 7px;
    font-weight: 600;
    white-space: nowrap;
    transition: .8s;
}
.leftNavMain .MuiTypography-root{
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    letter-spacing: .4px;
}
.leftNavMain .MuiListItemIcon-root {
    min-width: 40px;
}
.leftNavMain .MuiListItemButton-root{
    padding: 12px 17px;
    border-bottom: 1px solid #858585;
}
.leftNavMain .MuiListItemButton-root:hover{
    background: #c82d33;
}
.topMain{
    width: 100%;
    float: left;
    padding-left: 250px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    transition: .8s;
}
.topMain .MuiPaper-elevation{
    color: #000;
    background: #fff;
    box-shadow: none;
    border-bottom: 1px solid #ccc;
    padding: 3px 0;
    color: #737373;
}
.qrscantextbox{
    opacity: 0;
    /* visibility: hidden; */
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.topSearch{
    border: 1px solid #ccc;
    margin: 0 !important;
    width: 100% !important;
    max-width: 500px;
}
.topSearch .MuiInputBase-root{
    width: 100%;
}
.topSearch svg{
    color: #a7a7a7;
}
.topTimeLine{
    display: flex;
    align-items: center;
    margin: 0 7px;
    font-size: 12px;
    font-weight: 500;
}
.rightContentMain{
    width: 100%;
    float: left;
    padding-left: 270px;
    padding-top: 87px;
    min-height: 500px;
    padding-right: 20px;
    padding-bottom: 20px;
    transition: .8s;
    position: absolute;
    left: 0;
}
.orderTabMain{
    width: 100%;
    float: left;
}
/* .orderTabMain .MuiBox-root{
    border: none;
} */
.orderTabMain .MuiTabs-flexContainer .MuiButtonBase-root{
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0;
    padding: 10px 10px;
    border-radius: 10px 10px 0 0;
    margin: 0 8px;
}
/* .orderTabMain .MuiTabs-flexContainer .Mui-selected{
    color: #ffffff;
    background: #001f3f;
} */
.orderTabMain .MuiButtonBase-root:first-child{
    margin-left: 0;
}
.togBtTop{
    margin-right: 6px !important;
}
/* .tabContentMain{

} */
.tabContentMain .MuiBox-root{
    padding: 20px 0;
    float: left;
    width: 100%;
}
.tabContDiv{
    width: 100%;
    float: left;
    margin-top: 15px;
}
.orderFlterBt{
    padding: 0px 7px !important;
    margin: 0 !important;
    color: #878787 !important;
    width: 100%;
    border: 1px solid #b7b7b7 !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    height: 65px;
    text-transform: capitalize !important;
    display: flex !important;
    flex-direction: column;
}
.orderListing{
    width: 100%;
    float: left;
    margin-top: 20px;
    overflow: auto;
    height: calc(100vh - 410px);
    padding-right: 15px;
}
.orderListing::-webkit-scrollbar {
    width: 2px;
    background:#dddddd;
    opacity: 0 !important;
}

.orderListing::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #e8e8e8; 
    border-radius: 15px;
    opacity: 0 !important;
}

.orderListing::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5); 
    background:red;
    opacity: 0 !important;
}

.seectedOrderType{
    background: #c82d33 !important;
    color: #fff !important;
    border: 1px solid #c82d33 !important;
}
.orderDivOuter{
    width: 100%;
    float: left;
    transition: .8s;
    cursor: pointer;
}
.orderDivOuter{
    width: 100%;
    float: left;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 15px;
    padding-left: 70px;
    position: relative;
    box-shadow: 5px 8px 10px rgba(0,0,0,0.03);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.orderDivOuter h1{
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 5px;
}
.orderDivOuter h2{
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    margin-bottom: 5px;
}
.orderDivOuter p{
    font-size: 12px;
    color: #878787;
    font-weight: 400;
}
.startCount{
    font-size: 18px;
    color: #3498DB;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.startCount svg{
    color: #FFE600;
    font-size: 26px;
    margin-right: 4px;
}
.orderIcoDiv{
    width: 40px;
    height: 40px;
    background: #EFF2F5;
    border-radius: 100%;
    position: absolute;
    left: 15px;
    overflow: hidden;
}
.orderIcoDiv img{
    width: 100%;
    height: 40px;
    object-fit: cover;
}
.seectedOrder{
    border: 2px solid #6fc5ff;
    position: relative;
    background: #F3FFEF;
}
.seectedOrder::after{
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-left: 2px solid #6fc5ff;
    border-top: 2px solid #6fc5ff;
    border-bottom: 3px solid transparent;
    transform: rotate(136deg) skew(10deg,11deg);
    right: -9px;
    background: #f3ffef;
}
.orderDivOuter:hover{
    background: #f3ffef;
}
.orderDetailsSection{
    width: 100%;
    float: left;
    box-shadow: 0px 0px 17px rgba(0,0,0,0.05);
    padding: 20px;
    border-radius: 10px;
    z-index: 1;
    position: relative;
}
.orderTop{
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.orderTop h1{
    font-size: 23px;
    color: #333333;
    font-weight: 600;
}
.printIco{
    width: 50px;
    height: 50px;
    border-radius: 5px !important;
    background-color: #EFF2F5 !important;
    border: 1px solid #555555 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    min-width: auto !important;
}
.printIco svg{
    color: #555555;
}
.orderSumry{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
}
.orderSumry label{
    font-size: 14px;
    color: #878787;
    font-weight: 400;
    width: 100%;
    float: left;
}
.orderSumry p{
    font-size: 14px;
    color: #101010;
    font-weight: 500;
}
.nameOfCust{
    width: 100%;
    float: left;
    font-size: 17px;
    color: #333333;
    margin-bottom: 20px;
    margin-top: 10px;
}
.pickpSlot{
    font-size: 17px !important;
}
.pickpSlotTime{
    font-size: 19px !important;
    color: #333333 !important;
    font-weight: 600 !important;
}
.readyForPicOut{
    width: 100%;
    float: left;
    text-align: center;
    margin: 20px 0;
}
.scanQrBtn{
    background: #f9f9f9 !important;
    color: #000000 !important;
    border-radius: 10px 10px 0 0 !important;
    width: 155px;
    text-transform: capitalize !important;
    padding: 8px 20px !important;
    cursor: pointer !important;
    float: left;
}
.ordSmNew{
    position: absolute;
    top: 17px;
    left: 15px;
    background: #fff;
    padding: 0 6px;
    font-weight: 600;
    font-size: 13px;
    transition: .5s;
    border-radius: 5px;
}
.newOrderSumMain:hover .ordSmNew{
    background: #efffe7;
}
.readyForPicBt{
    height: 65px;
    width: 100%;
    background: #3498db !important;
    color: #fff !important;
    font-size: 30px !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    max-width: 400px;
    font-weight: 500 !important;
}
.qrOter{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}
.qrDiv{
    width: 100%;
    max-width: 200px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    display: inline-flex;
}
.qrDiv img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.orderItemDetails{
    width: 100%;
    float: left;
    border-radius: 10px;
    background: #F9F9F9;
    padding: 20px;
    margin-bottom: 30px;
}
.orderItemDetails h1{
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 15px;
}
.orderItemDiv{
    width: 100%;
    float: left;
}
.orderItemDiv span {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.orderItemDiv span p{
    font-size: 14px;
    color: #878787;
    font-weight: 400;
}
.orderItemDiv span b{
    font-size: 16px;
    color: #333333;
    font-weight: 600;   
}
.totalAmt{
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #3498DB !important;
}
.productDetails{
    width: 100%;
    float: left;
}
.productDetails table tr th{
    text-align: left;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    padding: 0px 0 10px;
}
.productDetails table tr td{
    font-size: 14px;
    color: #878787;
    font-weight: 500;
    padding: 10px 0;
}
.starSpen{
    font-size: 14px !important;
    color: #333333 !important;
    font-weight: 700 !important;
}
.productDetails table tbody tr td{
    border-bottom: 1px solid #efefef;
}
.productDetails table tbody tr:last-child td{
    border: none;
}
.newOrdCout{
    width: auto;
    height: auto;
    background: #121210;
    border-radius: 50px;
    font-size: 20px;
    margin-right: 5px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: arial;
    margin-top: -30px;
    margin-bottom: 8px;
    font-style: normal;
    padding: 1px 10px;
}
.smoothSlide{
    transition: .8s;
}
.smoothSlide .leftNavMain{
    /* left: -100%; */
    max-width: 60px !important;
    transition: .8s;
    overflow-x: hidden;
}
.smoothSlide .rightContentMain{
    padding-left: 80px;
}
.smoothSlide .topMain{
    padding-left: 60px;
}
.smoothSlide .leftTopSec b{
    opacity: 0;
}
.smoothSlide .MuiListItemText-root {
    /* display: none; */
    opacity: 0;
}
.smoothSlide .leftNavMain .MuiListItemButton-root{
    padding: 12px 17px;
}
.smoothSlide .leftTopSec{
    padding: 20px 9px;
}
.leftNavMain .MuiListItemText-root{
    transition: .8s;
    white-space: nowrap;
}

.sticyTab{
    position: sticky;
    top: 95px;
}
.sticyTab .MuiTabs-flexContainer{
    justify-content: space-between;
    padding: 0px 58px;
}
.tabImg{
    display: flex !important;
    flex-direction: column;
}
.tabImg .tabImgInr{
    width: 70px;
    height: 70px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 8px;
}
.tabImg p{
    font-size: 11px;
    color: #001F3F;
    margin: 9px 0 0;
    font-weight: 600;
}
.tabImg .tabImgInr img{
    width: 100%;
    height: 70px;
}
.scanTopBar{
    width: 100%;
    float: left;
    min-height: 330px;
    background: #001F3F;
    position: fixed;
    z-index: 0;
    padding: 0 50px
}
.scanOrderMain{
    width: 100%;
    float: left;
    text-align: center;
}
.scanOrderContDiv{
    width: calc(100% - 100px);
    max-width: 800px;
    display: inline-block;
    padding: 15px;
    background: #fff;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.1);
    min-height: 500px;
    border-radius: 10px;
    z-index: 9;
    position: relative;
    margin: 20px 0 0;
}
.scanOrderContDiv .orderDetailsSection{
    padding: 0;
    box-shadow: none;
    text-align: left;
}
.scanOrderContDiv .orderTop h1{
    display: flex;
    align-items: center;
}
.scanOrderContDiv .orderTop h1 svg{
    margin-right: 10px;
    cursor: pointer;
}
.pageMainHeading{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
}
.pageMainHeading h1{
    font-size: 16px;
    color: #333333;
    font-weight: 500;
}
.pageTopMainBt{
    height: 45px;
    background: #c82d33 !important;
    min-width: 160px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 5px !important;
}
.pageTopMainBt:last-child{
    margin-right: 0 !important;
}
.mainListDiv{
    width: 100%;
    height: auto;
    float: left;
    padding: 20px 15px;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 13px 10px rgba(0,0,0,0.03);
    position: relative;
}
.mainListCont{
    float: left;
    margin-right: 20px;
}
.mainListCont h1{
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    cursor: pointer;
}
.mainListCont p{
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}
.ticketDetailsMain .parContTx{
    max-width: initial;
}
.mainListContact{
    font-size: 15px;
    color: #878787;
    font-weight: 400;
    margin-bottom: 5px;
}
.mainListInrCont{
    margin: 0 20px;
}
.mainListInrCont:last-child{
    margin-right: 0;
}
.mainListInrCont label{
    font-size: 12px;
    color: #878787;
    font-weight: 400;
    width: 100%;
    float: left;
    margin-bottom: 5px;
}
/* .mainListInrCont span{
    font-size: 14px;
    color: #333333;
    font-weight: 500;
} */
.mainListSubTx{
    font-size: 14px !important;
    color: #333333 !important;
    font-weight: 500 !important;
    width: 100%;
    float: left;
}
.actionBtnOut{
    display: flex;
}
.editBtList{
    color: #555555;
}
.deleteBtList{
    color: #c82d33;
}
.swicthMain .Mui-checked{
    color: green !important;
}
.swicthMain .MuiSwitch-track{
    background-color: #008000 !important;
}
.listMainStar{
    display: inline-flex;
    align-items: center;
}
.listMainStar svg{
    color: #FFE600;
    font-size: 19px;
}
.noDataFond{
    width: 100%;
    float: left;
    text-align: center;
}
.noDataFond img{
    width: 100%;
    max-width: 415px;
}

.leftNavMain::-webkit-scrollbar {
    width: 2px;
    background:#001F3F;
    opacity: 0 !important;
}

.leftNavMain::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #001F3F; 
    border-radius: 15px;
    opacity: 0 !important;
}

.leftNavMain::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); 
    background:#001F3F;
    opacity: 0 !important;
}
.ordDetaTx{
    width: 100%;
    float: left;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.ordDetaTx svg{
    margin-right: 10px;
    cursor: pointer;
}
.serviceTopDiv{
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    min-height: 150px;
}
.serviceTopDiv img{
    width: 100%;
    object-fit: contain;
    height: 150px;
}
.servicesTabMain{
    width: 100%;
    float: left;
}
.servicesTabMain {
    width: 100%;
    float: left;
}
.servicesTabInnerMain{
    position: fixed;
    top: 0;
    width: 100%;
    padding: 125px 20px 20px 270px;
    left: 0;
    background: #fff;
    z-index: 2;
    transition: .8s;
}
.servicesTabMain .servicesTabInnerMain .MuiTabs-flexContainer{
    display: flex;
    justify-content: center;
    gap: 15px;
}
.servicesTabMain .servicesTabInnerMain .tabImg .tabImgInr{
    width: 100%;
    min-height: 95px;
    border-radius: 10px;
}
.servicesTabMain .servicesTabInnerMain .tabImg{
    width: 200px;
    padding: 0;
}
.servicesTabMain .servicesTabInnerMain .tabImg:last-child{
    margin-right: 0;
}
.servicesTabMain .servicesTabInnerMain .tabImg:first-child{
    margin-left: 0;
}
.servicesTabMain .servicesTabInnerMain .tabImg .tabImgInr img{
    width: 100%;
    height: 95px;
    object-fit: contain;
}
.servicesTabMain .servicesTabInnerMain .tabImg p{
    margin-bottom: 13px;
    font-size: 17px;
    color: #001F3F;
    text-transform: capitalize;
}
.servicesTabMain .servicesTabInnerMain .tabImg:hover{
    background: none;
}
.servicesTabMain .servicesTabInnerMain .MuiTabs-indicator{
    height: 3px;
    background-color: #c82d33;
    border-radius: 50px;
}
.servicesTabMain .servicesTabInnerMain .MuiTabs-indicator::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #c82d33;
    top: -8px;
    left: 50%;
    margin-left: -10px;
}
.serviceSearchBar{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
}
.prodSearchDiv{
    float: left;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.prodSearchInpt{
    width: 100%;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 15px;
    outline: none;
    font-size: 13px;
    color: #333;
}
.pageLabel{
    width: 100%;
    float: left;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
}
.prodFilter{
    width: 100%;
}
.addImpotBtOuter{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
select {
    background: url("../images/selectIcon.svg") no-repeat !important;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 2rem !important;
}
.serviceistOuter{
    width: 100%;
    float: left;
}
.blueBt{
    background: #c82d33 !important;
}
.servicesTabMain .tabContDiv{
    margin-top: 195px;
}
.smoothSlide .servicesTabInnerMain{
    padding-left: 80px;
}
.positionHeading{
    position: fixed;
    z-index: 3;
    transition: .8s;
}
.modalMain{
    width: 100%;
}
.modalMain .MuiDialogTitle-root{
    font-size: 21px;
    color: #333333;
    text-align: center;
    font-weight: 600;
    background: #F3FFEF;
}
.modalMain .MuiDialog-paperScrollBody{
    border-radius: 10px;
}
.modalCloseBt{
    position: absolute;
    right: 20px;
    cursor: pointer;
}
.smallModal .MuiDialog-paperScrollBody{
    max-width: 750px;
    width: 100%;
    /* max-width: calc(100% - 64px); */
}
.modalSinlInputDiv{
    width: 100%;
    /* max-width: 500px; */
    display: inline-block;
    text-align: left;
}
.forModalSpace{
    width: 100%;
    text-align: center;
    padding: 50px 50px;
}
.modalBtFullWidth{
    width: 100% !important;
    height: 52px !important;
    background: #c82d33 !important;
    border-radius: 8px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.bigModal .MuiDialog-paperScrollBody{
    max-width: 900px;
    width: 100%;
    /* max-width: calc(100% - 64px); */
}
.bigModal .forModalSpace{
    padding: 50px 50px;
}
.bigModal .modalSinlInputDiv{
    max-width: initial;
}
.modalBtSmallWidth {
    width: 100% !important;
    height: 52px !important;
    background: #c82d33 !important;
    border-radius: 8px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    max-width: 500px;
}
.modalMain .prodSearchInpt{
    height: 52px;
}
.modalMain .pageLabel {
    font-size: 13px;
    color: #333333;
    font-weight: 500;
}
.modalSuccessDiv{
    width: 100%;
    text-align: center;
    padding: 0 50px;
}
.modalBtOutline {
    width: 100% !important;
    height: 52px !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    max-width: 500px;
    border: 1px solid #c82d33 !important;
    color: #c82d33 !important;
}
.modalCenterContent{
    max-width: 460px;
    display: inline-block;
    width: 100%;
}
.modalCenterContent p{
    font-size: 12px;
    color: #878787;
    margin-bottom: 10px;
    line-height: 20px;
}
.modalCenterContent p b{
    font-size: 15px;
    color: #333333;
    font-weight: 600;
}
.modalCenterContent p span{
    font-size: 15px;
    color: #333333;
    font-weight: 500;
}
.finaTotlAmunt{
    width: 100%;
    float: left;
    background: #f1f1f1;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    border-radius: 5px;
    padding: 8px 20px;
    text-align: right;
}
.finaTotlAmunt b{
    color: #C82D33;
}
.finaShort .smalSho{
    display: block !important;
    line-height: 0;
}
.finaShort .smalSho svg{
    font-size: 19px;
    line-height: 0;
}
.forBackArrowAlign{
    display: flex;
    align-items: center;
}
.forBackArrowAlign svg{
    margin-right: 10px;
    cursor: pointer;
}
.productDetailsTop{
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #eee;
    float: left;
    box-shadow: 0px 43px 18px -40px rgba(0,0,0,0.1);
}
.productDetailsHeading{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
}
.productDetailsHeadLeft{
    width: 100%;
    float: left;
}
.productDetailsHeadRight{
    width: 100%;
    float: left;
    text-align: right;
}
.productDetailsHeadLeft h1{
    font-size: 33px;
    color: #c82d33;
    font-weight: 600;
}
.productDetailsHeadLeft h1 span{
    font-size: 18px;
    color: #878787;
    font-weight: 400;
    margin-left: 16px;
}
.productDetailsHeadLeft h1 span b{
    font-size: 20px;
    color: #c82d33;
    font-weight: 600;
}
.productId{
    font-size: 18px;
    color: #878787;
    font-weight: 400;
}
.productDetailsTop .mainListDiv{
    margin-top: 20px;
    background: #001f3f0a;
    padding: 15px;
    border: 1px solid #e2e2e2;
}
.productDetailsTop .mainListInrCont:first-child{
    margin-left: 0;
}
.productDetailsTop .mainListInrCont label{
    font-size: 14px;
    margin-bottom: 8px;
}
.productImgDiv{
    width: 100%;
    text-align: center;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ccc;
}

.productImgDiv .carousel .slider-wrapper {
    width: 145px;
}
.productImgDiv .carousel .slide .legend{
    display: none;
}
.productImgDiv .carousel.carousel-slider {
    overflow: visible;
}
.productImgDiv .carousel .control-dots {
    bottom: -28px;
}
.productImgDiv .carousel .carousel-status{
    text-shadow: none;
    font-weight: 400;
    font-size: 14px;
    background: #fafafa;
    border-radius: 5px;
    color: #a1a1a1;
    min-width: 69px;
    left: 0;
    max-width: 70px;
    display: none;
}
.productImgDiv .carousel .thumb.selected, .carousel .thumb:hover{
    border: 2px solid #3498DB;
    border-radius: 3px;
}
.productImgDiv .carousel .control-dots .dot{
    box-shadow: none;
    background: #616161;
    width: 10px;
    height: 3px;
    border-radius: 50px;
}
.productImgDiv .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover{
    background: #01182f;
}

.productImgInnerDiv{
    width: 100%;
    max-width: 200px;
    display: inline-block;
}
.productImgInnerDiv img{
    width: 100%;
}
.productSliderOuter{
    width: 100%;
    float: left;
}
.productSliderInner{
    width: 190px !important;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
}
.discountTx{
    width: 100%;
    float: left;
    font-size: 11px;
    color: #333333;
    font-weight: 500;
}
.discountTx b{
    font-size: 13px;
    color: #000;
}
.prodductSlidImgDiv{
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    margin: 15px 0;
}
.prodductSlidImgDiv img{
    width: 100%;
    max-width: 75px;
}
.prodPriceDiv{
    width: 100%;
    float: left;
}
.prodPriceDiv h1{
    font-size: 14px;
    color: #001F3F;
    font-weight: 600;
}
.prodPriceDiv span{
    font-size: 20px;
    color: #3498DB;
    font-weight: 600;
}
.prodPriceDiv p{
    font-size: 12px;
    font-weight: 500;
    color: #878787;
}
/* .productSliderOuter .slick-track{
    display: flex;
} */
.productSliderOuter .slick-arrow{
    display: none !important;
}
/* .productSliderOuter .slick-slide{
    max-width: 220px;
} */
.scrolled .servicesTabInnerMain .tabImg .tabImgInr{
    min-height: 50px;
    width: 50px;
    border-radius: 10px;
    height: 50px;
}
.scrolled .servicesTabInnerMain .tabImg .tabImgInr img{
    height: 50px;
}
.scrolled .servicesTabInnerMain .MuiTabs-flexContainer{
    width: 100%;
    max-width: 370px;
}
.scrolled .servicesTabInnerMain .tabImg p{
    font-size: 10px;
}
.scrolled .MuiTabs-scroller{
    display: flex;
    justify-content: center;
}
.scrolled .servicesTabInnerMain .tabImg{
    margin: 0;
    width: auto;
}
.scrolled .servicesTabInnerMain{
    padding: 126px 20px 20px 270px;
}
.scrolled{
    transition: .8s;
}
.employeeProfileLeft{
    width: 100%;
    float: left;
}
.employeeProfileLeft .basicDetailsDiv .mainListInrCont .mainListSubTx span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 165px;
    float: left;
}
.employeeTopDiv{
    width: 100%;
    float: left;
    padding: 20px;
    background: #F3FFEF;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}
.employeePro{
    width: 100%;
    float: left;
}
.employeePicDiv{
    width: 115px;
    height: 115px;
    border-radius: 100%;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 5px;
}
.employeePicDiv img{
    width: 100%;
    height: 115px;
    object-fit: cover;
}
.showDetailsEmployee{
    width: 100%;
    float: left;
}
.showDetailsEmployee h1{
    font-size: 20px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 5px;
}
.showDetailsEmployee span{
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    width: 100%;
    float: left;
    margin-bottom: 4px;
}
.showDetailsEmployee p{
    font-size: 14px;
    color: #878787;
    font-weight: 400;
    margin-bottom: 5px;
    float: left;
    width: 100%;
}
.showDetailsEmployee label {
    font-size: 12px;
    color: #c82d33;
    font-weight: 600;
    display: flex;
    justify-content: center;
    float: left;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
}
.showDetailsEmployee label .listMainStar svg{
    margin: 0 5px;
    font-size: 21px;
}
.showDetailsEmployee label b{
    font-size: 15px;
    color: #c82d33;
    font-weight: 600;
}
.basicDetailsDiv{
    width: 100%;
    float: left;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #eee;
}
.basicHeading{
    width: 100%;
    float: left;
    font-size: 14px;
    color: #333333;
    font-weight: 400;
}
.basicDetailsDiv .mainListInrCont{
    margin: 0;
}
.devider{
    width: 100%;
    /* margin: 20px 0 !important; */
}
.profileTabMain{
    width: 100%;
    float: left;
}
.profileTabMain .tabContDiv{
    margin-top: 0;
}
.profileTabMain .MuiTab-textColorPrimary{
    font-size: 16px;
    color: #878787;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-right: 15px;
    border-radius: 10px 10px 0 0;
}
.profileTabMain .Mui-selected{
    font-size: 16px;
    color: #c82d33 !important;
    font-weight: 600;
    text-transform: capitalize;
}
.profileTabMain .MuiTabs-indicator {
    background: #c82d33 ;
    height: 3px;
}
.detailsTableMain{
    width: 100%;
    float: left;
    border: 1px solid #eee;
    border-radius: 10px;
    overflow: hidden;
}
.detailsTableFilterSec{
    width: 100%;
    float: left;
    background: #F9F9F9;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filterBtn{
    border-radius: 50px !important;
    height: 29px;
    background: #fff !important;
    border: 1px solid #ccc !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    color: #878787 !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    margin: 0 5px !important;
    min-width: 100px !important;
}
.filterBtn:first-child{
    margin-left: 0 !important;
}
.filterBtn:last-child{
    margin-right: 0 !important;
}
.detailsTableFilterSec .Mui-selected{
    background: #3498DB !important;
    border: 1px solid #3498DB !important;
    color: #fff !important;
}
.tableMainEmployee{
    width: 100%;
    float: left;
    padding: 20px;
}
.employeeTbHeading{
    width: 100%;
    float: left;
    font-size: 15px;
    color: #001F3F;
    font-weight: 700;
    /* margin-bottom: 14px; */
}
.tableMainEmployee .iteyOh{
    font-size: 14px;
    color: #333333;
}
.tableMainEmployee .faZveH{
    padding: 0;
}
.tableMainEmployee .erkbiK div:first-child{
    font-size: 14px;
    color: #878787;
    font-weight: 400;
}
.inputBackground{
    background: #fff !important;
    background-image: none !important;
    appearance: auto !important;
    padding-right: 0 !important;
    min-width: 135px !important;
    height: 29px;
    border-radius: 50px;
}
.tableMainEmployee select{
    background-image: none !important;
}
.warningMain{
    width: 100%;
    float: left;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    position: relative;
}
.warningMain h1{
    width: 100%;
    float: left;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    margin-bottom: 7px;
}
.warningMain label{
    width: 100%;
    float: left;
    font-size: 11px;
    color: #878787;
    font-weight: 400;
    margin-bottom: 7px;
}
.warningMain p{
    width: 100%;
    float: left;
    font-size: 14px;
    color: #878787;
    font-weight: 300;
}
.warningMain p b{
    color: #c82d33;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.warningMain:last-child{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}
#more {
    display: inline;
}
.warninPrint{
  
    min-width: 50px !important;
    height: 50px;
    background: #f7f7f7 !important;
    position: absolute !important;
    right: 6px;
    top: 6px;
    color: #868686 !important;
}
.warninEdit{
    position: absolute !important;
    right: 80px;
    top: 20px;
}
.warninDelete{
    position: absolute !important;
    right: 70px;
    top: 20px;
    margin-right: 50px !important;
}
.modalTexArea{
    width: 100%;
    height: 150px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 15px;
    outline: none;
}
.warningPrintMain{
    width: 100%;
    float: left;
}

.printHeadOter {
    width: calc(100% - 100px);
    max-width: 800px;
    display: inline-block;
    background: #fff;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.1);
    min-height: 500px;
    border-radius: 10px;
    z-index: 9;
    position: relative;
    margin: 20px 0 0;
    overflow: hidden;
}
.printHeadTop{
    font-size: 15px;
    color: #333333;
    text-align: center;
    font-weight: 500;
    background: #F3FFEF;
    padding: 15px 20px;
    position: relative;
}
.warningInnerDiv{
    width: 100%;
    float: left;
    padding: 30px;
}
.warningConentDiv{
    width: 100%;
    float: left;
    border: 2px dashed #ccc;
    padding: 20px;
}
.printLogoHead{
    width: 100%;
    float: left;
}
.printLogoHead img{
    width: 95px;
}
.printLogoHead h1{
    font-size: 25px;
    color: #001F3F;
    font-weight: 600;
}
.contPrint{
    width: 100%;
    float: left;
    text-align: left;
}
.totxt{
    width: 100%;
    float: left;
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    margin-bottom: 20px;
}
.toSubtxt{
    width: 100%;
    float: left;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 4px;
}
.toDesigtxt{
    width: 100%;
    float: left;
    font-size: 14px;
    color: #878787;
    font-weight: 300;
}
.subLine{
    width: 100%;
    float: left;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
}
.subTex{
    font-size: 14px;
    color: #878787;
    font-weight: 400;
    min-width: 85px;
}
.subLineName{
    font-size: 14px;
    color: #333333;
    font-weight: 500;
}
.MainContentPrint{
    width: 100%;
    float: left;
    font-size: 14px;
    color: #878787;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px;
}
.MainContentPrint:last-child{
    margin-bottom: 0;
}
.reardext{
    width: 100%;
    float: left;
    margin: 25px 0;
}
.reardext .toDesigtxt{
    margin-bottom: 5px;
}
.reardext .toSubtxt{
    margin-bottom: 0;
}
.authoriDiv{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
}
.authoriDiv span{
    font-size: 12px;
    color: #878787;
    font-weight: 300;
}
.authoriDiv b{
    font-size: 14px;
    color: #333333;
    font-weight: 500;
}
.printBotBts{
    width: 100%;
    float: left;
    margin-top: 20px;
}
.addContentOut{
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.addContentBtn{
    background-color: #3498DB !important;
    margin-left: 10px !important;
    color: #fff !important;
    height: 52px;
    border-radius: 7px !important;
}
.addContentBtn svg{
    font-size: 32px;
}
.uploadImBox{
    width: 100%;
    background: #F9F9F9;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #BDC3C7;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.uploadImBox h1{
    font-size: 15px;
    color: #001F3F;
    font-weight: 500;
}
.uploadImBt{
    background: #c82d33 !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 14px !important;
    height: 52px;
    min-width: 180px !important;
    text-transform: capitalize !important;
    letter-spacing: 1px !important;
    margin: 12px 0 !important;
}
.uploadImBt svg{
    color: #fff;
}
.uploadImBox span{
    font-size: 12px;
    color: #878787;
    text-transform: capitalize !important;
    font-weight: 400;
}
.detailsTableFilterSec .uploadImBox{
    padding: 0;
    border: none;
}

.headOfDownloadile{
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 14px;
}
.downloadedViewDiv{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.donloadLeft{
    float: left;
    position: relative;
    padding-left: 55px;
}
.donloadLeft h1{
    font-size: 16px;
    color: #333333;
    font-weight: 400;
}
.donloadLeft span{
    font-size: 12px;
    font-weight: 400;
    color: #878787;
}
.uploadedImgDiv{
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;
    position: absolute;
    left: 0px;
}
.uploadedImgDiv img{
    width: 100%;
    height: 40px;
    object-fit: contain;
}
.donloadRight{
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 250px;
}
.donloadRight svg{
    color: #555555;
    cursor: pointer;
}
.donloadRight img{
    cursor: pointer;
}
.downloadDataBt{
    border: 1px solid #c82d33 !important;
    border-radius: 8px !important;
    height: 47px;
    padding: 0 20px !important;
    text-transform: capitalize !important;
    color: #c82d33 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}
.tableMainEmployee .MuiGrid-item:last-child .downloadedViewDiv{
    border: none;
}
.proBtMain{
    width: 100%;
    border-radius: 50px !important;
    background: #c82d33 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    height: 40px;
    font-size: 14px !important;
    font-weight: 700 !important;
}
.proBtMain span{
    margin-bottom: 0;
}
.addStarContD{
    width: 100%;
    float: left;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.addStarContD b{
    margin-left: 0;
    font-size: 20px;
    color: #333333;
    font-weight: 600;
}

.addStarContD p{
    font-size: 15px;
    color: #878787;
    font-weight: 400;
}
.detailsTableFilterSec .downloadedViewDiv{
    padding-bottom: 0;
    border: none;
}
.detailsTableFilterSec .downloadedViewDiv .uploadedImgDiv{
    top: 50%;
    margin-top: -20px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.detailsTableFilterSec .downloadedViewDiv .uploadedImgDiv img{
    width: 23px;
}
.approveBt{
    background: #008000 !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 14px !important;
    height: 42px;
    min-width: 120px !important;
    text-transform: capitalize !important;
    letter-spacing: 1px !important;
    margin: 0px 4px !important;
}
.rejectBt{
    background: #c82d33 !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-size: 14px !important;
    height: 42px;
    min-width: 120px !important;
    text-transform: capitalize !important;
    letter-spacing: 1px !important;
    margin: 0px 4px !important;
}
.aprroveRejMain{
    width: 100%;
    float: left;
    padding: 20px;
    background: #f9f9f9;
    padding-top: 0;
}
.aprroveRejTextArea{
    width: 100%;
    float: left;
    height: 85px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    outline: none;
}
.starActivityHeading{
    width: 100%;
    float: left;
    font-size: 20px;
    color: #001F3F;
    font-weight: 600;
}
.monthlyAllowanceMain{
    width: 100%;
    float: left;
}
.monthlyAllowanceMain .addStarContD{
    background: #fff;
}
.monthlyAllowanceMain .addStarContD p{
    font-size: 12px;
}
.allowncWithEdit{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.allowncEditIco{
    border-left: 1px solid #ccc;
    padding: 5px 0px 5px 8px;
    display: flex;
    margin-left: 20px;
    position: relative;
}
.allowncEditIco img{
    cursor: pointer;
}
.allowncWithEditInner{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.monthlyAllowanceMain .addStarContD b{
    color: #c82d33;
}
.monthlyAllowanceMain .allowncWithEdit .allowncWithEditInner b{
    color: #333333;
}
.starActiInnerDiv{
    width: 100%;
    float: left;
}
.starActiInnerDiv .detailsTableFilterSec{
    background: none;
    padding: 0;
}
.datePicOut{
    display: flex;
    align-items: center;
}
.datePicOut select{
    border-radius: 7px !important;
    height: 40px;
    font-size: 13px !important;
}
.dateSt{
    background: #fff !important;
    background-image: none !important;
    appearance: auto !important;
    border: 1px solid #ccc !important;
    padding: 5px;
    font-size: 13px;
    border-radius: 7px !important;
    height: 40px;
}
.leftRightSpace{
    margin: 0 5px;
    width: 100%;
}
.innerAllowncTotStar{
    width: 100%;
    float: left;
}
.innerAllowncTotStar .addStarContD{
    /* max-width: 400px; */
}
.starActivityTableMain{
    width: 100%;
    float: left;
    margin-top: 15px;
}
.profEditIco{
    position: absolute;
    right: 10px;
    top: 10px;
}
.addEditMain{
    width: 100%;
    float: left;
}
.employeeAddMain{
    width: 100%;
    float: left;
    padding: 40px 20px;
    border: 1px solid #eee;
    border-radius: 10px;
    text-align: center;
    position: relative;
}
.addEdiProfDivOut{
    width: 100%;
    float: left;
    text-align: center;
}
.addEdiProfDivInnrIconAtt{
    width: 165px;
    position: relative;
    display: inline-block;
}
.addEdiProfDivInnr{
    width: 165px;
    height: 165px;
    border-radius: 100%;
    overflow: hidden;
    display: inline-block;
    background: #eee;
}
.addEdiProfDivInnr img{
    width: 100%;
    height: 165px;
}
.profEdiICoDiv{
    position: absolute !important;
    bottom: 13px;
    right: 8px;
    width: 35px;
    height: 35px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    border: 1px solid #eee !important;
    min-width: auto !important;
    background: #fff !important;
}
.addEditformMain{
    width: 100%;
    display: inline-block;
    text-align: left;
    max-width: 1100px;
}
.saveBt{
    height: 45px;
    background: #c82d33 !important;
    min-width: 160px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    border-radius: 8px !important;
}
.starCafeTabMain{
    width: 100%;
    float: left;
}
.scrolled .starCafeTabMain .MuiTabs-scroller {
    justify-content: flex-start;
}
.starCafeTabMain .MuiTab-textColorPrimary{
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 400;
    color: #878787;
    background: #fafafa;
    border-radius: 8px 8px 0 0;
    margin-right: 10px;
}
.starCafeTabMain .MuiBox-root{
    padding: 0;
}
.starCafeTabMain .Mui-selected{
    color: #c82d33 !important;
}
.starCafeTabMain .MuiTabs-indicator{
    background: #c82d33;
}
.starCafeTabInner{
    padding: 18px 0;
}
.mealMenuDiv{
    width: 100%;
    float: left;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 20px;
    position: relative;
    /* padding-bottom: 70px; */
    background: #fff;
}
.mealTop{
    width: 100%;
    float: left;
    margin: 10px 0;
}
.mealWithImage{
    position: relative;
    padding-left: 65px;
    display: flex;
    align-items: center;
}
.mealImgTop{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    left: 0;
}
.mealWithImage h1{
    font-size: 20px;
    color: #333333;
    font-weight: 500;
}
.mealMainImgDiv{
    width: 100%;
    float: left;
    text-align: center;
    margin: 30px 0 50px;
    position: relative;
}
.mealMainImg{
    width: 135px;
    height: 135px;
    overflow: hidden;
    display: inline-block;
}
.mealMainImg img{
    width: 100%;
}
.mealMainImgDiv::after{
    content: "";
    position: absolute;
    width: 150px;
    border-bottom: 1px solid #ccc;
    left: 50%;
    bottom: -20px;
    margin-left: -75px;
}
.mealContentDiv{
    width: 100%;
    float: left;
}
.menuOutLine{
    width: 100%;
    float: left;
    font-size: 11px;
    color: #878787;
    font-weight: 400;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menuOutLine:last-child{
    margin-bottom: 0;
}
.menuOutLine b{
    font-size: 15px;
    color: #333333;
    font-weight: 400;
    margin-right: 5px;
    letter-spacing: normal;
    max-width: 125px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.mealEditIco{
    position: absolute;
    right: 20px;
    top: 34px;
    cursor: pointer;
    z-index: 1;
}
.noMenuAddedMain{
    width: 100%;
    float: left;
    text-align: center;
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.noMenuAddedMain h1{
    font-size: 32px;
    color: #e2e2e2;
    font-weight: 300;
    margin-bottom: 50px;
}
.noMenuAddedMain button{
    height: 45px;
    background: #c82d33 !important;
    min-width: 160px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 5px !important;
    width: 100%;
    max-width: 260px;
}
.mealStar{
    float: right;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    font-weight: 600;
}
.mealStar svg{
    color: #FFE600;
    font-size: 19px;
    margin-right: 4px;
}
.addedMenuList{
    width: 100%;
    margin: 50px 0;
    float: left;
    max-height: 200px;
    overflow: auto;
}
.addedMenuList .menuOutLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dollorAmt{
    font-size: 15px;
    color: #333333;
    font-weight: 500;
}
.menuOutLineInr{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 290px;
}
.addedMenuActionBt{
    width: 100%;
    text-align: right;
    max-width: 160px;
}
.menuOutLineInr .mealStar{
    font-size: 15px;
}
.inStock label{
    color: #c82d33;
    font-weight: 500;
}
.inStock .mainListSubTx{
    background: #c82d33;
    color: #fff !important;
    padding: 4px 10px;
    border-radius: 5px;
    font-weight: 600 !important;
    width: auto !important;
}
.css-rbklcn-MuiResponsiveChart-container{
    height: 100% !important;
}
/* .detailsTableFilterSecActionInr{

} */
.responsiveActionBtn{
    display: none !important;
}
.mealBtnBottomOuter{
    width: 100%;
    float: left;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    padding: 20px;
}
.mealBtnBottom{
    height: 45px;
    background: #3498DB !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 20px !important;
    border-radius: 8px !important;
    width: 100%;
    font-weight: 700 !important;
}
.picSlotLabel{
    background: #E74C3C;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border-radius: 50px;
    padding: 1px 12px;
    margin: 3px 0;
    display: inline-block;
}
.completedMarkBt{
    background: none !important;
    font-size: 29px !important;
    font-weight: 500;
    color: #ffffff !important;
}
.productImgDiv{
    position: relative;
}
.ediProdTop{
    position: absolute;
    top: 7px;
    right: 97px;
}
.mealOrangBt{
    background: #c82d33 !important;
}
.addedSpecialDiv{
    width: 100%;
    float: left;
    position: relative;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    margin-top: 10px;
}
.specialEditIco{
    position: absolute !important;
    top: 14px;
    right: 0;
}
.todaySpeHead{
    font-size: 14px;
    font-weight: 600;
    color: #0b0b0b;
}
.specialMenuListDiv{
    width: 100%;
    float: left;
    margin-top: 20px;
}
.specialMenuList{
    width: 100%;
    float: left;
    padding: 15px;
    border-radius: 5px;
    border: 2px dashed #ffc6c8;
    margin-bottom: 10px;
    background: #fff6f6;
}
.specialMenuList:last-child{
    margin-bottom: 0;
}
.specialMenuList h3{
    font-size: 14px;
    font-weight: 500;
    color: #333333;
}
.specialMenuList p{
    font-size: 10px;
    color: #363636;
    font-weight: 500;
    margin: 2px 0;
}
.specialStarsDiv{
    width: 100%;
    float: left;
}
.specialStarsDiv .mealStar{
    float: left;
    margin: 2px 0;
}
.dateStNew{
    font-size: 13px;
    color: #878787;
    font-weight: 400;
}
.newTabMain{
    width: 100%;
    float: left;
}
.newTabMain .MuiTabs-flexContainer{
    padding: 0;
}
.newTabMain .MuiBox-root{
    padding: 0;
}
.newTabMain .tabContDiv{
    margin-top: 3px;
}
.newTabsContentOter{
    padding: 27px 0 20px;
}
.productImgDiv .carousel .control-next.control-arrow:before {
    border-left: 8px solid #c5c5c5;
}
.productImgDiv .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #c5c5c5;
}
.productImgDiv .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}
.serviceistOuter .mainListCont h1{
    color: #c82d33;
    white-space: nowrap;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}
.unitDivMain{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
}
.unitDivMain .detailsTableFilterSec{
    background: transparent;
    padding: 0px;
}
.unitDivMain .filterBtn{
    font-size: 13px !important;
}
.addRemoveImagesBox{
    width: 100%;
    float: left;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}
.imageAddedBox{
    width: 135px;
    height: 150px;
    background: #fff;
    border-radius: 5px;
    float: left;
    position: relative;
    border: 1px solid #ccc;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imageAddedBox img{
    width: 90px;
    height: 100px;
    object-fit: contain;
}
.imageAddedBox .cloIm{
    position: absolute;
    right: 4px;
    top: 4px;
    width: 22px;
    height: 22px;
    background: #efefef;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cloIm svg{
    font-size: 18px;
}
.addRemoveImagesBox .uploadImBox{
    width: 135px;
    height: 150px;
    background: #f8f8f8;
    border-radius: 5px;
    float: left;
    position: relative;
    border: 2px dashed #ccc;
}
.addRemoveImagesBox .uploadImBox .uploadImBt{
    min-width: auto !important;
    font-size: 12px !important;
}
.addRemoveImagesBox .uploadImBox h1 {
    font-size: 14px;
}
.lunchOrderColor{
    border-left: 8px solid #66CDAA;
}
.dinnerOrderColor{
    border-left: 8px solid #3E3768;
}
.breakfastOrderColor{
    border-left: 8px solid #D9B700; 
}
.accordingMain{
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 20px;
}
.AccorddingHeading{
    font-size: 19px;
    color: #333333;
    font-weight: 600;
}
.AccorddingHeading span{
    font-size: 15px;
    color: #878787;
    font-weight: 400;
}
.accordingMain .MuiAccordion-rounded{
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 10px !important;
}
.accordingMain .MuiAccordionSummary-contentGutters{
    align-items: center;
    margin: 14px 0 !important;
}
.settingInr .accordingMain .MuiAccordionSummary-root{
    background: #f8f8f8;
}
.accordingMain .AccorddingHeading{
    margin-left: 15px;
}
.accordingMain .MuiAccordionDetails-root{
    border-top: 1px solid #ccc;
    padding: 20px;
    float: left;
    width: 100%;
}
.sortAndiltMain{
    width: 100%;
    float: left;
    text-align: right;
}
.sortAndiltMain .prodSearchInpt{
    width: 100%;
    max-width: 300px;
    margin-left: 15px;
}
.itemViewMain{
    width: 100%;
    float: left;
    margin-top: 20px;
    overflow: auto;
    max-height: 330px;
}
.menuViewLis{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.prodView{
    font-size: 12px;
    color: #878787;
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.prodView b{
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    margin-right: 5px;
    width: 100%;
    max-width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.lunchViewColor{
    font-size: 15px;
    color: #66CDAA;
    font-weight: 600;
    text-align: left;
    width: 100%;
    /* max-width: 150px; */
}
.breakfastViewColor{
    font-size: 15px;
    color: #D9B700;
    font-weight: 600;
    text-align: left;
    width: 100%;
    max-width: 150px;
}
.dinnerViewColor{
    font-size: 15px;
    color: #3E3768;
    font-weight: 600;
    text-align: left;
    width: 100%;
    max-width: 150px;
}
.specialViewColor{
    font-size: 15px;
    color: #E300DA;
    font-weight: 600;
    text-align: left;
    width: 100%;
    max-width: 150px;
}
.adMenuItemsBt{
    height: 45px;
    background: #c82d33 !important;
    min-width: 160px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 5px !important;
    width: 100%;
    max-width: fit-content;
    position: absolute !important;
    right: 50px;
    top: 18px;
    z-index: 1;
}
.mealBgMain{
    background: #F0F5ED;
    margin-top: 20px;
    padding: 0 20px 20px;
    border-radius: 9px;
}
.todyMenHeading{
    font-size: 24px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
.todyMenSubHeading{
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    text-align: center;
}
.veryBigModal .MuiDialog-paperScrollBody {
    max-width: 1200px;
    /* width: 100%; */
    /* max-width: calc(100% - 64px); */
}
.modalWeeklyTabsMain{
    width: 100%;
    float: left;
}
.modalWeeklyTabsMain .MuiTab-textColorPrimary{
    font-size: 14px;
    font-weight: 400;
    color: #878787;
    text-transform: capitalize !important;
    letter-spacing: 1px;
    border-radius: 10px;
    height: 55px;
    width: 100%;
    max-width: 150px;
    background: #fbfbfb;
    margin-right: 9px;
    border: 1px solid #eee;
}
.modalWeeklyTabsMain .MuiTabs-flexContainer .Mui-selected:first-child{
    background: #D9B700;
    color: #fff;
}
.modalWeeklyTabsMain .MuiTabs-flexContainer .Mui-selected:nth-child(2){
    background: #66CDAA;
    color: #fff;
}
.modalWeeklyTabsMain .MuiTabs-flexContainer .Mui-selected:last-child{
    background: #6a37a7;
    color: #fff;
}
.modalWeeklyTabsMain .MuiTabs-indicator{
    background: transparent;
}
.modalWeeklyTabsMain .MuiBox-root .MuiBox-root{
    border: none;
    float: left;
    width: 100%;
    padding: 15px 0;
}
.modalTabsContent{
    width: 100%;
    float: left;
}
.modalTabsContent .mHisf{
    background: #f4f4f4;
    border-radius: 9px 9px 0 0;
    border: none;
}
.modalTabsContent .mHisf .lnOUep{
    font-size: 13px;
    color: #333333;
    font-weight: 500;
}
.modalTabsContent .faZveH{
    font-size: 14px;
    font-weight: 600;
}
.modalTabsContent .eXWrwD{
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    border: 1px solid #f4f4f4;
}

.checkColor1 .MuiCheckbox-colorPrimary{
    color: #d9b700 !important;
}
.checkColor2 .MuiCheckbox-colorPrimary{
    color: #66CDAA !important;
}
.checkColor3 .MuiCheckbox-colorPrimary{
    color: #6a37a7 !important;
}
.itemHeadingSt{
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 10px;
}
.modalSmallBtSt{
    height: 45px;
    background: #c82d33 !important;
    min-width: 160px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    margin: 0 5px !important;
    width: 100%;
    max-width: 260px;
}
.accordTotalOrder{
    float: right;
    width: 100%;
    text-align: right;
    font-size: 18px;
    color: #333333;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
}
.accordTotalOrder img{
    margin-right: 10px;
}
.menuViewLis .accordTotalOrder{
    padding-right: 0;
    font-size: 15px;
    font-weight: 500;
}
.menuViewLis .accordTotalOrder img{
    width: 16px;
    margin-right: 5px;
}
.orderSmmryAccordingMain .itemViewMain{
    margin-top: 0;
}
.orderSmmryAccordingMain .itemViewMain .menuViewLis:last-child{
    margin-bottom: 0;
}
.orderSmmryAccordingMain .MuiAccordion-rounded{
    border: 1px solid #e3e3e3;
    margin-bottom: 10px !important;
    border-radius: 10px;
    box-shadow: 0px 7px 6px rgba(0,0,0,0.03);
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.orderSmmryAccordingMain .MuiAccordion-rounded::before{
    background: no-repeat;
}
.orderSmmryAccordingMain .AccorddingHeading {
    width: 100%;
}
.AddNewBtn{
    display: none !important;
}
.addedFields .AddNewBtn{
    display: inline-flex !important;
}
.addedFields .addFieldsBt{
    display: none;
}
.groceryTableMain .newAddedTr{
    background-color: #d6ffe5 !important;
}
.groceryTableMain table thead tr th{
    padding: 10px;
    background: #eee;
    border-radius: 6px 6px 0 0;
}
.groceryTableMain table tbody tr td {
    border: 1px solid #efefef;
    padding: 10px;
    font-weight: 400;
}
.groceryTableMain table tbody tr:last-child td{
    border: 1px solid #efefef; 
}
.groceryTableMain .newAddedTr{
    background-color: #d6ffe5 !important;
}
.qutyInput{
    width: 100%;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 15px;
    outline: none;
}
.radioMain .MuiFormGroup-root{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
}
.uniTopBotttomSpace{
    padding: 10px 0;
}
.singlUnitSt{
    display: none !important;
}
.selectedSinglUnit .cartonSt{
    display: none !important;
}
.selectedSinglUnit .singlUnitSt{
    display: block !important;
}
.innerTabsMain{
    width: 100%;
    float: left;
}
.innerTabsMain .MuiBox-root{
    padding: 0;
}
.innerTabsMain .MuiTabs-indicator{
    display: none;
}
.innerTabsMain .MuiTab-textColorPrimary{
    min-height: 27px;
    background: #fff;
    border-radius: 50px;
    padding: 7px 22px;
    border: 1px solid #ccc;
    margin: 0;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 300 !important;
}
.innerTabsMain .Mui-selected{
    background: #c82d33 !important;
    border: 1px solid #c82d33 !important;
    color: #fff !important;
    font-weight: 500 !important;
}
.innerTabsMain .MuiTabs-flexContainer{
    background: #f9f9f9;
    padding: 17px 20px;
}
.innerTabsMain .groceryTableMain table thead tr th{
    background: #fafafa;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #353535;
    border-top: 1px solid #e7e7e7;
}
.innerTabsMain .groceryTableMain table tbody tr td{
    font-size: 13px;
    color: #888;
}
.employeeTbHeadingSelect select{
    float: left;
    font-size: 15px;
    color: #001F3F;
    font-weight: 700;
    /* margin-bottom: 14px; */
    border: none;
    outline: none;
    background: url("../images/selectIcon.svg") no-repeat !important;
    background-position: calc(100% - 0.75rem) center !important;
}
.employeeTbHeadingInpu input{
    float: left;
    font-size: 13px;
    color: #001F3F;
    font-weight: 500;
    border: none;
    border: 1px solid #ccc;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
}
.employeeTbHeadingInpu b{
    float: left;
    margin: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
}
.starActivityTbsMain{
    width: 100%;
}
.starActivityTbsMain .MuiTabs-flexContainer {
    background: transparent;
    padding: 0;
}
.paginationOuterMain .MuiPagination-ul{
    justify-content: flex-end;
}
.starActivityTbsMain .tableMainEmployee{
    padding: 15px 0;
}
.todayRecord{
    background: #fce4ff;
}
.innerTabsMain .groceryTableMain table tbody .todayRecord td{
    color: #000;
}
.uplBtOut{
    background: #F9F9F9;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    border: 1px dashed #e3e3e3;
    max-width: 310px;
    margin-bottom: 20px;
}
.uplBtOut .uploadImBt{
    margin: 0 !important;
    height: 40px;
    min-width: 125px !important;
}
.uplBtOut p{
    font-size: 12px;
    color: #878787;
    text-transform: capitalize !important;
    font-weight: 400;
    margin-left: 15px;
}
.downloadedViewDivOuter{
    width: 100%;
    float: left;
    border-bottom: 1px solid #eee;
}
.noDocTTx{
    color: #bebebe;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
}
.tableMainEmployee .MuiGrid-container .MuiGrid-item:last-child .downloadedViewDivOuter{
    border-bottom: none;
}
.tableMainEmployee .MuiGrid-container .MuiGrid-item:last-child .downloadedViewDivOuter .noDocTTx{
    margin-bottom: 0;
}
.eyeCate{
    position: absolute;
    right: 100px;
}
.slideCateFirstSt{
    height: 0;
    opacity: 0;
    visibility: hidden;
    padding-top: 0 !important;
    padding-left: 0 !important;
    transition: .3s;
}
.slideCateSt{
    height: auto;
    opacity: 1;
    visibility: visible;
    padding-top: 24px !important;
    padding-left: 24px !important;
}
.employeeFilter{
    display: flex;
}
.probDetBt{
    border: 1px solid #ccc !important;
    border-radius: 7px !important;
    height: 63px;
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    max-width: 215px;
    align-items: flex-start !important;
    padding: 0 35px !important;
    margin-right: 15px !important;
}
.probDetBt label{
    font-size: 14px;
    font-weight: 400;
    color: #878787;
}
.probDetBt b{
    font-size: 15px;
    font-weight: bold;
    color: #333333;
}
.probDetBtOut{
    width: 100%;
    float: left;
    display: flex;
}
.newOrderSumMain{
    width: 100%;
    float: left;
    padding: 20px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    min-height: 235px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: .5s;
    box-shadow: 0px 36px 9px -27px rgba(0,0,0,0.1);
    background: #fff;
    position: relative;
}
.newOrderSumMain:hover{
    background: #efffe7;
}
.newOrdTop{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}
.newOrdTop h1{
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    margin-left: 15px;
}
.newOrdBot{
    width: 100%;
    float: left;
    border-top: 1px solid #ccc;
    margin-top: 20px;
    padding-top: 20px;
}
.newOrdBot .accordTotalOrder{
    display: flex;
    justify-content: center;
    padding: 0;
    font-size: 17px;
}
.newOrdTop img{
    width: 55px;
}
.orderSmryPop{
    display: flex;
    width: 100%;
    float: left;
    align-items: center;
    justify-content: space-between;
    background: #F0F5ED;
    padding: 15px;
    border-radius: 14px;
}
.orderSmryPop1{
    display: flex;
    align-items: center;
}
.orderSmryPop1 img{
    width: 46px;
}
.orderSmryPop1 .AccorddingHeading{
    margin-left: 10px;
}
/* .orderSmryPop2{

} */
.menuDetailsPop{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}
.menuDetailsPop h1{
    font-size: 19px;
    color: #000000;
    font-weight: 700;
}
.menuDetailsPop p{
    font-size: 16px;
    font-weight: 400;
    color: #878787;
}
.menuDetailsPop  .accordTotalOrder{
    width: auto;
    font-size: 38px;
    color: #3498DB;
    font-weight: 600;
}
.menuDetailsPopOut{
    width: 100%;
    float: left;
}
.menuDetailsPopOut .menuDetailsPop h1{
    font-size: 19px;
}
.menuDetailsPopOut .menuDetailsPop p{
    font-size: 15px;
}
.minuDetailDevideLine{
    position: relative;
}
.minuDetailDevideLine::before{
    content: "";
    position: absolute;
    height: 100%;
    border-left: 1px solid #ccc;
    left: 11px;
    top: 11px;
}
.otherProdctHead{
    font-size: 16px;
    font-weight: 400;
    color: #001F3F;
}
.companyDiv{
    width: 270px;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
}
.companyDiv h1{
    font-size: 13px;
    font-weight: 600;
    color: #333333;
}
.compLogImg{
    margin-bottom: 13px;
    display: flex;
    align-items: center;
}
.compLogImg img{
    width: 115px;
    height: 50px;
    object-fit: contain;
}
.errorSt{
    color: red !important;
    text-align: left;
    font-size: 13px;
    margin-top: 2px;
    font-weight: 500;
}
.companyAddIco{
    float: left;
    display: flex;
}
.companyAddIco svg{
    font-size: 40px;
}
.addCompanyDiv{
    border: 2px dashed #6ba74c;
    /* background: #ace68e; */
    cursor: pointer;
    transition: .3s;
}
.addCompanyDiv:hover{
    background: #d7ffc3;
    border: 2px dashed #67e667;
}
.forSuperAddCompTop{
    width: 100%;
    float: left;
    min-height: 64px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
}
.registraContentMain{
    width: 100%;
    float: left;
    min-height: 500px;
    padding-bottom: 80px;
    transition: .8s;
    text-align: center;
}
.registraContentInner{
    width: 100%;
    display: inline-block;
    text-align: left;
    max-width: 932px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 40px 20px -27px rgba(0,0,0,0.1);
}
.needHelp{
    font-size: 13px;
    color: #878787;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.needHelp svg{
    margin-left: 5px;
    font-size: 20px;
}
.companyLogos{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
}
.registraContentInner .pageMainHeading h1{
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    width: 100%;
    float: left;
    text-align: left;
    margin-bottom: 5px;
}
.registraContentInner .pageMainHeading p{
    font-size: 13px;
    font-weight: 400;
    color: #878787;
    width: 100%;
    float: left;
    text-align: left;
}
.registraContentInner .pageMainHeading{
    display: block;
}
.reggistraLabel{
    width: 100%;
    float: left;
    font-size: 13px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 10px;
}
.orgnigDiv{
    width: 100%;
    float: left;
}
.orgniInner{
    width: 250px;
    height: 85px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    float: left;
    margin-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #BDC3C7;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
}
.activeOrgniInner{
    background: #ebebeb;
    border: 1px solid #2e352a;
    color: #000;
}
.serTypeMulti{
    width: 100%;
    float: left;
}
.serTypeMultiDiv{
    min-width: 100px;
    border-radius: 50px;
    border: 1px solid #ccc;
    padding: 7px 20px;
    float: left;
    font-size: 12px;
    color: #878787;
    font-weight: 400;
    cursor: pointer;
    margin-right: 12px;
}
.activeSerTypeMultiDiv{
    background: #3498DB;
    color: #fff;
    border: 1px solid #3c94ce;
}
.formLongBtTopSpace{
    margin-top: 40px !important;
}
.veggiRow{
    display: flex;
    justify-content: space-between;
    gap: 15px;
    position: relative;
    padding-right: 40px;
    margin-bottom: 15px;
}
.veggiRowTop{
    margin-bottom: 0;
}
.veryVeryBigModal .MuiDialog-paperScrollBody{
    max-width: 1400px;
    width: 100%;
    /* max-width: calc(100% - 64px); */
}
.veggiRowInr{
    width: 100%;
    max-width: 165px;
}
.veggiRowTop label{
    font-size: 13px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
    float: left;
    width: 100%;
}
.veggiRowInr .pageTopMainBt{
    margin: 0 !important;
    min-width: auto !important;
}
.veggiRowBt{
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
}
.veggiRowBt svg{
    font-size: 30px;
    cursor: pointer;
    color: #D78978;
}
.addVeggiBt svg{
    color: #c82d33;
    font-size: 30px;
    cursor: pointer;
}

.veggiRowBtNew svg{
    font-size: 30px;
    cursor: pointer;
    color: #D78978;
}
.profileDetailsIm{
    background: #F3FFEF;
    border-radius: 10px;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.react-datepicker-wrapper{
    width: 100%;
}
.comEdiIco{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.profileDetMain{
    width: 100%;
    float: left;
}
.profileDetMain .proBtMain{
    max-width: 250px;
}
.proDetailList{
    width: 100%;
    float: left;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding: 20px;
}
.proDetailListIner{
    width: 100%;
    float: left;
    border-bottom: 1px dashed #e7e7e7;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.proDetailListIner h1{
    font-size: 14px;
    color: #333333;
    font-weight: 300;
    margin-bottom: 15px;
}
.proDetailListData{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 20px;
}
.proDetailListData:last-child{
    margin-bottom: 0;
}
.dataLab{
    width: 100%;
    float: left;
    font-size: 12px;
    font-weight: 400;
    color: #878787;
}
.proDetailListIner:last-child{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}
.settingMainDv{
    width: 100%;
    float: left;
    text-align: center;
}
.settingInr{
    width: 100%;
    display: inline-block;
    padding: 100px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    /* background: #f1f1f1; */
    position: relative;
}
.setingLbl{
    width: 100%;
    float: left;
    text-align: left;
    font-size: 14px;
    color: #3b3b3b;
    font-weight: 400;
    letter-spacing: .4px;
    margin-bottom: 7px;
}
.setingInpt{
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border: none;
    padding: 0 15px;
    font-size: 14px;
    color: #001f3f !important;
    font-weight: 500;
    outline: none !important;
    border: 1px solid #ccc;
}
.setiUpdateBt{
    max-width: 400px;
    display: inline-block;
    float: none !important;
}
.setiIcoDi{
    font-size: 120px !important;
    position: absolute;
    top: -6px;
    color: #ececec;
    left: 100%;
    margin-left: -132px;
}
.logoPrevOuter{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: -70px;
}
.logoPrev{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: inline-block;
    background: #fff;
    overflow: hidden;
    border: 1px solid #ccc;
}
.logoPrev img{
    width: 100%;
    height: 100px;
    object-fit: contain;
}
.visitorSearch {
    width: 100%;
    float: left;
    text-align: left;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.sortingIcoOut .MuiToggleButton-standard{
    background: none !important;
    border: none !important;
    padding: 10px 15px;
    border-radius: 5px !important;
}
.sortingIcoOut .MuiToggleButton-standard svg{
    margin-left: 0;
    font-size: 30px;
    color: #8f8f8f;
    transition: .5s;
}
.sortingIcoOut .Mui-selected{
    color: #000 !important;
}
.sortingIcoOut .Mui-selected svg{
    color: #06d221;
    transform: rotate(180deg);
}
.smalShortingOuter{
    display: flex;
    align-items: center;
}
.smalShortingOuter svg{
    font-size: 13px;
    cursor: pointer;
}
.smalShortingOuter label{
    margin-bottom: 0;
    /* display: contents; */
}
.smalSho{
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    margin-left: 2px !important;
    display: none !important;
}

.autocompleteMain{
    width: 100%;
    float: left;
}
.autocompleteMain .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ccc !important;
    border-width: 1px !important;
}
.autocompleteMain .MuiInputBase-root{
    width: 100%;
    height: 44px;
    border-radius: 5px;
    padding: 0 15px;
    outline: none;
}
.autocompleteMain .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: #ccc !important;
}
.autocompleteMain .MuiOutlinedInput-input{
    color: #333 !important;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    height: 14px;
}
.verticleScroll{
    max-height: 300px;
    overflow: auto;
}
.tablTypsOuter{
    display: flex;
    width: 100%;
}
.tablTypsOuterMain{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.settheading{
    width: 100%;
    float: left;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}
.settingDevider{
    width: 100%;
    float: left;
    border-top: 1px dashed #bcbcbc;
    margin: 20px 0 15px;
}
.underConstruction{
    width: 100%;
    float: left;
    text-align: center;
}
.workers {
    width: 400px;
    height: 185px;
    position: relative;
    margin: 90px auto 0px;
    background-image: url('../images/workers.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: transparent;
    background-size: 100%;
}
@-moz-keyframes left_hand {
    0%{-moz-transform:rotate(-23deg)}
    50%{-moz-transform:rotate(1deg)}
    100%{-moz-transform:rotate(-23deg)}
}

@-webkit-keyframes left_hand {
    0%{-webkit-transform:rotate(-23deg)}
    50%{-webkit-transform:rotate(1deg)}
    100%{-webkit-transform:rotate(-23deg)}
}

@-o-keyframes left_hand {
    0%{-o-transform:rotate(-23deg)}
    50%{-o-transform:rotate(1deg)}
    100%{-o-transform:rotate(-23deg)}
}

@-ms-keyframes left_hand {
    0%{-ms-transform:rotate(-23deg)}
    50%{-ms-transform:rotate(1deg)}
    100%{-ms-transform:rotate(-23deg)}
}

@keyframes left_hand {
    0%{transform:rotate(-23deg)}
    50%{transform:rotate(1deg)}
    100%{transform:rotate(-23deg)}
}
.workers .left_hand {
    width: 80px;
    height: 74px;
    background-image: url('../images/hand.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: transparent;
    background-size: 100%;
    position: absolute;
    left: 102px;
    top: 29px;
    z-index: 2;
    -moz-animation: 0.4s ease 0s normal none infinite left_hand;
    -moz-transform-origin: 85% 65%;
    -webkit-animation: left_hand 0.4s infinite ease-in-out;
    -webkit-transform-origin: 85% 65%;
    -o-animation: 0.4s ease 0s normal none infinite left_hand;
    -o-animation: left_hand 0.4s infinite ease-in-out;
    -o-transform-origin: 85% 65%;
    -ms-animation: 0.4s ease 0s normal none infinite left_hand;
    -ms-animation: left_hand 0.4s infinite ease-in-out;
    -ms-transform-origin: 85% 65%;
    animation: 0.4s ease 0s normal none infinite left_hand;
    animation: left_hand 0.4s infinite ease-in-out;
    transform-origin: 85% 65%;
}
.workers .right_hand {
    width: 73px;
    height: 87px;
    position: absolute;
    background-image: url('../images/hand2.svg');
    background-repeat: no-repeat;
    background-position: left top;
    background-color: transparent;
    background-size: 100%;
    right: 125px;
    top: -7px;
    -moz-animation: 0.5s ease 0s normal none infinite right_hand;
    -moz-transform-origin: 15% 85%;
    -webkit-animation: right_hand 0.5s infinite ease-in-out;
    -webkit-transform-origin: 15% 85%;
    -o-animation: 0.5s ease 0s normal none infinite right_hand;
    -o-animation: right_hand 0.5s infinite ease-in-out;
    -o-transform-origin: 15% 85%;
    -ms-animation: 0.5s ease 0s normal none infinite right_hand;
    -ms-animation: right_hand 0.5s infinite ease-in-out;
    -ms-transform-origin: 15% 85%;
    animation: 0.5s ease 0s normal none infinite right_hand;
    animation: right_hand 0.5s infinite ease-in-out;
    transform-origin: 15% 85%;
}

@-moz-keyframes right_hand {
    0%{-moz-transform:rotate(55deg)}
    50%{-moz-transform:rotate(1deg)}
    100%{-moz-transform:rotate(55deg)}
}

@-webkit-keyframes right_hand {
    0%{-webkit-transform:rotate(55deg)}
    50%{-webkit-transform:rotate(1deg)}
    100%{-webkit-transform:rotate(55deg)}
}

@-o-keyframes right_hand {
    0%{-o-transform:rotate(55deg)}
    50%{-o-transform:rotate(1deg)}
    100%{-o-transform:rotate(55deg)}
}

@-ms-keyframes right_hand {
    0%{-ms-transform:rotate(55deg)}
    50%{-ms-transform:rotate(1deg)}
    100%{-ms-transform:rotate(55deg)}
}

@keyframes right_hand {
    0%{transform:rotate(55deg)}
    50%{transform:rotate(1deg)}
    100%{transform:rotate(55deg)}
}

.underConstruction .title {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: #000000;
    line-height: 28px;
    margin: 25px 10px 55px 10px;
}

.settingInr .accordingMain .MuiPaper-rounded{
    margin-bottom: 10px !important;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
}
.settingInr .MuiAccordionSummary-expandIconWrapper svg{
    color: #000;
}
.settingInr .accordingMain .MuiAccordion-rounded::before{
    display: none;
}
.listingSearchAndFilterMain{
    width: 100%;
    float: left;
    display: flex;
    gap: 15px;
}
.proDetailasCont{
    width: 100%;
    float: left;
}
.proDetailasContDiv{
    width: 100%;
    float: left;
}
.proDetailasContDiv span{
    display: flex;
    margin-bottom: 20px;
}
.proDetailasContDiv span p{
    font-size: 13px;
    color: #878787;
    font-weight: 400;
    letter-spacing: 1px;
    width: 100%;
    max-width: 200px;
}
.proDetailasContDiv span b{
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    letter-spacing: 1px;
}
.salesOt{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.salesOt p{
    font-size: 15px;
    font-weight: 400;
    color: #878787;
}
.salesOt select{
    border: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    max-width: 150px;
    border-radius: 5px;
}
.chartAmountMain{
    width: 100%;
    float: left;
}
.chartAmountMain b{
    font-weight: 600;
    font-size: 22px;
    color: #c82d33;
}
.chartAmountMain p{
    font-weight: 400;
    font-size: 15px;
    color: #333333;
    display: flex;
    justify-content: space-between;
}
.chartAmountMain p span{
    font-size: 15px;
    font-weight: 700;
    color: #c82d33;
    display: flex;
    align-items: center;
}
.allownceFormOutMain{
    width: 100%;
    max-width: 730px;
    display: inline-block;
    margin-bottom: 25px;
    margin-top: 25px;
    text-align: left;
}
.dataNotfond{
    width: 100%;
    float: left;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #dfdfdf;
}
.dataNotfond img{
    width: 300px;
}
.serviceistOuter .mainListDiv{
    gap: 15px;
}
.serviceistOuter .mainListDiv .mainListCont{
    margin: 0;
    width: 100%;
}
.serviceistOuter .mainListDiv .mainListCont .smalShortingOuter label{
    width: 100%;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.serviceistOuter .mainListDiv .mainListCont .mainListSubTx{
    width: 100%;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.useAdminDiv{
    background: #535455;
    width: 100%;
    float: left;
    text-align: center;
    padding: 5px;
    font-size: 12px;
}
.selectedTypeTx{
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}
.selectedTypeTx::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #001f3f;
    bottom: -15px;
    left: 50%;
    margin-left: -10px;
}
.selectedTypeTx span{
    background: #001F3F;
    color: #fff;
    padding: 7px 30px;
    border-radius: 18px 0;
}
.forModalSpace .autocompleteMain .MuiInputBase-root {
    width: 100%;
    height: 52px;
}
.starMarkerSettiAcor{
    width: 100%;
    float: left;
    text-align: left;
}
.orderTimingsMain{
    width: 100%;
    float: left;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    padding: 15px;
    min-height: 174px;
}
.orderTimingsTop{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.orderTimingsTop h2{
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 100%;
}
.orderTimingsCont{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 15px;
    padding-top: 22px;
    border-top: 1px solid #e3e3e3;
    padding-bottom: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.orderTimingsCont h4{
    font-size: 20px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 9px;
}
.orderTimingsCont p{
    font-size: 14px;
    font-weight: 400;
    color: #878787;
}
.breakfastTimeColor{
    color: #D9B700;
}
.lunchTimeColor{
    color: #66CDAA;
}
.dinnerTimeColor{
    color: #190787;
}
.warnLabel{
    margin-bottom: 5px !important;
}

.warnInput{
    margin-bottom: 10px;
}
.warnInputDesc{
    height: 100px !important;
}
.forVeggiErrorTx .errorSt{
    font-size: 10px;
    position: absolute;
    bottom: -6px;
    left: 15px;
    background: #fff;
    padding: 0 5px;
    margin: 0;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    max-width: 113px;
    text-overflow: ellipsis;
}
.forVeggiErrorTx table tbody tr td{
    position: relative;
}
.veggiImgPreviewOuter{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 9px;
}
.veggiImgPreview{
    background: #efefef;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}
.veggiImgPreview img{
    width: 100%;
    height: 50px;
    object-fit: contain;
}
.requiredStar{
    color: red;
}
.visitorFilterIco{
    margin-left: 10px;
    cursor: pointer;
}
.visitorSearchDiv{
    width: 100%;
    float: left;
    position: relative;
}
.filterIconSearch{
    position: absolute;
    right: 10px;
    top: 10px;
    color: #878787;
    cursor: pointer;
}
.itemNameTbl{
    font-weight: 600 !important;
    color: #000 !important;
}
.forTxCenterAlignTable table thead tr th{
    text-align: center;
}
.forTxCenterAlignTable table thead tr th:first-child{
    text-align: left;
}
.forTxCenterAlignTable table tbody tr td{
    text-align: center;
}
.forTxCenterAlignTable table tbody tr td:first-child{
    text-align: left;
}
.flagSt{
    position: absolute;
    top: -7px;
    left: 17px;
    background: #eaeaea;
    font-size: 10px;
    font-weight: 700;
    padding: 2px 15px;
    border-radius: 13px 0;
    color: #282828;
    border: 1px solid #d6d6d6;
}
.muliSelectMain .MuiFormControl-root{
    width: 100%;
}
.muliSelectMain .MuiOutlinedInput-input{
    padding: 14px 15px;
}
.complementoryMain{
    display: flex;
    align-items: center;
    gap: 20px;
}
.complementoryMain .MuiFormGroup-root{
    display: flex;
    flex-direction: row;
}
.complementoryMain .form-control{
    width: 100%;
    max-width: 340px;
}
.mainListDiv .mainListCont .dateStNew{
    white-space: nowrap;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    float: left;
}
.forSetOnPriority{

}
.forSetOnPriority .pageLabel{
    width: auto;
    margin-bottom: 0;
}
.addTimeBtn{
    color: #676060;
    font-size: 30px !important;
    cursor: pointer;
}

/* New Order View Css */
.newOrderView .qrOter{
    margin-top: 0;
    margin-bottom: 0;
    max-width: 146px;
}
.newOrderView .qrDiv{
    height: 145px;
    display: block;
}
.newOrderView .orderItemDetails{
    width: 100%;
    margin-bottom: 0;
}
.newOrderView .orderTop h1{
    font-size: 17px;
}
.newOrderView .nameOfCust {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 2px;
    text-align: center;
}
.newOrderView .orderSumry label {
    font-size: 13px;
}
.newOrderView .orderSumry p {
    font-size: 12px;
    font-weight: 600;
}
.newOrderView .orderTop {
    align-items: center;
}
.newOrderView .qrOuterMain{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 15px;
}
.newOrderView .pickpSlot {
    font-size: 13px !important;
    color: #3498db !important;
}
.newOrderView .pickpSlotTime {
    font-size: 14px !important;
    color: #c82d33 !important;
}
.newOrderView .readyForPicBt {
    height: 45px;
    font-size: 20px !important;
    border-radius: 7px !important;
    max-width: 340px;
    font-weight: 600 !important;
    text-transform: uppercase !important;
}
.newOrderView .qrDiv img {
    height: 145px;
}
.newOrderView .orderItemDetails h1 {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 7px;
}
.newOrderView .orderItemDiv span p {
    font-size: 13px;
    font-weight: 500;
}
.newOrderView .orderItemDiv span{
    margin-bottom: 1px;
}
.newOrderView .productDetails table tr th {
    font-size: 13px;
    color: #5c5656;
    font-weight: 500;
    background: #f3f3f3;
    padding: 7px 10px;
    border-radius: 8px 8px 0 0;
}
.newOrderView .productDetails table tr td {
    font-size: 13px;
    padding: 7px 10px;
}
.printNow{
    cursor: pointer;
    transition: transform .2s;
}
.printNow:hover{
    transform: scale(1.5);
}

.forVeggiErrorTx .form-control{
    position: relative;
}
.settingStarCafeMain{
    width: 100%;
    float: left;
}
.settingStarCafeMain .MuiButtonBase-root{
    background: #f9f9f9;
    border-radius: 10px 10px 0 0;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    color: #bfbfbf;
}
.settingStarCafeMain .MuiTabs-flexContainer{
    gap: 7px;
}
.settingStarCafeMain .Mui-selected{
    background: #f2f2f2;
    color: #333 !important;
}
.settingStarCafeMain .MuiTabs-indicator{
    background-color: #f2f2f2;
}
.settingStarCafeMain .MuiBox-root{
    padding: 0;
    border: none;
}
.settingStarCafeInner{
    padding: 20px;
    width: 100%;
    float: left;
    background: #f2f2f2;
    border-radius: 0 10px 10px 10px;
}
.starCafeTimeMain{
    width: 100%;
    float: left;
}
.starCafeTimeMain .MuiStack-root{
    width: 100%;
    padding: 0;
}
.starCafeTimeMain .MuiInputBase-input{
    height: 18px;
}

/* Small Print Css */
.smallPrintMain .scanOrderContDiv{
    width: 350px;
    border-radius: 5px;
}
.smallPrintMain .orderTop h1 {
    font-size: 12px;
}
.smallPrintMain .nameOfCust {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 5px;
}
.smallPrintMain .orderSumry label {
    font-size: 10px;
    font-weight: 600;
    color: #000;
    margin-bottom: 4px;
}
.smallPrintMain .orderSumry p {
    font-size: 9px;
    color: #000;
    font-weight: 600;
}
.smallPrintMain .pickpSlot {
    font-size: 10px !important;
}
.smallPrintMain .pickpSlotTime {
    font-size: 9px !important;
    color: #000;
    font-weight: 800 !important;
}
.smallPrintMain .qrDiv {
    max-width: 90px;
    height: 90px;
    border-radius: 5px;
}
.smallPrintMain .qrDiv img {
    height: 90px;
}
.smallPrintMain .orderItemDetails h1 {
    font-size: 10px;
    margin-bottom: 5px;
    color: #000;
    font-weight: 700;
}
.smallPrintMain .orderItemDiv span p {
    font-size: 10px;
    font-weight: 600;
    color: #828282;
}
.smallPrintMain .orderItemDiv span{
    margin-bottom: 5px;
}
.smallPrintMain .orderItemDiv span b {
    font-size: 12px;
    font-weight: 700;
}
.smallPrintMain .totalAmt {
    font-size: 12px !important;
}
.smallPrintMain .qrOter{
    margin-bottom: 2px;
}
.smallPrintMain .productDetails table tr th {
    font-size: 9px;
    font-weight: 700;
}
.smallPrintMain .productDetails table tr td {
    font-size: 9px;
    color: #333;
}
.smallPrintMain .starSpen {
    font-size: 10px !important;
}
.smallPrintMain .groceryTableMain table thead tr th {
    padding: 2px 2px;
    border-radius: 2px 2px 0 0;
}
.smallPrintMain .groceryTableMain table tbody tr td{
    padding: 2px;
}
.smallPrintMain .orderItemDetails {
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
}
.smallPrintMain .printIco{
    position: fixed;
    right: 20px;
    top: 20px;
}

.updateBtn{
    height: 52px !important;
    background: #c82d33 !important;
    border-radius: 8px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 500 !important;

}

.cancelEditBtn{
    width: 100% !important;
    border: 1px solid #c82d33 !important;
    height: 52px !important;
    background: #ffffff !important;
    border-radius: 8px !important;
    color: #c82d33 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
.pageTopMainCancelBt{
    min-width: auto !important;
    width: 150px !important;
        font-size: 12px !important;
        height: 45px;
        background: #ffffff !important;
        border-radius: 8px !important;
        color: #c82d33 !important;
        border: 1px solid #c82d33 !important;
}

.cartonOrSinleSelect{
    width: 100%;
    float: left;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.typeSelectionDiv{
    width: 250px;
    height: 200px;
    background: #fbfbfb;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed #ccc;
    cursor: pointer;
    transition: .3s;
}
.typeSelectionImg{
    width: 100%;
}
.typeSelectionImg img{
    width: auto;
    height: 100px;
}
.typeSelectionDiv h1{
    font-size: 22px;
    color: #000;
    font-weight: 700;
}
.selectSubTx{
    width: 100%;
    float: left;
    text-align: center;
    color: #333;
    margin-bottom: 5px;
}
.typeSelectionDiv:hover{
    background: #f3ffef;
}

.setExchangeMain{
    width: 100%;
    float: left;
    background: #121210;
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.setExchImgDiv{
    width: 70px;
    height: 70%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}
.setExchImgDiv img{
    width: 100%;
    height: 70px;
    object-fit: contain;
}
.exchangeLeft{
    display: flex;
    align-items: center;
    gap: 15px;
}
.exchangeLeft h1{
    font-size: 20px;
    font-weight: 400;
}
.exchangeLeft p{
    font-size: 14px;
    font-weight: 400;
}

.exchangeRight{
    width: 100%;
    max-width: 300px;
    float: left;
    text-align: right;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #001f3f;
    border-radius: 0 9px 9px 0;
}
.exchangeRight::before{
    content: "";
    position: absolute;
    width: 0; 
    height: 0; 
    border-top: 109px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 95px solid white;
    left: -94px;
    bottom: 0;
}
.exchangeSetBt{
    background: #c82d33 !important;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 7px !important;
    padding: 10px 20px !important;
}
.popUpExchImgOuter{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.popUpExchImgOuter h2{
    font-weight: 400;
    font-size: 34px;
    color: #333333;
}
.lineBtns{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.labSpaceBot{
    margin-bottom: 3px !important;
}
.messOuter{
    width: 100%;
    float: left;
    border: 1px dashed #ccc;
    border-radius: 7px;
    padding: 15px;
    background: #fbfbfb;
}
.messOuter p{
    font-size: 14px;
    font-weight: 500;
    color: #3a3434;
    margin-bottom: 10px;
}
.messOuter p b{
    font-size: 18px;
    letter-spacing: 1px;
    color: #000;
}
.tableHeadinNew{
    width: 100%;
    float: left;
    font-size: 14px;
    color: #001F3F;
    font-weight: 600;
    margin-bottom: 5px;
}
.forUploadImgErrorOuter{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.orderTabMain .MuiTabs-indicator{
    background-color: #c82d33 !important;
}
.orderTabMain .Mui-selected{
    color: #c82d33 !important;
}

.imCloIcon{
    position: absolute;
    font-size: 12px !important;
    right: 3px !important;
    top: 3px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    background: #fff;
    transition: .5s;
}
.veggiImgPreview:hover .imCloIcon{
    background: red;
    color: #fff;
}
.scanDivMain{
    width: 100%;
    float: left;
}
.scanDivMain .scanOrderContDiv{
    width: auto;
    box-shadow: none;
    padding: 0;
}
.readyForPicBt {
    background: #c82d33 !important;
}
.scanDivMain .qrDiv {
    border: 2px dashed #ffb3b6;
    padding: 15px 10px;
    /* box-sizing: content-box; */
}
.scanDivMain .nameOfCust {
    font-size: 15px;
    margin-bottom: 13px;
    margin-top: 0;
}
.scanDivMain .orderSumry label {
    font-size: 13px;
}
.scanDivMain .orderSumry {
    border: 1px solid #f5f5f5;
    border-radius: 7px;
    padding: 7px 10px;
    box-shadow: 0px 28px 10px -26px rgba(0, 0, 0, 0.1);
}
.scanDivMain .pickpSlot {
    font-size: 13px !important;
}
.scanDivMain .pickpSlotTime {
    font-size: 14px !important;
    color: #c82d33 !important;
    font-weight: 500 !important;
}
.scanDivMain .orderItemDetails {
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0px 35px 10px -26px rgba(0, 0, 0, 0.1);
}
.scanDivMain .orderItemDetails h1 {
    font-size: 15px;
    font-weight: 700;
}
.scanDivMain .orderItemDiv span p {
    font-size: 13px;
    color: #525252;
    font-weight: 500;
}
.scanDivMain .orderItemDiv span b {
    font-size: 14px;
    font-weight: 700;
}
.scanDivMain .totalAmt {
    font-weight: 700 !important;
    font-size: 17px !important;
    color: #c82d33 !important;
}
.scanDivMain .orderItemDiv span {
    margin-bottom: 7px;
}
.scanDivMain .orderItemDiv span:last-child {
    margin-bottom: 0px;
}
.scanDivMain .qrOter{
    margin-top: 0;
}
.scanDivMain .orderTop {
    align-items: center !important;
}
.reloadStarError{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #c82d33;
    background: #ffdedf;
    padding: 8px 20px;
    border-radius: 8px;
    box-shadow: 0px 18px 10px -13px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffa9ad;
    text-align: center;
}
.topExchangeRate{

}
.topExchangeRate .setExchImgDiv {
    width: 40px;
    height: 40%;
}
.topExchangeRate .setExchImgDiv img {
    height: 40px;
}
.topExchangeRate .popUpExchImgOuter h2 {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}
.topExchangeRate .popUpExchImgOuter{
    gap: 7px;
}
#myLeft .notificationOuter{
    /* display: none; */
}
.forImportB{
    min-width: auto !important;
}
.pintLogoOutMain{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin-top: -12px;
    border-bottom: 1px dashed #eee;
    padding-bottom: 5px;
    margin-bottom: 10px;
}
.pintLogoOutMain img{
    width: 40px;
}
.pintLogoOutMain h5{
    font-size: 12px;
    font-weight: 800;
    color: #000;
}

.menuViewLis .prodViewOuter{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    max-width: 615px;
}
.menuViewLis .freeImgOuter{
    width: 100%;
    float: left;
}
.menuViewLis .freeImgOuter img{
    padding: 0 5px;
    float: left;
}
.todayMenuTx .freeImgOuter img{
    float: left;
}
.unitDivMain .mealMenuCheckbox {
    justify-content: flex-start !important;
}
.complementoryMain .freeTagTextBox{
    max-width: 260px !important;
}
.swipeBtMain{
    width: 100%;
    float: left;
    margin: 200px 0;
    display: flex;
    justify-content: center;
}
.ReactSwipeButton {
    width: calc(100% - 1170px);
    height: 50px;
    position: relative;
    display: inline-block;
}
.rsbContainer {
    float: left;
    width: 100%;
    height: 100%;
    background: #CC3A44;
    border-radius: 50px;
    position: relative;
    box-shadow: inset 1px 1px 22px rgba(0, 0, 0, 0.5);
    overflow: hidden;
}
.rsbcText{
    color: #fff;
}

.notiContDivMain .MuiMenu-list{
    width: 340px !important;
}
.notLineT{
    padding: 15px 20px !important;
    white-space: normal !important;
    font-size: 14px !important;
    color: #fff !important;
    line-height: 23px !important;
    border-bottom: 1px dashed #e2e2e2 !important;
    display: flex !important;
    flex-direction: column;
}
.notLineT:last-child{
    border-bottom: none !important;
}
.notLineT b{
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.notLineT p{
    width: 100%;
    float: left;
    font-size: 13px;
    font-weight: 400;
    color: #333;
}
.todayMenuTx{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
}
.pageContDivMain {
    width: 100%;
    float: left;
    padding: 20px 20px;
    border: 1px solid #eee;
    border-radius: 10px;
    position: relative;
}
.panelHeading {
    width: 100%;
    float: left;
    font-weight: 600;
    font-size: 15px;
    color: #373737;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.menuItemsAc{
    width: 100%;
    display: flex;
    justify-content: flex-end; 
    padding-right: 20px;
}
.suportTicListMain{
    width: 100%;
    float: left;
    /* height: calc(100vh - 308px); */
    max-height: 520px;
    overflow: auto;
}
.suportTicListMain .suportTicList{
    max-height: 350px;
    overflow: auto;
    min-height: 145px;
}
.ticketDetailsTxt{
    /* height: calc(100vh - 730px); */
    max-height: 200px;
    overflow: auto;
}
.suportTicList{
    width: 100%;
    float: left;
    padding: 13px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0px 19px 6px -17px rgba(0, 0, 0, 0.09);
}
.suportListTop{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.suportListPro {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
    background: #f7f7f7;
    background-size: 100%;
    background-repeat: no-repeat;
    border: 1px solid #c2c2c2;
}
.suportListPro img{
    width: 100%;
    height: 36px;
    object-fit: cover;
}
.proNameNMob{

}
.proNameNMob h2{
    font-weight: 500;
    font-size: 12px;
    color: #565656;
    margin-bottom: 2px;
}
.proNameNMob p{
    font-weight: 300;
    font-size: 11px;
    color: #565656;
}
.suportListProOut{
    display: flex;
    align-items: center;
    gap: 10px;
}
.suportListBot{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.suportListBotLft{

}
.suportListBotLft h2{
    text-transform: capitalize;
    font-size: 13px;
    width: 100%;
    float: left;
    font-weight: 500;
}
.suportListBotLft p{
    width: 100%;
    float: left;
    font-size: 12px;
    color: #565656;
}
.suportListBotRight{

}
.ticketClose{
    background: #c82d33 !important;
    color: #fff !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
    padding: 5px !important;
    line-height: normal !important;
}
.tickLabl{
    background: #c6ffc6;
    font-size: 11px;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 13px;
    padding: 5px;
    border-radius: 0 0 9px 9px;
    box-shadow: 0px 11px 4px -8px rgba(0, 0, 0, 0.1);
}
.newNotificaionS .MuiPaper-rounded{
    border-radius: 25px;
    background: #fff;
    top: 70px !important;
    position: relative;
    overflow-x: initial;
    overflow-y: initial;
}

.newNotificaionS .MuiPaper-rounded::before{
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #e5e5e5;
    position: absolute;
    top: -11px;
    right: 71px;
}
.newNotificaionS .MuiMenu-list{
    max-height: 340px;
    overflow-y: auto;
}

.newNotificaionS .MuiMenu-list::-webkit-scrollbar {
    width: 2px;
    background:#dddddd;
    opacity: 0 !important;
}

.newNotificaionS .MuiMenu-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #e8e8e8; 
    border-radius: 15px;
    opacity: 0 !important;
}

.newNotificaionS .MuiMenu-list::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5); 
    background:red;
    opacity: 0 !important;
}
.ediDelMain{
    display: flex;
    gap: 10px;
}
.ediDelMain img{
    cursor: pointer;
}
.withTicket{
    padding-top: 36px;
}


.suportTicListMain::-webkit-scrollbar {
    width: 2px;
    background:#dddddd;
    opacity: 0 !important;
    visibility: hidden;
}

.suportTicListMain::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #e8e8e8; 
    border-radius: 15px;
    opacity: 0 !important;
    visibility: hidden;
}

.suportTicListMain::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5); 
    background:red;
    opacity: 0 !important;
    visibility: hidden;
}
.ticketDetailsMain{
    width: 100%;
    float: left;
}
.ticketDatNtime{
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    top: 10px;
    right: 17px;
    color: #c1c1c1;
}
.tickekDeailsTop{
    width: 100%;
    float: left;
    border-radius: 10px 10px 0 0;
    background: #c82d33;
}
.tickekDeailsTop .suportTicList{
    margin-bottom: 0;
    border: none;
}
.tickekDeailsTop .proNameNMob h2{
    color: #fff;
}
.tickekDeailsTop .proNameNMob p{
    color: #fff;
}
.tickekDeailsTop .suportListBotLft h2{
    color: #ffd7d7;
}
.tickekDeailsTop .suportListBotLft p{
    color: #ffc3c3;
}
.tickekDeailsTop .ticketDatNtime{
    color: #fff;
}
.tickekDeailsTop .tickLabl{
    background: #e63940;
    top: -4px;
    border-radius: 2px 2px 9px 9px;
}

.chatDivOuterScroll{
    width: 100%;
    float: left;
    max-height: 275px;
    overflow: auto;
}
.ticketDetailsMain .disctionBottomContMain {
    width: 100%;
    float: left;
    padding: 15px;
    border: 1px solid #c82d33;
    border-radius: 0 0 8px 8px;
    background: #fff;
}
.ticketDetailsMain .disctionComntScrol {
    max-height: 540px;
    width: 100%;
    float: left;
    overflow: auto;
    padding-bottom: 12px;
    padding-right: 20px;
}
.ticketDetailsMain .ticketChatMain {
    padding-right: 0;
}
.ticketDetailsMain .ticketChatLeft {
    width: 75%;
    /* max-width: 460px; */
    float: left;
    margin-left: 44px;
    position: relative;
}
.ticketDetailsMain .parkSocityInner {
    width: 100%;
    float: left;
    padding: 13px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0px 19px 6px -17px rgba(0, 0, 0, 0.09);
}
.ticketDetailsMain .parkSocityInnerTicket {
    cursor: pointer;
}
.ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket {
    padding: 11px 13px;
    background: #eaeaea;
    border: 0px none;
    box-shadow: 0px 0px 0px -17px rgba(0, 0, 0, 0);
    border-radius: 0px 15px 15px 15px;
}
.ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 13px solid transparent;
    border-right: 11px solid #eaeaea;
    left: -8px;
    top: 0;
}
.ticketDetailsMain .parkSocityTop {
    width: 100%;
    float: left;
    padding-left: 46px;
}
.ticketDetailsMain .ticketChatMain .parkSocityTop {
    padding-left: 0;
}
.ticketDetailsMain .parkSocityTopPro {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    left: 13px;
    background: #f7f7f7;
    /* background-image: url(https://cdn1.vectorstock.com/i/1000x1000/23/70/man-avatar-icon-flat-vector-19152370.jpg); */
    background-size: 100%;
    background-repeat: no-repeat;
    border: 1px solid #c2c2c2;
}
.ticketDetailsMain .parkSocityInnerTicket .parkSocityTopPro {
    left: 19px;
}
.ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parkSocityTopPro {
    left: -43px;
    right: auto;
    top: 2px;
}
.ticketDetailsMain .parkSocityTopPro img {
    width: 100%;
    height: 36px;
    object-fit: cover;
    background: #fff;
}
.ticketDetailsMain .parContTx {
    width: 100%;
    float: left;
    font-size: 12px;
    color: #565656;
    margin: 2px 0 0;
}
.ticketDetailsMain .parkSocityInnerTicket .parContTx {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 20px;
}
.ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parContTx {
    margin-top: 0px;
    color: #676767;
    font-weight: 500;
}
.ticketDetailsMain .ticketChatRight {
    width: 75%;
    /* max-width: 460px; */
    float: right;
    margin-right: 44px;
    position: relative;
}
.ticketDetailsMain .parContTxDate {
    width: 100%;
    margin-top: 7px !important;
    text-align: left;
    font-size: 11px !important;
    color: #7aaed3;
    letter-spacing: 1px;
}
.ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parContTxDate {
    margin-top: 5px;
    color: #676767;
    font-weight: 500;
    text-align: left;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.6px !important;
}
.ticketDetailsMain .ticketChatRight {
    clear: both;
}
.ticketDetailsMain .ticketChatRight .parkSocityInnerTicket {
    text-align: right;
    padding: 11px 13px;
    background: #94ff8a;
    border: 0px none;
    box-shadow: 0px 0px 0px -17px rgba(0, 0, 0, 0);
    position: relative;
    border-radius: 15px 0px 15px 15px;
}
.ticketDetailsMain .ticketChatRight .parkSocityInnerTicket::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 13px solid transparent;
    border-left: 11px solid #94ff8a;
    right: -8px;
    top: 0;
}
.ticketDetailsMain .ticketChatMain .parkSocityTop {
    padding-left: 0;
}
.ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parkSocityTopPro {
    right: -43px;
    left: auto;
    top: 2px;
}
.ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parContTx {
    margin-top: 5px;
    color: #0a7400;
    font-weight: 500;
}
.ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parContTxDate {
    margin-top: 5px;
    color: #0a7400;
    text-align: right;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.6px !important;
}
.ticketDetailsMain .typeComentOut {
    width: 100%;
    float: left;
    padding: 12px 0px 0px;
    border-top: 1px dashed #ccc;
    position: relative;
}
.ticketDetailsMain .typeComentOutIco {
    width: 100%;
    float: left;
    position: relative;
}
.ticketDetailsMain .typeComentOut svg {
    position: absolute;
    right: 11px;
    top: 11px;
    color: #08AE36;
    cursor: pointer;
}
.ticketDetailsMain .typeComentOut svg {
    right: 0px;
    top: 11px !important;
    font-size: 28px;
}
.ticketDetailsMain .textAriaChat {
    width: calc(100% - 35px);
    border: 1px solid #ccc;
    padding: 9px 15px;
    outline: none;
    border-radius: 5px;
    font-size: 12px;
}
.ticketDetailsMain .parkSocityTop h4 {
    font-weight: 500;
    font-size: 12px;
    color: #565656;
    margin-bottom: 2px;
}




.myNewAttach .atchmentMain {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.myNewAttach .atchMain {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.myNewAttach .atchMain svg {
    color: #674BDD;
    font-size: 22px;
}
.myNewAttach .atchMain input {
    opacity: 0;
    position: absolute;
    left: 0;
}
input[type=file], input[type=file]::-webkit-file-upload-button {
    cursor: pointer;
}
.myNewAttach .atchMain span {
    font-weight: 400;
    font-size: 14px;
    color: #674BDD;
    margin-left: 10px;
}
.myNewAttach .formatNot {
    color: red !important;
    font-style: italic;
    font-size: 12px;
    transition: .3s;
    transform: translateX(-133px);
    visibility: hidden;
    opacity: 0;
}
.myNewAttach .atchMain span {
    font-weight: 400;
    font-size: 14px;
    color: #674BDD;
    margin-left: 10px;
}
.myNewAttach .atchMain:hover .formatNot {
    opacity: 1;
    visibility: visible;
    transform: translateX(-0px);
}
.myNewAttach .atchmentMain {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.myNewAttach .fileName {
    margin-top: 10px;
}
.myNewAttach .fileName span {
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 20px;
    font-size: 14px;
    color: #333;
}
.mealTypeErrorMg{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
}
.otherProdctHeadNew{
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: .5px;
    margin-bottom: 0px;
    background: #494949;
    padding: 7px 15px;
    border-radius: 6px;
}

.leaveHistoTabMain{
    width: 100%;
    float: left;
}
.leaveHisTabCont{
    width: 100%;
    float: left;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    border: 1px solid #fafafa;
    background: #fafafa;
}
.underMenu{
    padding-left: 40px;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 1px dashed #efefef;
    transition: .3s;
}
.underMenu:hover{
    background: #f2f2f2;
    border-radius: 8px;
}
.underMenu:last-child{
    border-bottom: none;
}

.underMenu h1{
    font-size: 18px;
    font-weight: 500;
    position: relative;
}
.underMenu h1::before{
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background: #414547;
    right: 100%;
    top: 50%;
    margin-top: -3px;
    margin-right: 11px;
    border-radius: 100%;
}
.underMenu .accordTotalOrder{
    font-size: 27px;
    width: 100%;
    max-width: 64px;
    display: flex;
    justify-content: space-between;
}
.underMenu .accordTotalOrder img{
    width: 19px;
}
.orderSumTbOnly{
    background: #fdfdfd;
    padding: 20px;
    border-radius: 0 0 15px 15px;
}
.orderSumTbOnly .todyMenHeading{
    text-align: left;
}
.orderSumTbOnly .prodSearchInpt{
    max-width: 240px;
    float: right;
}



.scrollBd table.scrolldown {
    width: 100%;
}

/* To display the block as level element */
.scrollBd table.scrolldown tbody,
table.scrolldown thead {
    display: block;
}

.scrollBd thead tr th {

}

.scrollBd table.scrolldown tbody {
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}


.scrollBd tbody td,
thead th {
    width: 200px;
}

/* Added By Snehal Start  */

.qr-reader {
    width: 430px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
  }
  
  .qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
  }
  
  .qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }



  /* added by vaishali */
  .openScanOuter{
    float: left;
    /* width: 100%; */
  }

  .openScanOuter input{
    width: 450px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #878787;
    margin: 0 10px;
    margin-bottom: 10px;
  }


  /* Media Queries for mobile screens */
  @media (max-width: 426px) {
    .qr-reader {
      width: 100%;
    }
  }

  /* End */







































/* @media print {
 

    @page {
        margin-top: 0;
        margin-bottom: 0px;
    }
    body {
        padding-top: 20px;
        padding-bottom: 20px ;
    }

  } */

.popListingMain{
    width: 100%;
    float: left;
    border-radius: 10px;
    max-height: 400px;
    overflow: auto;
}
.popListingInr{
    width: 100%;
    float: left;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #eee;
    position: relative;
    padding-left: 97px;
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}
.activeTicket{
    background: #ffdbdb;
    border: 2px solid #f4adad;
}
.popListPro{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #eee;
    overflow: hidden;
    position: absolute;
    top: 20px;
    left: 20px;
}
.popListPro img{
    width: 100%;
    height: 50px;
    object-fit: cover;
}

.addEmploAccordingMain{
    width: 100%;
    float: left;
    margin-top: 20px;
  }
  .addEmploAccordingMain .settingInr{
    padding: 0;
    border: none;
  }
  .addEmploAccordingMain .settingInr .allownceFormOutMain h1{
    font-size: 14px;
    font-weight: 600;
    color: #333232;
  }
  .addEmploAccordingMain .settingInr .allownceFormOutMain{
    max-width: 100%;
  }
  .smBt{
    background: #3498DB !important;
    max-width: 200px;
    gap: 10px;
  }
  .addEmploAccordingMain .mainListInrCont{
    margin: 0;
  }
  .employeeProfileLeft .addEmploAccordingMain{
    margin-top: 0;
  }
  .employeeProfileLeft .addEmploAccordingMain .settingInr .accordingMain .MuiAccordionDetails-root{
    padding-top: 0;
  }

.notAddedx{
    font-size: 13px;
    font-weight: 500;
    color: #c82d33;
    text-align: center;
    background: #ffebeb;
    border: 1px dashed #c82d33;
    border-radius: 5px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.addedMemCount{
    font-size: 10px;
    color: #179d03;
    font-weight: 500;
    margin-left: 6px;
}
.manageActIcons{
    display: flex;
    gap: 15px;
}
.manageActIcons svg{
    font-size: 25px;
    cursor: pointer;
}
.managMarkCheck{
    color: green !important;
}
.managRemoveCheck{
    color: red;
}
.activeMenu{
    background: #c82d33 !important;
}
.addEdiCheckDiv{
    padding: 0;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    background: none;
}
.popDetailS h1{
    font-size: 13px;
    font-weight: 600;
    color: #F44336;
    margin-bottom: 5px;
}
.popDetailS b{
    font-size: 12px;
    font-weight: 600;
    color: #333;
    width: 100%;
    float: left;
}
.popDetailS span{
    font-size: 13px;
    font-weight: 500;
    color: #333;
}
.greenMarkCheck{
    color: green !important;
}
.moreInoAdd{
    text-align: center;
    color: #374db6;
    font-size: 15px;
    cursor: pointer;
}
.newEdiCheckDiv{
    justify-content: flex-start;
    gap: 30px;
}
.NewallownceFormOutMain{
    max-width: initial;
}
.financeLeftMain{
    width: 100%;
    float: left;
}
.financeRightMain{
    width: 100%;
    float: left;
}
.amountSpendMain{
    width: 100%;
    float: left;
    border-radius: 10px;
    background: #343434;
    color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
}
.amuntSpndLft{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.amuntSpndLft h1{
    color: #FA6C6C;
    font-weight: 700;
    font-size: 48px;
    line-height: 45px;
}
.amuntSpndLft p{
    font-weight: 400;
    font-size: 18px;
    color: #fff;
}
.amuntSpndRght{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.amuntSpndRght select{
    width: 100%;
    max-width: 200px;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    padding: 6px 10px;
    font-size: 18px;
    font-weight: 400;
    outline: none;

    background: url('../images/selectIconWhite.svg') no-repeat !important;
    background-position: calc(100% - 0.75rem) center !important;
    appearance: none !important;
    padding-right: 2rem !important;
}
.amuntSpndRght select option{
    background: #343434;
}
.addReceiptMain{
    width: 100%;
    float: left;
    padding: 20px;
    border-radius: 10px;
    background: #F3FFEF;
}
.reciptHeading{
    width: 100%;
    float: left;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
}
/* .forWhiteDrop{
    position: absolute;
} */
.forWhiteDrop select{
    background: #fff !important;
    position: relative;
}
.forWhiteDrop{
    position: relative;
}
.forWhiteDrop::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #333;
    bottom: 18px;
    z-index: 9;
    right: 10px;
}
.uploReciptBt{
    width: 100%;
    float: left;
    position: relative;
    border: 1px solid #c82d33;
    height: 43px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #cb2d33;
    font-size: 15px;
    font-weight: 600;
    transition: .3s;
}
.uploReciptBt:hover{
    background: #cb2d33;
    color: #fff;
}
.uploReciptBt input{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.uploadedReciptMain{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.uploadedReciptInr{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 9px;
}
.uploadedReciptInr h1{
    font-size: 16px;
    font-weight: 400;
    color: #878787;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    width: 100%;
}
.uploadedReciptInr span{
    width: 100%;
    border-bottom: 2px dashed #ccc;
}
.uploadedReciptInr svg{
    cursor: pointer;
}
.readyPicColr{
    color: #05F400;
}
.preparingColr{
    color: #FFF500;
}








/* warnings - damage cost css added by Vaishali  */
.dmCost{
    /* width: 100%; */
    float: left;
}
.dmCost p{
    font-size: 12px;
    color: #868686;
}
.dmCost h3{
    font-size: 12px;
    font-weight: 700;
}
.typeWarnNew{
    width: 100%;
    float: left;
}
.typeWarnNew p{
    font-size: 12px !important;
    color: #333333 !important;
    text-overflow:unset !important ;
    margin-bottom: 10px !important;
    text-wrap: unset;
    width: 100% !important;
    float: left;
}
.typeWarnNew span{
    font-size: 12px !important;
    color: #333333 !important;
    text-overflow:unset !important ;
    margin-bottom: 10px !important;
    text-wrap: unset;
    width: 100% !important;
    float: left;
}

.warnDetailsNewTop{
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    background-color: #f9f9f9;
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    gap: 15px;
}
.warnDetailsNewRefLayout{
    flex-direction: column !important;
    align-items: flex-start !important;
}
.warnDetailsNewRefLayout p{
    float: left;
    width: auto !important;
    margin-bottom: 5px !important;
    font-weight: 600 !important;
}
.warnNewRightmargin{
    margin-right: 0 !important;
}
.newWarnDescP{
    width: 100%;
    float: left;
}
.newWarnDescP p{
    margin-top: 10px;
    font-size: 13px !important;
    color: #333333 !important;
    letter-spacing: 1px !important;
    line-height: 20px !important;
    text-wrap: unset;
    max-width: 100% !important;
    /* text-overflow:none !important; */
}
.modalSearchBgNew{
    background-color: #f7f7f7 !important;
}
.closedOnNew{
    font-size: 13px !important;
    color: #868686 !important;
    font-weight: 400 !important;
    text-align: right !important;
    width: 100%;
}
.closedOnNew span{
    color: #333333 !important ;
    font-weight: 600 !important;
}
.wrongTimeScanOrder{
    width: 100%;
    float: left;
    background-color: #F5f5f5;
    color: #0b0b0b;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
}
.wrongTimeScanOrder span{
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
    color: #c82d33;
    letter-spacing: 1px;
}

/* Status Css */
.statusWraper{
    width: 100%;
    float: left;
    padding: 0 20px;
}
.statusTopMain{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.statusLogLeft{
    float: left;
    display: flex;
    align-items: center;
    gap: 15px;
}
.statusLogLeft img{
    width: 100%;
    max-width: 100px;
}
.statusLogLeft h1{
    font-weight: 500;
    font-size: 30px;
    color: #000;
    line-height: 36px;
}
.statusLogLeft p{
    font-size: 24px;
    font-weight: 400;
    color: #656464;
}
.statusLogRight{
    float: left;
}
.statusLogRight img{
    width: 100%;
    max-width: 230px;
}
.statusContentMain{
    /* width: 100%; */
    width: 65%;
    float: left;
    background-image: url('../images/statusBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    border-radius: 20px;
    height: calc(100vh - 128px);
    margin-bottom: 20px;
    overflow-y: auto;
}
.statusContentMain table{
    text-align: left;
}
.statusContentMain table thead tr th{
    font-size: 20px;
    font-weight: 800;
    padding: 10px 25px;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
}
.statusContentMain table tbody tr td{
    font-size: 20px;
    font-weight: 600;
    padding: 10px 25px;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
}
.readyForImg{
    display: flex;
    align-items: center;
    color: #05F400;
}
.readyForImg img{
    width: 39px;
}

.preparingImg{
    display: flex;
    align-items: center;
    color: #FFF500;
    gap: 15px;
}
.preparingImg img{
    width: 39px;
}
.newSta{
    /* background: #feffb0; */
    /* color: #474747; */
    /* padding: 1px 15px; */
    font-weight: 600;
    border-radius: 5px;
    font-style: italic;
}
.tdSpecilTx{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 17px;
    display: none;
}
.tdSpecilTx img{
    width: 60px;
}
.tdSpecilTx h1{
    font-size: 30px;
    font-weight: 400;
    color: #000;
}
.tdSpecilTx h1 span{
    font-size: 30px;
    font-weight: 700;
    color: #FE9800;
}
.statusContentMain::-webkit-scrollbar {
    opacity: 0 !important;
}

.statusContentMain::-webkit-scrollbar-track {
    opacity: 0 !important;
}

.statusContentMain::-webkit-scrollbar-thumb {
    opacity: 0 !important;
}


.fixTableHead::-webkit-scrollbar {
    width: 0;
    opacity: .5 !important;
}

.fixTableHead::-webkit-scrollbar-track {
    opacity: .5 !important;
}

.fixTableHead::-webkit-scrollbar-thumb {
    opacity: .5 !important;
}

.fixTableHead { 
    overflow-y: auto;
    height: 100%;
  } 
  .fixTableHead thead th { 
    position: sticky; 
    top: 0; 
  } 
  .fixTableHead table { 
    border-collapse: collapse;         
    width: 100%; 
  } 
  .fixTableHead th, 
  td { 
    padding: 8px 15px; 
  } 
  .fixTableHead th { 
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  } 
  .dispOrder{
    display: flex;
    align-items: center;
    gap: 50px;
  }
  .dispOrder h1{
    font-size: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .dispOrder h1 span{
    font-weight: 800;
  }

/* Status Css End */


.reportsSecMain{
    width: 100%;
    float: left;
}
.repoFilterMain{
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.repoFilterBt{
    color: #333333 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    border: 1px solid #ccc !important;
    width: 100%;
    max-width: 150px;
    padding: 5px !important;
    border-radius: 5px !important;
}
.activeRepoFilterBt{
    background: #c82d33 !important;
    color: #fff !important;
    border: 1px solid #c82d33 !important;
}
.selectDateRangeMain{
    width: 100%;
    float: left;
    text-align: center;
    background: #fcfcfc;
    padding: 7px;
    border-radius: 7px;
    border: 1px dashed #ccc;
}
.selectDateRangeInr{
    display: inline-flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
}
.dateRangReport{
    display: flex;
    align-items: center;
    gap: 10px;
}
.dateRangReport input{

}
.dateRangReport b{
    font-size: 12px;
    font-weight: 500;
}
.updateDataB{
    max-width: 200px;
    padding: 8px 15px !important;
    background: #121210 !important;
    color: #fff !important;
    border: 1px solid #121210 !important;
}
.repoSubHeading{
    width: 100;
    float: left;
    font-weight: 400;
    font-size: 16px;
    color: #878787;
}
.additionalFiltterMain{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 15px;
}
.reportChartsMain{
    width: 100%;
    float: left;
    /* background: #F8F9FA;
    padding: 15px;
    border-radius: 8px; */
}
.repoChartContDiv{
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.repoChartCont{
    width: 100%;
    float: left;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 97px;
}
.repoChartCont b{
    font-weight: 700;
    font-size: 25px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 6px;
    line-height: normal;
}
.repoChartCont b svg{
    color: #FFE600;
    font-size: 30px;
}
.repoChartCont p{
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
}
.chartRepoBlack{
    background: #121210;
}
.chartRepoGreen{
    background: #1CAF75;
}
.chartRepoBlue{
    background: #758CA7;
}
.repoOrdersGraphMain{
    width: 100%;
    float: left;
    background: #fff;
    box-shadow: 10px 10px 3px rgba(0, 0, 0, 0.02);
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ececec;
    min-height: 313px;
}
.orderGrapHeading{
    width: 100%;
    float: left;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}
.repoGraIndec{
    width: 100%;
    float: left;
    display: flex;
    gap: 18px;
    justify-content: space-between;
}
.repoGraIndec span{
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 5px;
}
.repoGraIndec span svg{
    font-size: 17px;
    display: none;
}
.activeRepoOrder{
    color: #c82d33 !important;
    font-weight: 600 !important;
}
.activeRepoOrder svg{
    display: block !important;
}
.repoOrderGrpDetai{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 10px;
}
.repoOrderGrpDetai em{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    float: left;
}
.repoOrderGrpDetai p{
    font-weight: 600;
    font-size: 16px;
    color: #333333;
}
.repoOrderGrpDetai span{
    font-weight: 400;
    font-size: 12px;
    color: #878787;
}
.blackDetai p{
    color: #121210;
}
.yellowDetai p{
    color: #EA902A;
}
.greenDetai p{
    color: #3DC100;
}

.blackDetai em{
    background: #121210;
}
.yellowDetai em{
    background: #EA902A;
}
.greenDetai em{
    background: #3DC100;
}

.orderPieGr{
    width: 100%;
    float: left;
}
.orderPieGr .MuiChartsLegend-column{
    display: none;
}
.orderPieGr div{
    width: 100%;
}
.orderPieGr div svg{

}
.orderPieGr div svg g{
    
}
.orderPieGr div svg g .MuiPieArc-root:first-child{
    fill: #3DC100 !important;
}
.orderPieGr div svg g .MuiPieArc-root:nth-child(2){
    fill: #EA902A !important;
}
.orderPieGr div svg g .MuiPieArc-root:last-child{
    fill: #121210 !important; 
}
.departChartMain{
    width: 100%;
    float: left;
    background: #fff;
    box-shadow: 10px 10px 3px rgba(0, 0, 0, 0.02);
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ececec;
}
.reportSearchMain{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 15px;
}
.repoBtn{
    width: auto;
    background: #c82d33 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    min-width: 150px !important;
    height: 44px;
    gap: 8px;
}
.btDropHover{
    width: 150px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    font-weight: 400;
    font-size: 14px;
    border-radius: 8px;
    margin-top: -45px;
    position: absolute;
    background: #fff;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    overflow: hidden;
}
.hoverForDrop:hover .btDropHover{
    opacity: 1 !important;
    visibility: visible !important;
    margin-top: 5px;
}
.btDropHover p{
    font-weight: 400;
    font-size: 12px;
    color: #878787;
    padding: 10px 15px;
    cursor: pointer;
}
.btDropHover p:hover{
    background: #f6f6f6;
    color: #000;
}
.withStar{
    display: flex;
    line-height: normal;
    align-items: center;
    gap: 5px;
}
.withStar svg{
    color: #FFE600;
    font-size: 15px;
}
.forReadyFP{
    color: #EA902A !important;
}
.forCompletedS{
    color: #3DC100 !important;
}
.NotPickedUpColor{
    color: #02b2af !important;
}
.forGrapfDropDown{
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    font-size: 13px;
    color: #333;
    padding: 0 15px;
}
.forGrapfDropDownOut{
    justify-content: flex-end;
}
.starAlloMain{

}
.orderGrapHeading span{
    font-size: 12px;
    color: #878787;
}
.starAlloMain .orderPieGr div svg g .MuiPieArc-root:first-child{
    fill: #b3b3b3 !important;
}
.starAlloMain .yellowDetai em {
    background: #b3b3b3;
}
.starAlloMain .yellowDetai p {
    color: #b3b3b3;
}
.forPassEye{
    position: relative;
}
.forPassEye svg{
    position: absolute;
    bottom: 13px;
    right: 12px;
    cursor: pointer;
}
.forPassEye .errorSt{
    position: absolute;
}

.emploRepoTab{
    
}
.emploRepoTab .chartRepoGreen {
    background: #E92A18;
}

.salesReportsMain{

}
.salesReportsMain .repoChartContDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.trendingUpIcon{
    color: #0dba0d !important;
}
.trendingDownIcon{
    color: red !important;
}
.trendingUpIcon svg{
    color: #0dba0d !important;
    font-size: 25px !important;
}
.trendingDownIcon svg{
    color: red !important;
    font-size: 25px !important;
}
.forFle{
    display: flex;
    gap: 15px;
}
.topNLowestSaleMain{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.topNLowestSaleInr{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.topSalePro{
    display: flex;
    align-items: center;
    gap: 10px;
}
.topSalePro em{
    min-width: 50px;
    height: 60px;
    max-width: 50px;
    overflow: hidden;
}
.topSalePro em img{
     width: 100%;
}
.topSalePro h1{
    font-weight: 500;
    font-size: 13px;
    color: #2D3748;
}
.topSalePro p{
    font-weight: 700;
    font-size: 10px;
    color: #2D3748;
}
.topSalePro p span{
    font-size: 10px;
    color: #C82D33;
    font-style: italic;
    font-weight: 500;
}
.stokBt{
    background: #FEECEB !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    border-radius: 50px !important;
    text-transform: capitalize !important;
    color: #AA3028 !important;
    min-width: auto !important;
    padding: 2px 15px !important;
}

.css-1la267r-MuiAreaElement-root{
    fill: #31C61E !important;
  }
  .hovContTxt{
    position: relative;
    cursor: pointer;
    transition: .3s;
  }
  .hovContTxt::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 19px solid #ffe3e5;
    right: 0;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
  }
  .hovContDiv{
    width: 100%;
    min-width: 600px;
    background: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 8px;
    border: 5px solid #ffe3e5;
    position: absolute;
    transition: .3s;
    top: 50%;
    margin-top: -230px;
    left: 100%;
    min-height: 340px;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
  }

  .forFle b{
    color: #c82d33;
    font-weight: 600;
  }
  .hovDivRangDat{
    display: flex;
    gap: 13px;
    justify-content: space-between;
  }
  .hovDivRangDat .prodSearchInpt{
    height: 30px;
    font-size: 10px;
  }
  .addedHov .hovContDiv{
    opacity: 1;
    visibility: visible;
  }
  .addedHov::before{
    opacity: 1;
    visibility: visible;
  }
  .hovClos{
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .emploChangePasOt{
    width: 100%;
    max-width: 400px;
    display: inline-flex;
  }

.hovContTxt:hover .hovContDiv{
    opacity: 1;
    visibility: visible;
}
.hovContTxt:hover::before{
    opacity: 1;
    visibility: visible;
}
.forEmployeePreview .hovContDiv{
    min-height: 94px;
    margin-top: -98px;
    margin-top: -126px;
}
.emploRepoDetMain{
    width: 100%;
    float: left;
    display: flex;
    gap: 20px;
    align-items: center;
}
.emploRepoDetPic{
    width: 122px;
    height: 122px;
    border-radius: 100%;
    overflow: hidden;
}
.emploRepoDetPic img{
    width: 100%;
    height: 122px;
    object-fit: cover;
}
.emploRepoDetTx{

}
.emploRepoDetTx h1{
    font-weight: 600;
    font-size: 18px;
    color: #C82D33;
}
.emploRepoDetTx p{
    font-weight: 500;
    font-size: 12px;
    color: #333333;
}
.reportingToEmpl{
    float: left;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 26px;
}
.reportingToEmpl h1{
    font-weight: 600;
    font-size: 24px;
    color: #333333;
}
.reportingToEmpl p{
    font-weight: 300;
    font-size: 12px;
    color: #333333;
}
.reportingToEmpl p b{
    font-weight: 600;
    font-size: 12px;
    color: #333333;
}
.forModalSpace .modalSinlInputDiv .hovContDiv{
    display: block;
    position: static;
    margin: 0;
    opacity: 1;
    visibility: visible;
    border: 2px dashed #eee;
    box-shadow: 0px 38px 10px -30px rgba(0, 0, 0, 0.1);
}
.ordBannerMain{
    width: 34%;
    float: right;
    height: calc(100vh - 128px);
    border-radius: 20px;
    padding: 65px 20px 20px;
    background-image: linear-gradient(#DBAA07, #0D8563);
    color: #fff;
    position: relative;
    text-align: center;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ordBannerMain::-webkit-scrollbar {
    opacity: 0 !important;
}

.ordBannerMain::-webkit-scrollbar-track {
    opacity: 0 !important;
}

.ordBannerMain::-webkit-scrollbar-thumb {
    opacity: 0 !important;
}
.topLinInSt{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}
.ordBannerMain h1{
    font-size: 22px;
    font-weight: 400;
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
}
.ordBannerMain h2{
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    line-height: 39px;
}
.ordrBannerPicDiv{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bannerPicOrd{
    width: 110px;
    height: 110px;
    border-radius: 100%;
    overflow: hidden;
    background: #eee;
    margin: 17px 0 11px;
}
.bannerPicOrd img{
    width: 100%;
    height: 110px;
    object-fit: cover;
}
.ordrBannerPicDiv h1{
    font-size: 24px;
    font-weight: 500;
    color: #EEFF00;
    margin-bottom: 0;
}
.ordrBannerPicDiv span{
    font-size: 20px;
    font-weight: 400;
}
.bneBtCont{
    width: 100%;
    float: left;
    margin-top: 20px;
}
.bneBtCont b{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    display: flex;
    width: 100%;
}
.bneBtCont p{
    font-size: 15px;
    font-weight: 400;
}

.blon{
    width: 98px;
    position: absolute;
    left: 61px;
    top: 50%;
    margin-top: -73px;
}
.blonRight{
    width: 98px;
    position: absolute;
    right: 61px;
    top: 50%;
    margin-top: -73px;
}
.blon2{
    width: 43px;
    position: absolute;
    top: 50%;
}
.blon3{
    width: 40px;
    position: absolute;
    right: 77px;
    top: 53px;
}
.forDateIco{
    position: relative;
}
.forDateIco svg{
    position: absolute;
    bottom: 14px;
    right: 10px;
}
.forDateIco .errorSt{
    position: absolute;
}
.newDtPik{
    width: 100%;
    float: left;
}
.newDtPik .MuiStack-root{
    padding: 0;
}
.newDtPik .MuiInputBase-formControl{
    height: 40px !important;
    overflow: hidden !important;
    border-radius: 5px !important;
}
.toInDt{
    padding: 0 5px;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
}
.reLIm{
    width: 34px;
    height: 32px;
    margin: 3px 0;
    cursor: pointer;
}
.emtyAcmt{
    color: #c82d33;
    font-weight: 700;
}
.reciptSlider{
    width: 100%;
    border: none;
}
.reciptSlider .carousel .slider-wrapper {
    width: 250px;
}
.tblScrl{
    overflow: auto;
}
.rightsMain{
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
}
.rightsMain div{
    width: 100%;
    max-width: 335px;
    border-radius: 8px;
    border: 1px solid #ccc;
}
.weeklyAccordion{
    padding: 0;
    border: none;
}
.myNewSer{
    width: 100%;
    float: left;
    margin-bottom: 15px;
}
.webLogoOut{
    width: 50px;
    height: 40px;
    border-radius: 5px;
    overflow: hidden;
    transition: .3s;
}
.webLogoOut img{
    width: 100%;
    height: 40px;
    object-fit: cover;
    max-width: none;
}
.smoothSlide .webLogoOut{
    position: absolute;
    left: 2px;
}



::-webkit-scrollbar {
    opacity: 0;
    visibility: hidden;
}

::-webkit-scrollbar-track {
    opacity: 0;
    visibility: hidden;
}

::-webkit-scrollbar-thumb {
    opacity: 0;
    visibility: hidden;
}

.posiMins .errorSt{
    position: static;
}



  
  .dragNddropMain {

  }
  
  .sortable-list {
    list-style-type: none;
    padding: 0;
  }
  
  .sortable-item {
    margin: 8px 0;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: grab;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .sortable-item.dragging {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .sortable-item:hover {
    background-color: #d9d9d9;
  }

.scanDivMain .orderNewTp{
    align-items: center !important;
    margin-bottom: 7px;
}
.scanDivMain .orderNewTp .nameOfCust{
    margin-bottom: 0;
    width: auto;
    font-size: 20px;
}
.scanDivMain .orderNewTp .printNow{
    margin-top: -15px;
}

.pulsingBt {

  }
  .pulsingBt {
    min-width: 42px !important;
    max-width: 42px;
    height: 42px;
    padding: 10px !important;
    background-color: #C30F42 !important;
    cursor: pointer !important;
    outline: 0 !important;
    border: none !important;
    border-radius: 100% !important;
    transition: all .3s ease !important;
    animation: pulse 1.6s infinite;
    /* position: absolute !important;
    top: 21px;
    z-index: 9;
    right: 78px; */
  }
  .pulsOut{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .pulsingBt svg{
    color: #fff;
    font-size: 30px;
  }
  

  
  
  /* Animations */
  
  @-webkit-keyframes pulse {
    
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(215, 17, 73, .85);
      box-shadow: 0 0 0 0 rgba(215, 17, 73, .85);
    }
    
    70% {
      -webkit-box-shadow: 0 0 0 2em rgba(215, 17, 73, 0);
      box-shadow: 0 0 0 2em rgba(215, 17, 73, 0);
    }
    
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(215, 17, 73, 0);
      box-shadow: 0 0 0 0 rgba(215, 17, 73, 0);
    }
    
  }
  
  @keyframes pulse {
    
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(215, 17, 73, .85);
      box-shadow: 0 0 0 0 rgba(215, 17, 73, .85);
    }
    
    70% {
      -webkit-box-shadow: 0 0 0 2em rgba(215, 17, 73, 0);
      box-shadow: 0 0 0 2em rgba(215, 17, 73, 0);
    }
    
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(215, 17, 73, 0);
      box-shadow: 0 0 0 0 rgba(215, 17, 73, 0);
    }
    
  }
  
.lineCh{
    width: 100%;
    float: left;
    display: flex;
    gap: 20px;
}
.outVegNon{
    display: flex;
    gap: 5px;
}
.vegNonVegImg{

}
.vegNonVegImg img{
    width: 9px;
}

.bodyScanNew .nameOfCust{
    margin: 0;
}
.bodyScanNew .orderTop h1{
    width: auto;
    white-space: nowrap;
}
.newAmnttSpendiv{
    width: 100%;
    float: left;
}
.bgTr{
    border: none;
    background: #fff;
}
.leftNavMain .MuiCollapse-entered{
    background: #555555;
}
.leftNavMain .MuiCollapse-entered .MuiListItemButton-root{
    padding-left: 50px;
}
.leftNavMain .MuiCollapse-entered .MuiListItemButton-root .MuiListItemIcon-root{
    min-width: 30px;
}
.leftNavMain .MuiCollapse-entered .MuiListItemButton-root svg{
    font-size: 22px;
    color: #d8d8d8;
}
.leftNavMain .MuiCollapse-entered .MuiListItemButton-root .MuiTypography-root{
    font-size: 13px;
    color: #d8d8d8;
}
.leftNavMain .MuiCollapse-entered .MuiListItemButton-root:hover{
    background: #3e3e3e;
}

.refNotx{
    padding-right: 53px;
    overflow: auto !important;
    text-overflow: initial !important;
    max-width: none !important;
    text-align: right;
}
.thnkOuter{
    width: 100%;
    text-align: center;
}
.thnkOuter h1{
    font-size: 22px;
    color: green;
    margin-bottom: 10px;
    font-weight: 600;
}
.thnkOuter p{
    font-size: 14px;
    line-height: 24px;
}
.completedOrdSt{
    background: #9cff9c !important;
    color: #333 !important;
}
.dmCostNew h3{
    font-weight: 500;
    font-size: 10px;
}
.typeWarnNewHeading{
    width: 100%;
    float: left;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 17px;
}
.typeWarnNewHeading p{
    max-width: initial;
    font-size: 14px !important;
    display: flex;
    gap: 20px;
    align-items: center;
    padding-bottom: 10px;
}
.typeWarnNewHeading p span{
    font-size: 16px !important;
    width: auto !important;
    margin: 0 !important;
}
.addDamaCost{
    border: 3px solid #7d7d7d;
    width: 25px;
    height: 24px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.addDamaCost svg{
    font-size: 17px;
}
.totDamageCost{
    position: absolute;
    top: 0;
    font-size: 12px;
    background: #eee;
    padding: 3px 15px;
    border-radius: 0 0 10px 10px;
    left: 140px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}
.totDamageCost b{
    display: flex;
    font-size: 10px;
    align-items: center;
}
.totDamageCost b svg{
    font-size: 13px;
}
.activeTicket .totDamageCost{
    background: #ffc7c7 !important;
}
.rightTotCos{
    display: flex;
    float: left;
    align-items: center;
}
.rightTotCos svg{
    font-size: 13px;
}
.warNewDis{
    gap: 15px;
    justify-content: center;
}
.warnNewFilt{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}
.warnNewFilt .visitorSearch{
    /* width: auto; */
}
.warnNewFilt .MuiOutlinedInput-root{
    height: 44px;
}
.warnNewFilt .MuiFormLabel-root{
    transform: translate(14px, -9px) scale(0.75);
    background: #fff;
}
.warnNewFilt .sortingIcoOut .MuiToggleButton-standard {
    padding: 10px 0px 10px 10px;
}
.notPickedTx{
    font-size: 13px;
    font-weight: 500;
    color: #c82d33;
    text-align: center;
    background: #ffebeb;
    border: 1px dashed #c82d33;
    border-radius: 5px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    justify-content: space-between;
}
.notPickedTx div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.notPickedTx svg:last-child{
    cursor: pointer;
}
.scanEmplSearch{
    justify-content: flex-start;
}
.scanEmplSearch .prodFilter{
    max-width: 533px;
}
.scanEmplSearch .pulsingBt{
    min-width: auto !important;
    max-width: initial;
    border-radius: 5px !important;
    padding: 0 15px !important;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 13px;
    text-transform: capitalize;
}
.modEmploDetails .mainListInrCont{
    margin: 0;
}
.modEmploDetails .basicHeading{
    font-size: 17px;
    font-weight: 500;
}
.modEmploDetails .modalBtSmallWidth {
    width: 100% !important;
    height: 52px !important;
    border-radius: 8px !important;
    color: #333 !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    max-width: 500px;
    border: 1px solid #333 !important;
    background: none !important;
}
.modEmploDetails .activeLunchScan{
    background: #66CDAA !important;
    border: 1px solid #66CDAA !important;
    color: #fff !important;
}
.modEmploDetails .activeDinnerScan{
    background: #3E3768 !important;
    border: 1px solid #3E3768 !important;
    color: #fff !important;
}
.emploQrBt{
    min-width: auto !important;
}
.emploQrBt svg{
    color: #c82d33;
}
.genrtQrMain{
    width: 100%;
    float: left;
    text-align: center;
}
.genrtQrMain img{

}
.genrtQrCon{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.genrtQrCon p{
    font-size: 25px;
    font-weight: 500;
    color: #c82d33;
}
.genrtQrCon span{
    font-size: 15px;
    font-weight: 400;
    color: #878787;
}
.genrtQrCon b{
    font-size: 22px;
    font-weight: 600;
    color: #333;
}
.printQrC{
    position: absolute;
    right: -44px;
    top: -30px;
    cursor: pointer;
    transition: .3s;
}
.printQrC:hover{
    transform: scale(1.5);
}
.ordEmploScanMain .prodSearchInpt{
    max-width: 100%;
}
.ordEmploScanMain .scanEmplSearch{
    align-items: center;
}
.fromDToDMain{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    gap: 20px;
}
.fromDToDMain .MuiInputBase-inputAdornedEnd{
    padding: 10px 14px;
}
.fromDToDMain .MuiStack-root{
    padding-top: 0;
}
.fromDToDMain b{
    font-size: 14px;
    font-weight: 700;
    color: #c30f42;
}
.ordEmploScanMain .mainListSubTx{
    max-width: initial !important;
}
.modEmploDetails .activeBreakScan{
    background: #D9B700 !important;
    border: 1px solid #D9B700 !important;
    color: #fff !important;
}
.breakFtColr .mainListDiv{
    border-left: 8px solid #D9B700;
}
.lunchFtColr .mainListDiv{
    border-left: 8px solid #66CDAA;
}
.dinnerFtColr .mainListDiv{
    border-left: 8px solid #3E3768;
}


/* addec by vaishali to refund quantity  */

.QtyRefundTopContent{
    width: 100%;
    float: left;
    text-align: center;
}
.QtyRefundTopContent h1{
    font-size: 20px;
    font-weight: 500;
    color: #333333;
}
.QtyRefundTopContent p{
    font-size: 15px;
    color: #868686;
    font-style: italic;
}

.forModalSpace .outerIncdec {
  text-align: center;
}
 .forModalSpace .IncDrcBtn {
    border: 1px solid !important;
    border-radius: 100% !important;
    background: none !important;
    color: #878787 !important;
    height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;
    margin: 20px 10px ;
}

.forModalSpace .IncDrcBtn svg {
    font-size: 24px;
    color: #333333;
}
.forModalSpace .countValue {
    font-size: 22px;
    font-weight: 600;
    padding: 5px;
    margin: 0;
}
.forModalSpace .qtyModalClose svg{
    top: 3px !important;
}
.refundedTx{
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}
.refundedTx h1{
    font-size: 11px;
    font-weight: 600;
    color: #0db70d;
}

.reportPageTblScroll{
    overflow-y: auto;
    max-height: 400px;
}
.reportPageTblScroll thead{
    position: sticky;
    top: 0;
}
.reportPageTblScroll::-webkit-scrollbar {
    width: 5px !important;
    background:#dddddd !important;
    opacity: 1 !important;
    visibility: visible;
}

.reportPageTblScroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #e8e8e8 !important; 
    border-radius: 15px !important;
    opacity: 1 !important;
    visibility: visible;
}

.reportPageTblScroll::-webkit-scrollbar-thumb {
    border-radius: 15px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5) !important; 
    background:#333;
    opacity: 1 !important;
    visibility: visible;
}
 .recharts-wrapper{
    width: 100%;
 }
 .recharts-legend-wrapper{
    width: 100%; 
 }
.commeMainTbl{
    font-weight: 600 !important;
}
.commeMainTbl details{
    color: #888;
    font-size: 12px;
    font-weight: 400 !important;
}
.commeMainTbl summary{
    cursor: pointer;
}

@media print {
    body {
      margin: 0;
      padding: 0;
      width: 80mm; /* Match the thermal printer's paper width */
    }
  
    .printable-content {
      width: 80mm; /* Adjust to match thermal paper width */
      margin: 0 auto; /* Center content */
    }
  
    /* Hide non-essential elements */
    .no-print {
      display: none !important;
    }
  
    /* Prevent blank space issues */
    .page-break {
      page-break-after: always;
    }
    .qrOter{
        display: none !important;
    }
    .smallPrintMain .orderItemDetails{
        padding: 10px 0;
        margin-top: 10px;
    }
  }
















/* Responsive */
@media only screen and (max-width: 1024px) {
    .sticyTab .MuiTabs-flexContainer {
        padding: 0;
    }
    .leftNavMain{
        max-width: 60px !important;
    }
    .topMain{
        padding-left: 60px;
    }
    .servicesTabInnerMain{
        padding-left: 80px;
    }
    .leftNavMain .MuiListItemText-root{
        display: none;
        opacity: 0;
    }
    .positionHeading{
        left: 0;
        padding-left: 80px;
    }
    .rightContentMain{
        padding-left: 80px;
    }

    .smoothSlide .leftNavMain{
        max-width: 250px !important;
    }
    .smoothSlide .leftNavMain .MuiListItemText-root{
        display: contents;
        opacity: 1;
    }
    .smoothSlide .leftTopSec b{
        opacity: 1;
    }
    .smoothSlide .topMain{
        padding-left: 250px;
    }
    .smoothSlide .positionHeading{
        padding-left: 270px;
    }
    .smoothSlide .servicesTabInnerMain{
        padding: 125px 20px 20px 270px;
    }
    .smoothSlide .rightContentMain{
        padding-left: 270px;
    }
    .scrolled .servicesTabInnerMain {
        padding: 126px 20px 20px 80px;
    }
    .smoothSlide .scrolled .servicesTabInnerMain {
        padding: 126px 20px 20px 270px;
    }
    .mainListDiv{
        /* flex-direction: column;
        align-items: flex-start; */
        overflow-x: auto;
    }
    .mainListInrCont{
        margin: 5px 0;
    }
    /* .actionBtnOut {
        position: absolute;
        top: 8px;
        right: 3px;
        background: #fff;
    } */
    .mainListCont{
        margin-bottom: 5px;
    }
    .detailsTableFilterSecActionInr{
        display: none !important;
    }
    .responsiveActionBtn{
        display: block !important;
    }
    .loginLeft{
        width: 100% !important;
        height: auto !important;
    }
    .loginRight{
        display: none !important;
    }
    .logoHeading{
        font-size: 30px !important;
    }
    .loginHeadiCont h4{
        font-size: 20px !important;
    }
    .loginMainBody{
        padding: 0 15px;
    }
    .orderTop h1 {
        font-size: 13px;
    }
    .orderDetailsSection .printIco{
        width: 30px;
        height: 30px;
    }
    .orderDetailsSection .printIco svg{
        font-size: 18px;
    }
    .nameOfCust {
        font-size: 13px;
        margin-top: 3px;
    }
    .orderSumry label {
        font-size: 10px;
        font-weight: 500;
    }
    .pickpSlot {
        font-size: 10px !important;
    }
    .pickpSlotTime {
        font-size: 14px !important;
    }
    .readyForPicBt {
        height: 45px;
        font-size: 16px !important;
        border-radius: 6px !important;
        max-width: 200px;
    }
    .qrDiv {
        max-width: 150px;
        height: 150px;
    }
    .qrDiv img {
        height: 150px;
    }
    .orderItemDetails h1 {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    .orderItemDiv span {
        margin-bottom: 4px;
    }
    .orderItemDiv span p {
        font-size: 11px;
        font-weight: 500;
    }
    .orderItemDiv span b {
        font-size: 12px;
        font-weight: 700;
    }
    .totalAmt {
        font-size: 15px !important;
    }
    .productDetails table tr th {
        font-size: 11px;
        font-weight: 600;
    }
    .productDetails table tr td {
        font-size: 10px;
        font-weight: 600;
    }
    .starSpen {
        font-size: 10px !important;
    }
    .tabImg .tabImgInr {
        width: 60px;
        height: 60px;
    }
    .orderFlterBt{
        border-radius: 6px !important;
        font-size: 10px !important;
        font-weight: 600 !important;
        height: 43px;
    }
    .newOrdCout {
        width: 25px;
        height: 25px;
        font-size: 13px;
        font-weight: 700;
        margin-top: -20px;
        margin-bottom: 1px;
    }
    .orderDivOuter h1 {
        font-size: 11px;
        font-weight: 600;
        margin-bottom: 1px;
    }
    .orderDivOuter h2 {
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 1px;
    }
    .orderDivOuter p {
        font-size: 10px;
        font-weight: 500;
    }
    .startCount {
        font-size: 12px;
    }
    .startCount svg {
        font-size: 17px;
    }
    .serviceistOuter .mainListDiv {
        gap: 40px;
    }
    .flagSt {
        top: 0px;
        left: 15px;
        padding: 0px 12px;
        border-radius: 0 0 3px 3px;
    }
}

 /* warning comment layout start  */
/* warning details comments */


.warnDetailCard{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.warnDetail h4{
    font-size: 12px;
    color: #c82d33;
    font-weight: 400;
}

.bottomTextAreaOut{
    width: 100%;
    float: left;
    position: relative;
}
.bottomTextAreaOut svg{
    position: absolute !important;
    right: 10px !important;
    top: 15px !important;
}
.newWarnComntMain {
    width: 100%;
    float: left;
    margin: 20px 0;
    margin-bottom: 0;
}

 .newWarnSubjectOuter{
    display: flex;
    width: 100%;
    /* max-width: 40%; */
    float: left;
    flex-direction: row;
    align-items: center;
}
.newWarnComntMain .ticketDetailsMain{
    width: 100%;
    float: left;
}
.newWarnComntMain .ticketDatNtime{
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    top: 10px;
    right: 17px;
    color: #c1c1c1;
}
.newWarnComntMain .tickekDeailsTop{
    width: 100%;
    float: left;
    border-radius: 10px 10px 0 0;
    background: #c82d33;
}
.newWarnComntMain .tickekDeailsTop .suportTicList{
    margin-bottom: 0;
    border: none;
}
.newWarnComntMain .tickekDeailsTop .proNameNMob h2{
    color: #fff;
}
.newWarnComntMain .tickekDeailsTop .proNameNMob p{
    color: #fff;
}
.newWarnComntMain .tickekDeailsTop .suportListBotLft h2{
    color: #ffd7d7;
}
.newWarnComntMain .tickekDeailsTop .suportListBotLft p{
    color: #ffc3c3;
}
.newWarnComntMain .tickekDeailsTop .ticketDatNtime{
    color: #fff;
}
.newWarnComntMain .tickekDeailsTop .tickLabl{
    background: #e63940;
    top: -4px;
    border-radius: 2px 2px 9px 9px;
}

.newWarnComntMain .ticketDetailsMain .disctionBottomContMain {
    width: 100%;
    float: left;
    padding: 0 !important;
    border: none !important;
    border-radius: 0px !important;
    background: #fff;
}

.newWarnComntMain .ticketDetailsMain .disctionComntScrol {
    max-height: 540px;
    width: 100%;
    float: left;
    overflow: auto;
    padding-bottom: 12px;
    padding-right: 20px;
}
.newWarnComntMain .ticketDetailsMain .ticketChatMain {
    padding-right: 0;
}
.newWarnComntMain .ticketDetailsMain .ticketChatLeft {
    width: 75%;
    /* max-width: 460px; */
    float: left;
    margin-left: 44px;
    position: relative;
    margin-bottom: 10px !important;
}
.newWarnComntMain .ticketDetailsMain .parkSocityInner {
    width: 100%;
    float: left;
    padding: 13px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0px 19px 6px -17px rgba(0, 0, 0, 0.09);
}
.newWarnComntMain .ticketDetailsMain .parkSocityInnerTicket {
    cursor: pointer;
}
.newWarnComntMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket {
    padding: 11px 13px;
    margin-bottom: 5px !important;
    background: #f5f5f5 !important;
    border: 0px none;
    box-shadow: 0px 0px 0px -17px rgba(0, 0, 0, 0);
    border-radius: 0px 15px 15px 15px;
}
.newWarnComntMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 13px solid transparent;
    border-right: 11px solid #f5f5f5;
    left: -8px;
    top: 0;
}
.newWarnComntMain .ticketDetailsMain .parkSocityTop {
    width: 100%;
    float: left;
    padding-left: 46px;
}
.newWarnComntMain .ticketDetailsMain .ticketChatMain .parkSocityTop {
    padding-left: 0;
}
.newWarnComntMain .ticketDetailsMain .parkSocityTopPro {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    left: 13px;
    background: #f7f7f7;
    /* background-image: url(https://cdn1.vectorstock.com/i/1000x1000/23/70/man-avatar-icon-flat-vector-19152370.jpg); */
    background-size: 100%;
    background-repeat: no-repeat;
    border: 1px solid #c2c2c2;
}
.newWarnComntMain .ticketDetailsMain .parkSocityInnerTicket .parkSocityTopPro {
    left: 19px;
}
.newWarnComntMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parkSocityTopPro {
    left: -43px;
    right: auto;
    top: 2px;
}
.newWarnComntMain .ticketDetailsMain .parkSocityTopPro img {
    width: 100%;
    height: 36px;
    object-fit: cover;
    background: #fff;
}
.newWarnComntMain .ticketDetailsMain .parContTx {
    width: 100%;
    float: left;
    font-size: 12px;
    color: #565656;
    margin: 2px 0 0;
}
.newWarnComntMain .ticketDetailsMain .parkSocityInnerTicket .parContTx {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 20px;
}
.newWarnComntMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parContTx {
    margin-top: 0px;
    color: #878787 !important;
    font-weight: 400 !important;
    white-space: normal;
}
.newWarnComntMain .ticketDetailsMain .ticketChatRight {
    width: 75%;
    /* max-width: 460px; */
    float: right;
    margin-right: 44px;
    position: relative;
    margin-bottom: 5px !important;
}
.newWarnComntMain .ticketDetailsMain .parContTxDate {
    width: 100%;
    margin-top: 0px !important;
    text-align: left;
    font-size: 11px !important;
    color: #7aaed3;
    letter-spacing: 1px;
}
.newWarnComntMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parContTxDate {
    margin-top: 5px;
    color: #676767;
    font-weight: 500;
    text-align: left;
    font-style: normal !important;
    font-weight: 500;
    letter-spacing: 0.6px !important;
}
.newWarnComntMain .ticketDetailsMain .ticketChatRight {
    clear: both;
}
.newWarnComntMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket {
    text-align: right;
    padding: 11px 13px;
    background: #FFECED !important;
    border: 0px none;
    box-shadow: 0px 0px 0px -17px rgba(0, 0, 0, 0);
    position: relative;
    border-radius: 15px 0px 15px 15px;
    margin-bottom: 5px !important;
}
.newWarnComntMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 13px solid transparent;
    border-left: 11px solid #FFECED !important;
    right: -8px;
    top: 0;
}
.newWarnComntMain .ticketDetailsMain .ticketChatMain .parkSocityTop {
    padding-left: 0;
}
.newWarnComntMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parkSocityTopPro {
    right: -43px;
    left: auto;
    top: 2px;
}
.newWarnComntMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parContTx {
    margin-top: 5px;
    color: #878787;
    font-weight: 500;
}
.newWarnComntMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parContTxDate {
    margin-top: 5px;
    color: #0a7400;
    text-align: right;
    font-style: normal !important;
    font-weight: 500;
    letter-spacing: 0.6px !important;
}
.newWarnComntMain .ticketDetailsMain .typeComentOut {
    width: 100%;
    float: left;
    padding: 12px 0px 0px;
    border-top: 1px dashed #ccc;
    position: relative;
}
.newWarnComntMain .ticketDetailsMain .typeComentOutIco {
    width: 100%;
    float: left;
    position: relative;
    padding-right: 50px;
}
.newWarnComntMain .ticketDetailsMain .typeComentOut svg {
    position: absolute;
    right: 11px;
    top: 11px;
    color: #08AE36;
    cursor: pointer;
}
.newWarnComntMain .ticketDetailsMain .typeComentOut svg {
    right: 0px;
    top: 11px !important;
    font-size: 28px;
}
.newWarnComntMain .ticketDetailsMain .textAriaChat {
    width: 100%;
    border: 1px solid #ccc;
    padding: 9px 15px;
    outline: none;
    border-radius: 5px;
    font-size: 12px;
}
.newWarnComntMain .ticketDetailsMain .parkSocityTop h4 {
    font-weight: 500;
    font-size: 13px;
    color: #121210 !important;
    margin-bottom: 2px;
}
.newWarnComntMain .timeAlignRight{
    text-align: right !important;
}

/* warning comment layout end  */



/* Sagar web */

.boxActions {
    float: left;
    width: 100%;
    margin-top: 11px;
    margin-bottom: -5px;
}
.boxActions span{ 
    width: auto !important;
    padding-right: 15px;
    margin-bottom: 0px !important;
    float: none !important;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.boxActions span em{
    font-style:normal ;
    font-size: 13px;
    margin-left: 4px;
}
.boxActions span svg{ 
    font-size: 18px;
}

.expandfilesDiv{
    width: 100%;
    float: left;
    border-top: 1px dashed #ccc;
    margin-top: 15px;
    padding-top: 4px;
    display: none;
    transition: .5s;
}
.expandfilesDiv .doqumentDiv1{
    width: auto;
}
.expandfilesDiv .doquView{
    margin: 0;
    width: 28px;
    height: 30px;
    margin-right: 15px;
}
.expandfilesDiv .doquView img{
    width: 100%;
    height: 30px;
    object-fit: fill;
}


/* added by vaishali for reload refund stars popup  */

.newReloadRefundTab{
    float: left;
    width: 100%;
}
.newReloadRefundTab .MuiBox-root{
    border: none !important;
}
.newReloadRefundTab .MuiTabs-flexContainer{
    display: flex;
    justify-content: center;
}
.refundIC{
    width: 50px;
    cursor: pointer;
}
.EmployeeOrderScanForTab{

}
.EmployeeOrderScanForTab .MuiTab-textColorPrimary{
    background: #e9e9e9;
    color: #000;
}
.EmployeeOrderScanForTab .MuiTabs-flexContainer .Mui-selected:first-child{
    background: #c82d33;
    color: #fff !important;
}
.EmployeeOrderScanForTab .MuiTabs-flexContainer .Mui-selected:nth-child(2){
    background: #66cdaa;
    color: #fff !important;
}
.EmployeeOrderScanForTab .MuiTabs-flexContainer .Mui-selected:nth-child(3){
    background: #3e3768;
    color: #fff !important;
}
.EmployeeOrderScanForTab .MuiTabs-flexContainer .Mui-selected:nth-child(4){
    background: #d9b700;
    color: #fff !important;
}

.EmployeeOrderScanForTab .MuiTabs-indicator {
    background: #e9e9e9;
}
.EmployeeOrderScanForTab .breakFtColr .mainListDiv {
    /* border: 1px solid #d9b700; */
    border-left: 8px solid #D9B700;
    /* background: #ffef9c; */
}
.EmployeeOrderScanForTab .dinnerFtColr .mainListDiv {
    /* border: 1px solid #3e3768; */
    border-left: 8px solid #3E3768;
    /* background: #ded8ff; */
}
.EmployeeOrderScanForTab .lunchFtColr .mainListDiv {
    /* border: 1px solid #66cdaa; */
    border-left: 8px solid #66CDAA;
    /* background: #defff4; */
}
.EmployeeOrderScanForTab .mainListInrCont label {
    font-size: 12px;
    color: #424242;
}
.refuundedTx{
    position: absolute;
    top: 10px;
    right: 15px;
    background: #beffb6;
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid #80f080;
}

/* for finance by vaishali  */
.innerTabsMainFinance{
   width: 100%;
}

.innerTabsMainFinance  .MuiTabs-flexContainer{
    background: #f9f9f9 !important;
    padding: 17px 20px !important;
}

.innerTabsMainFinance .financeDrowpDown{
    color: #333333;
    font-size: 16px;
    /* margin-top: 20px; */
    background: #ffffff;
    padding: 5px 20px;
}

.financeDrowpDownSelect select{
float: left;
/* margin-top: 20px; */
font-size: 15px;
color: #000000;
font-weight: 400;
/* margin-bottom: 14px; */
border: none;
outline: none;
background: url(http://localhost:3000/static/media/selectIcon.eb3ce11….svg) no-repeat !important;
background-position: calc(100% - 0.75rem) center !important;
background: #f9f9f9 !important;
    border: none !important;
    outline: none !important;
    /* width: 100%; */
    padding: 5px 10px !important;
    margin-right: 20px;

}

.financeDrowpDownInput input{
    float: left;
    font-size: 13px;
    color: #001F3F;
    font-weight: 500;
    border: none;
    border: 1px solid #ccc;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    
}

.financeDrowpDownInput b{
    float: left;
    margin: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
}

















@media only screen and (max-width: 991px) {
    .bigModal .MuiDialog-paperScrollBody{
        max-width: fit-content;
        max-width: calc(100% - 64px);
    }
    .downloadedViewDiv{
        flex-direction: column;
        align-items: flex-start;
    }
    .donloadLeft{
        margin-bottom: 20px;
    }
    .sticyTab{
        position: static;
    }
    .orderListing{
        padding-bottom: 0px;
    }
    .orderFlterBt{
        flex-direction: row;
    }
    .newOrdCout{
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 10px;
    }
    .orderListing{
        height: auto;
    }
}


@media only screen and (max-width: 767px) {
    .smoothSlide .servicesTabInnerMain {
        padding: 125px 20px 20px 80px;
    }
    .smoothSlide .rightContentMain {
        padding-left: 80px;
    }
    .servicesTabMain .servicesTabInnerMain .tabImg .tabImgInr{
        min-height: auto;
    }
    .servicesTabMain .servicesTabInnerMain .tabImg .tabImgInr img{
        height: 70px;
    }
    .scrolled .servicesTabInnerMain .tabImg .tabImgInr{
        min-height: 40px;
    }
    .scrolled .servicesTabInnerMain .tabImg .tabImgInr img{
        height: 50px;
    }
    .scrolled .servicesTabInnerMain .MuiTabs-flexContainer{
        max-width: none;
    }
    .servicesTabMain .servicesTabInnerMain .tabImg p {
        font-size: 10px;
    }
    .servicesTabMain .tabContDiv {
        margin-top: 160px;
    }
    .pageTopMainBt{
        min-width: auto !important;
        font-size: 12px !important;
        height: 40px;
    }
    .pageMainHeading h1 {
        font-size: 13px;
    }
    .pageLabel {
        font-size: 11px;
    }
    .prodSearchInpt {
        height: 38px;
        border-radius: 3px;
        font-size: 12px;
    }
    .warningMain h1 {
        font-size: 13px;
        margin-bottom: 6px;
    }
    .warningMain label{
        font-size: 10px;
    }
    .warningMain p {
        font-size: 11px;
        padding-right: 55px;
    }
    .warningMain p b {
        font-size: 11px;
    }
    .profileTabMain .MuiTab-textColorPrimary {
        font-size: 12px;
    }
    .starActivityHeading {
        font-size: 15px;
    }
    .datePicOut select{
        height: 29px;
        
    }
}


@media only screen and (max-width: 600px) {
    .topTimeLine{
        display: none;
    }
    .mainListCont h1 {
        font-size: 14px;
        margin-bottom: 2px;
    }
    .mainListCont p {
        font-size: 12px;
        margin-bottom: 2px;
    }
    .starActiInnerDiv .detailsTableFilterSec{
        flex-direction: column;
    }
}