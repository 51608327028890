

.topheaderMain{
    background-color: #121210;
    width: 100%;
    max-height: 136px;
    text-align: center;
    padding: 20px 0;
    border-radius: 0 0 50px 50px;
}

.clockContentMain{
    width: 100%;
    float: left;
}

.clockContentMain .clockImg{
    width: 100%;
    float: left;
    margin: 15px 0;
}

.clockContentMain .clockImg img{
    width: 100%;
    height: 166px;
}

.clockInputOuter{
    display: flex;
    justify-content: center;
    text-align: center;
    float: left;
    width: 100%;
}

.empCodeOuter h5{
    font-size: 15px;
    color: #121210;
    font-weight: 600;
    margin-bottom: 20px;
}

.empCodeOuter input{
    width: 57px !important;
    height: 52px !important;
    border-radius: 2px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.profilePicOuter{
    width: 100%;
    float: left;
}

.profileBoxOuter{
    width: 100%;
    float: left;
    background-color: #F9F9F9;
    border-radius: 12px ;
    text-align: center;
    min-height: 221px;
    margin: 20px 0;
    align-content: center;
}

.profileBoxOuter .profImgOuter{
    width: 100%;
    height: 119px;
    border-radius: 80px;
    overflow: hidden;
}

.profileBoxOuter .profImgOuter img{
    width: 119px;
    height: 119px;
}

.clockBtns{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clockBtns Button{
    width: 162px !important;
    height: auto;
    border-radius: 8px !important;
    padding: 15px 0;
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    display: flex;
    flex-direction: column-reverse;
}
.clockBtns span{
    margin: 0 !important;
    padding: 0 !important;
}
.clockBtns img{
    margin-bottom: 10px;
}
.clockBtns Button{
    background-color: #c82d33 !important;
}

.clockBtns Button~Button{
    background-color: #121210 !important;
}

.thankOuter{
    width: 100%;
    float: left;
    text-align: center;
}

.thankOuter .clockImg{
    width: 100%;
    float: left;
    margin: 40px 0;
}
.thankOuter .clockImg img{
    width: 203px;
    height: 203px;
}
.thankOuter h6{
    font-size: 16px;
    font-weight: 600;
    color: #121210;
    padding-bottom: 5px;
}
.thankOuter h2{
    font-size: 26px;
    color: #c82d33;
    font-weight: 600;
    padding-bottom: 5px;
}
.thankOuter p{
    font-size: 16px;
    color: #878787;
    font-weight: 400;
    padding-bottom: 5px;
}
.thankOuter h3{
    font-size: 19px;
    color: #121210;
    font-size: 600;
    padding-bottom: 36px;
}

.thankOuter h4{
    font-weight: 16px;
    color: #4C8F2A;
    font-size: 16px;
}

.reasonsOuter{
    float: left;
    width: 100%;
    margin-top: 30px;
}

.reasonsOuter .reasonSelected{
    background-color: #4C8F2A !important;
    color: #ffffff !important;
}

.reasonsOuter .reasonSelected b{
    color: #ffffff !important;
}

.reasonsOuter h1{
    font-size: 32px;
    font-weight: 600;
    color: #121210;
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.reasonsOuter .reasonInput{
    margin: 30px 0;
    width: 100%;
    float: left;
}

.reasonsOuter .reasonInput input{
    width: 100%;
    height: 84px;
    border-radius: 8px !important;
    border: 1px solid #BDC3C7 !important;
    padding: 10px;
}