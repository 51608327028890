@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap');


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    letter-spacing: 1px;
}

.mobileCssMain h1,
h2,
h3,
h4,
h5,
h6 {}

/* body{

} */
.mobileCssMain .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.mobileCssMain .wrapper {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
}

.mobileCssMain .splashMain {
    width: 100%;
    float: left;
    padding: 20px 40px;
    height: 100vh;
    background: #121210;
    /* background: linear-gradient(4deg, rgba(0,31,63,1) 9%, rgba(24,90,159,1) 42%, rgba(30,66,103,1) 74%, rgba(0,31,63,1) 98%); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mobileCssMain .splashLogoOter {
    margin-bottom: 50px;
    text-align: center;
    /* background: #fff; */
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
}

.mobileCssMain .splashLogoOterNew {
    display: flex;
    flex-direction: column;
}

.mobileCssMain .splashLogoOterNew h1 {
    font-size: 16px !important;
    font-weight: 600;
}

.mobileCssMain .splashLogoOter img {
    width: 165px;
}

.mobileCssMain .splashLogoOter img~img {
    width: 185px;
}

.mobileCssMain .splashMain h1 {
    width: 100%;
    float: left;
    font-size: 23px;
    font-weight: 500;
    color: #FFFFFF;
}

.mobileCssMain .splashData {
    text-align: center;
}

.mobileCssMain .splashMain p {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    margin: 11px 0 40px;
}

.mobileCssMain .splashMain button {
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    max-width: 230px;
    background: #c82d33;
    height: 50px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    display: inline-flex;
}

.mobileCssMain .loginMain {
    width: 100%;
    float: left;
    height: 100vh;
    display: flex;
    align-items: center;
}

.mobileCssMain .loginMain h1 {
    font-weight: 600;
    font-size: 32px;
    color: #001F3F;
}

.mobileCssMain .loginMain p {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 400;
    color: #878787;
}

.mobileCssMain .loginCont {
    width: 100%;
    float: left;
}

.mobileCssMain .loginLable {
    width: 100%;
    float: left;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
}

.mobileCssMain .loginCont .MuiOutlinedInput-root {
    width: 100%;
    height: 52px;
    border-radius: 5px;
    padding: 0 15px;
    outline: none;
}

.mobileCssMain .loginCont .MuiTextField-root {
    width: 100%;
}

.mobileCssMain .loginCont .MuiOutlinedInput-input {
    padding: 0;
    font-size: 13px;
}

.mobileCssMain .forgotTex {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    width: 100%;
    float: left;
    text-align: right;
    letter-spacing: 1px;
}

.mobileCssMain .loginBt {
    width: 100%;
    float: left;
    background: #c82d33 !important;
    color: #fff !important;
    height: 54px !important;
    border-radius: 10px !important;
    text-transform: capitalize !important;
    margin: 15px 0 !important;
    font-weight: 600 !important;
    letter-spacing: 1px;
    border: 4px solid #fff;
}

.mobileCssMain .forgotOter {
    width: 100%;
    padding: 20px;
}

.mobileCssMain .forgotOter h1 {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
}

.mobileCssMain .forgotOter p {
    font-size: 15px;
    font-weight: 400;
    color: #878787;
}


.mobileCssMain .forotInput {
    width: 100%;
    float: left;
    position: relative;
    border: 1px solid #c82d33;
    height: 75px;
    border-radius: 15px;
    padding: 10px;
    padding-left: 75px;
}

.mobileCssMain .forGmail {
    background: #F0F5ED;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 15px;
    top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileCssMain .forotInput label {
    font-size: 13px;
    font-weight: 400;
    color: #878787;
    letter-spacing: 1px;
}

.mobileCssMain .forotInput input {
    border-radius: 7px;
    margin: 2px 0;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 600;
    font-size: 16px;
}

.mobileCssMain .logError {
    color: red;
    font-weight: 500;
    font-size: 16px;
    background: #ffc5c5;
    border-radius: 6px;
    position: relative;
    top: 10px;
    left: 20px;
}

.mobileCssMain .dashMain {
    width: 100%;
    float: left;
}

.mobileCssMain .dashTop {
    width: 100%;
    float: left;
    /* background: #121210; */
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notificationInner .notiIco {
    font-size: 32px;
    color: #ffffff;
    /* margin-right: 12px; */
}

.notificationOuter {
    background: #121210;
    width: 100%;
    float: left;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 9;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}

.notificationOuter .dashLogo {
    display: flex;
    align-items: center;
    gap: 5px;
}

.notificationOuter .dashLogo span {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.notiFixedPatch {
    width: 100%;
    float: left;
    height: 50px;
}

.notificationInner {
    display: flex;
    /* width: 50px; */
    height: 50px;
    position: relative;
    align-items: center;
    justify-content: center;
}

.notificationOuter .notificationInner p {
    background: #c82d33;
    border-radius: 100% !important;
    padding: 1px 7px !important;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    right: -5px;
    top: 5px;
    color: #ffffff !important;
}

.mobileCssMain .notificationOuter {
    background: #121210;
    width: 100%;
    float: left;
    display: flex;
    padding-right: 5px;
    align-items: center;
    justify-content: flex-end;
}

.mobileCssMain .notificationInner {
    display: flex;
    /* width: 50px; */
    height: 50px;
    position: relative;
    align-items: center;
    justify-content: center;
}

.mobileCssMain .notificationOuter .notificationInner p {
    background: #c82d33;
    border-radius: 100% !important;
    padding: 1px 7px !important;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    right: -5px;
    top: 5px;
    color: #ffffff !important;
}



.notiP {
    background: #c82d33;
    border-radius: 100% !important;
    padding: 2px 7px !important;
    position: absolute;
    right: 10px;
    top: 0;
}


.mobileCssMain .notiIcoPadd {
    padding: 0 12px;
}

.mobileCssMain .notiIco svg {
    font-size: 28px;
    cursor: pointer;
}

.mobileCssMain .dashLogo {
    display: flex;
    align-items: center;
}

.mobileCssMain .dashLogo span {
    color: #fff;
    margin-left: 8px;
    font-size: 15px;
    font-weight: 600;
}

.mobileCssMain .dashLogoOut {
    width: 40px;
    height: 40px;
    /* background: #001F3F; */
    border-radius: 100%;
    overflow: hidden;
    /* border: 1px solid #c82d33; */
}

.mobileCssMain .dashLogoOut img {
    width: 100%;
}

.mobileCssMain .availStarMain {
    width: 100%;
    float: left;
    background: #121210;
    margin-top: -0.1px;
}

.mobileCssMain .availStar {
    width: 100%;
    float: left;
    background: #c82d33;
    border-radius: 10px;
    min-height: 50px;
    padding: 5px;
    margin-bottom: -26px;
    position: relative;
    color: #fff;
    text-align: center;
}

.mobileCssMain .availStar h4 {
    font-size: 14px;
    font-weight: 500;
    color: #ffe600;
    margin-bottom: 3px;
    letter-spacing: 1px;
    font-style: italic;
}

.mobileCssMain .availStar h1 {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
}

.mobileCssMain .availStar h1 b {
    font-size: 14px;
    font-weight: bold;
    color: #FFE600;
    display: flex;
    align-items: center;
    margin-left: 7px;
    gap: 6px;
}

.mobileCssMain .dedctedWraper {
    width: 100%;
    float: left;
    padding: 0 8px;
}

.mobileCssMain .dedctedMain {
    width: 100%;
    float: left;
    border: 1px solid #c82d33;
    background: #FFF0F1;
    padding: 30px 15px 3px;
    border-radius: 0 0 15px 15px;
    border-top: none;
    text-align: center;
}

.mobileCssMain .dedctedMain h3 {
    font-size: 13px;
    font-weight: 600;
    color: #c82d33;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .4px;
}

.mobileCssMain .dedctedMain b {
    display: flex;
    align-items: center;
    color: #121210;
    font-size: 15px;
    font-weight: bold;
    margin-right: 8px;
    gap: 3px;
}

.mobileCssMain .dedctedMain b svg {
    color: #FFE600;
    margin-right: 3px;
    font-size: 20px;
}

.mobileCssMain .orderStatusDiv {
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #eee;
    position: relative;
    padding: 10px 120px 10px 67px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-top: 38px;
    margin-bottom: 15px;
}

.mobileCssMain .orderStatusDiv label {
    font-size: 14px;
    font-weight: 400;
    color: #878787;
    margin-bottom: 3px;
    float: left;
    width: 100%;
}

.mobileCssMain .orderStatusDiv h2 {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 3px;
    float: left;
    width: 100%;
}

.mobileCssMain .orderStatusDiv h3 {
    font-size: 14px;
    font-weight: 600;
    color: #c82d33;
}

.mobileCssMain .ordIcoDiv {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    background: #e9e9e9;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileCssMain .deliveryBoyImg {
    position: absolute;
    right: 15px;
    top: -28px;
    width: 80px;
}

.mobileCssMain .notifiDiv {
    width: 100%;
    float: left;
    padding: 15px 15px;
    background: #e7e7e7;
}

.mobileCssMain .mainHEadingOrder {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.mobileCssMain .mainHEadingOrder b {
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    float: left;
}

.mobileCssMain .mainHEadingOrder span {
    background: #c82d33;
    color: #fff;
    border-radius: 50px;
    font-size: 11px;
    font-weight: 500;
    float: left;
    padding: 0px 10px;
    margin-left: 10px;
    height: 22px;
    line-height: 22px;
}

.mobileCssMain .orderNotiDiv {
    width: 100%;
    float: left;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 60px;
    margin-bottom: 13px;
    border: 0.2px solid #BDC3C7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.complainBox {
    width: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    border: 0.2px solid #BDC3C7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.mobileCssMain .orderNotiDiv h1 {
    font-size: 13px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 3px;
    float: left;
    width: 100%;
}

.mobileCssMain .orderNotiDiv h2 {
    font-size: 16px;
    font-weight: 400;
    color: #535353;
    margin-bottom: 3px;
    float: left;
    width: 100%;
    letter-spacing: 1px;
    padding-right: 5px !important;
}

.mobileCssMain .orderNotiDivBg {
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.mobileCssMain .todayMenuMain {
    width: 100%;
    float: left;
}

.mobileCssMain .menuHeading {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 400;
    color: #121210;
    margin-bottom: 15px;
}

.mobileCssMain .orderNotiDivBg .orderNotiDiv {
    border: 1px solid #eee;
}

.mobileCssMain .orderNotiDivBg .ordIcoDiv {
    overflow: initial;
    background: transparent;
}

.mobileCssMain .lunchDenerMain {
    width: 100%;
    float: left;
}

.mobileCssMain .lunchDener {
    /* width: 50%; */
    float: left;
    display: flex;
    align-items: center;
}

.mobileCssMain .lunchDener b {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-left: 10px;
}

.mobileCssMain .menImDiv {
    float: left;
}

.mobileCssMain .menImDiv img {
    float: left;
}

.mobileCssMain .lunchDener:last-child {
    border-left: 1px solid #000;
    padding-left: 15px;
}

.mobileCssMain .storesMain {
    width: 100%;
    float: left;
    padding: 10px 0;
}

.mobileCssMain .storesHeading {
    color: #333333;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    float: left;
}

.mobileCssMain .storWarn {
    min-height: 80px !important;
}

.mobileCssMain .storInner {
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.mobileCssMain .storInner img {
    float: left;
}

.mobileCssMain .storInner b {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #001F3F;
}

.mobileCssMain .fixedHeader {
    width: 100%;
    float: left;
    position: fixed;
    top: 0;
    z-index: 1;
}

.mobileCssMain .fixedHeaderPacth {
    height: 97px;
}

.mobileCssMain .ordLine {
    font-size: 14px;
    font-weight: 400;
    color: #878787;
    letter-spacing: 1px;
}

.mobileCssMain .bottomTabMain {
    width: 100%;
    float: left;
    position: fixed;
    bottom: 0;
    z-index: 9;
    background: #fff;
    border-top: 1px solid #f6f6f6;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.mobileCssMain .bottomTabMain .MuiTabs-flexContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.mobileCssMain .bottomTabMain .Mui-selected {
    color: #c82d33 !important;
    font-weight: 600;
}

.mobileCssMain .bottomTabMain .MuiTabs-indicator {
    background-color: #c82d33 !important;
}

.mobileCssMain .bottomTabMain .MuiTab-root {
    text-transform: capitalize !important;
    padding: 0;
    max-width: initial;
    min-width: 50px;
}

.mobileCssMain .forBottomTabOnly {
    padding-bottom: 80px;
    float: left;
    width: 100%;
}

.newSearchOuter {
    width: 100%;
    float: left;
    margin-bottom: 15px;
}

.newSearchOuter input {
    width: 100%;
    float: left;
    height: 48px;
    border: 1px solid #ccc;
    padding: 0 15px;
    border-radius: 6px;
    outline: none;
}



/*  Vaishali */

.mobileCssMain .header h2 {
    font-size: 18px;
    color: red;
}

.mobileCssMain .LunchData {
    float: left;
    width: 100%;
}

.mobileCssMain .LunchData h2 {
    font-size: 24px;
    font-weight: 400;
}

.mobileCssMain .thaliImg {
    align-items: center;
    float: left;
    width: 100%;
    height: 200px;
    text-align: center;
    margin: 20px 0;
}

.mobileCssMain .thaliImg img {
    width: 100%;
    height: 200px;
}

.mobileCssMain .thaliName {
    display: flex;
    float: left;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #cccccc;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
}

.mobileCssMain .thaliData {
    float: left;
    width: 100%;
}

.mobileCssMain .thaliData h1 {
    font-size: 20px;
    color: #333333;
    font-weight: 600;
    /* margin-bottom: 10px; */
}

.mobileCssMain .thaliData h5 {
    font-size: 16px;
    font-weight: 400;
    color: #878787;
}

.mobileCssMain .nonVeg {
    float: left;
    width: 100%;
    background-color: #333333;
    color: #FFF500;
    text-align: center;
    margin-top: 15px;
    padding: 8px;
    border-radius: 6px;
    border: 2px dashed #fffb93
}

.mobileCssMain .nonVeg h3 {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1px;
}

.mobileCssMain .menuDetails {
    float: left;
    width: 100%;
    margin: 30px 0;
    margin-bottom: 10px;
}

.mobileCssMain .menuDetails ul {
    text-decoration: none;
    list-style: none;
}

.mobileCssMain .menuDetails li {
    font-size: 14px;
    color: #878787;
    margin-bottom: 10px;
}

.mobileCssMain .menuDetails .listTitle {
    font-weight: 600;
    font-size: 16px;
    color: #333333;
}

.mobileCssMain .orderBtn {
    min-width: 154px !important;
    margin: 10px 0 !important;
}

.mobileCssMain .headerData {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
}

.mobileCssMain .headerData Button {
    padding: 0;
    margin: 0;
    min-width: 20px !important;
}

.mobileCssMain .headerData Button {
    color: #333333;
}

.mobileCssMain .headerData .logo {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.mobileCssMain .headerData h5 {
    font-weight: 600;
    font-size: 15px;
}

.mobileCssMain .screenTitle {
    display: flex;
    width: 100%;
    float: left;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
}

.mobileCssMain .screenTitle button {
    min-width: auto;
    padding: 0;
    color: #333;
}

.mobileCssMain .screenTitle h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    float: left;
}

.mobileCssMain .breakfastDat {
    float: left;
    width: 100%;
}

.mobileCssMain .titleBelowImg {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobileCssMain .cupIcon {
    position: initial;
    margin: 0;
    margin-right: 10px !important;
}

.mobileCssMain .titleBelowImg h3 {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    letter-spacing: 1px;
}

.mobileCssMain .IncDrcBtn {
    border: none !important;
    background: none !important;
    color: #878787 !important;
}

.mobileCssMain .IncDrcBtn svg {
    font-size: 24px;
    color: #ffffff;
}

.mobileCssMain .breakfastBtn {
    border: 1px solid #BDC3C7;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    background: #eee;
}


.mobileCssMain .outerIncdec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom, #ff454c 0%, #c82d33 47%, #ff454c 100%);
    padding: 3px 5px;
    border-radius: 50px;
}

.mobileCssMain .outerIncdec h2 {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.mobileCssMain .breakfastBtn h5 {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    padding: 0;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow: ellipsis;
    max-width: 125px; */
    float: none;
    display: inline-block;
    margin-top: 23px;
}

.mobileCssMain .counter {
    margin-bottom: 20px;
}

.mobileCssMain .todaysSpecial {
    float: left;
    width: 100%;
    padding: 15px;
    background-color: #f6f6f6;
}

.mobileCssMain .todaysSpecialData {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobileCssMain .todaysSpecialData p {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 14px !important;
}

.mobileCssMain .specialMenu {
    width: 100%;
    float: left;
    padding: 15px;
    /* padding-right: 0; */
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    flex-direction: row;
    margin-bottom: 10px;
}

.mobileCssMain .specialMenuNew {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mobileCssMain .specialMenu h3 {
    font-size: 14px;
    font-weight: 600;
    /* padding-bottom: 4px; */
    color: #333333;
    letter-spacing: 0.5px;
    padding-right: 15px;
    margin-bottom: 5px;
}

.mobileCssMain .specialMenu p {
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 4px;
    color: #333333;
}

.mobileCssMain .starPrice {
    align-items: center;
    display: flex;
    font-weight: 600;
    gap: 3px;
    font-size: 13px;
    line-height: 0;
}

.mobileCssMain .starIcon {
    color: #FFE600;
    /* margin-right: 3px; */
    font-size: 14px;

}

.mobileCssMain .starPrice h5 {
    font-size: 15px;
    font-weight: 700;
    color: #121210;
}

.mobileCssMain .bottomBtn {
    float: left;
    width: 100%;
}

.mobileCssMain .pickUp {
    float: left;
    width: 100%;
}

.mobileCssMain .pickUp h4 {
    font-size: 14px;
    font-weight: 400;
    color: #878787;
    padding-bottom: 5px;
}

.mobileCssMain .pickUp p {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
}

.mobileCssMain .orderPrice {
    margin-bottom: 15px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    justify-content: center;
}

.mobileCssMain .orderPrice h2 {
    font-size: 28px;
    font-weight: 700;
    color: #333333;
}

.mobileCssMain .orderPrice h6 {
    font-size: 14px;
    color: #878787;
    font-weight: 400;
}

.mobileCssMain .ordermenuList {
    float: left;
    width: 100%;
    margin: 20px 0;
}

.mobileCssMain .orderSummary {
    margin-top: 20px;
    float: left;
    width: 100%;
    border: 1px solid #BDC3C7;
    border-radius: 16px;
    padding: 15px;
}

.mobileCssMain .summaryData {
    float: left;
    width: 100%;
}

.mobileCssMain .orderSummary h4 {
    padding-bottom: 10px;
}

.mobileCssMain .productSummary p {
    float: left;
    font-size: 14px;
    font-weight: 400;
    color: #878787;
}

.mobileCssMain .productSummaryTopMinus {
    margin-top: 0 !important;
}

.mobileCssMain .summaryItem h6 {
    float: left;
    font-size: 13px;
    font-weight: 500;
    color: #312e2e;
    letter-spacing: 1px;
}

.mobileCssMain .summaryItem {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobileCssMain .summaryItem h3 {
    float: right;
    font-size: 15px;
    color: #333333;
    font-weight: 700;
}

.mobileCssMain .green {
    color: #c82d33 !important;
}

.mobileCssMain .blue {
    color: #121210 !important;
}

/* Modal Pop up */


.mobileCssMain .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}


.mobileCssMain .modal {
    float: left;
    width: 100%;
    margin: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);  */
}

.mobileCssMain .vegModal {
    padding: 15px !important;
}

.mobileCssMain .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.mobileCssMain .modalInnerData {
    float: left;
    width: 100%;
    margin: 20px 0;
    flex: 1;
    max-height: 435px;
    overflow-y: auto
}

.mobileCssMain .modalCloseIcon {
    width: 100%;
    float: right;
    margin: 10px 0;
    text-align: right;
}

.mobileCssMain .modalFooter {
    float: left;
    width: 100%;
}

.mobileCssMain .orderTiming {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;
}

.mobileCssMain .orderTiming h4 {
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    padding-right: 5px;
}

.mobileCssMain .orderTiming h2 {
    font-size: 15px;
    font-weight: 600;
    color: #121210;
}

.mobileCssMain .summaryContent {
    text-align: center;
    float: left;
    width: 100%;
}

.mobileCssMain .cartImgOuter {
    float: left;
    width: 100%;
    text-align: center;
    margin: 5px 0 7px;
}

.mobileCssMain .cartImgOuter img {
    width: 125px;
    height: auto;
}

.mobileCssMain .summaryContent h1 {
    font-size: 23px;
    font-weight: 700;
    color: #333333;
    float: left;
    width: 100%;
}

.mobileCssMain .summaryContent p {
    text-align: center;
    font-size: 14px;
    color: #878787;
    font-weight: 400;
    line-height: 20px;
    float: left;
    width: 100%;
    margin: 7px 0 15px;
}

.mobileCssMain .spanbold {
    font-weight: 600;
    color: #333333;
    float: left;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}

.mobileCssMain .secondaryBtn {
    width: 100%;
    float: left;
    background: #ffffff !important;
    color: #878787 !important;
    height: 46px !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
    margin: 20px 0 !important;
    border: 1px solid #878787 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-weight: 600 !important;
}

.mobileCssMain .productOuter {
    border-radius: 8px;
    padding: 15px;
    border: none;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05) !important;
    position: relative;
    float: left;
    background: #fbfbfb;
    border: 1px solid #efefef;
    width: 100%;
}

.mobileCssMain .productImg {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    height: 95px;
    background: #fff;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #eee;
}

.mobileCssMain .productImg img {
    width: 100%;
    height: 76px;
    object-fit: contain;
}

.mobileCssMain .productOuter h3 {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 4px;
    color: #333333;
    white-space: nowrap;
    float: left;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px;
}

.mobileCssMain .productOuter p {
    font-size: 11px !important;
    font-weight: 500 !important;
    padding-bottom: 4px !important;
    color: #6c6c6c !important;
    float: left;
    width: 100%;
    letter-spacing: 1px;
}

.mobileCssMain .productOuter p~p {
    color: #333333 !important;
}

.mobileCssMain .modal h4 {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 15px;
}

.mobileCssMain .modal p {
    font-weight: 600;
    font-size: 15px;
    color: #333333;
}

.mobileCssMain .vegProd p {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #333333 !important;
}

.mobileCssMain .successImg {
    width: 100%;
    float: left;
    text-align: center;
}

.mobileCssMain .successImg img {
    width: 100%;
    height: 113px;
    object-fit: contain;
}

.mobileCssMain .contentTopSpacing {
    width: 100%;
    float: left;
    margin-top: 170px;
}

.mobileCssMain .tabMain {
    width: 100%;
    float: left;
}

.mobileCssMain .tabsImgOuter {
    border: 1px solid #878787;
    border-radius: 8px;
    padding: 9px;
    width: 100%;
    height: auto;
    float: left;

}

.mobileCssMain .tabsImgOuter img {
    width: 100%;
    height: 63px;
    object-fit: contain;
}

.mobileCssMain .MuiTab-root {
    padding-right: 0 !important;
}

.mobileCssMain .MuiBox-root {
    padding: 0 !important;
}

.mobileCssMain .MuiTab-textColorPrimary {
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    color: #333333 !important;
    letter-spacing: 1px !important;
}

.mobileCssMain .MuiTypography-root {
    padding: 15px !important;
    height: calc(100vh - 328px);
    position: relative;
    overflow: auto;
}

.mobileCssMain .Mui-selected {
    color: #c82d33 !important;
}

.mobileCssMain .activeOrdersList {
    float: left;
    width: 100%;
}

.mobileCssMain .orderListLeftData {
    float: left;
    width: 100%;
    max-width: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileCssMain .orderListRightData {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;

}

.mobileCssMain .orderMenuList {
    float: left;
    width: 100%;
}

.mobileCssMain .activeOrdersList .orderMenuList {
    overflow: auto;
    height: calc(100vh - 351px);
    position: relative;
}

.mobileCssMain .orderListOrderIdData {
    margin-left: 15px !important;
}

.mobileCssMain .QRBtn {
    min-width: 78px !important;
    height: 31px !important;
    margin: 10px 0 !important;
    border: 1px solid #121210 !important;
    background-color: #fff !important;
    color: #121210 !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
    font-size: 12px;
    width: auto;
}

.mobileCssMain .ActiveOrderDetailsData {
    float: left;
    width: 100%;
}

.mobileCssMain .orderDetailsBox {
    float: left;
    width: 100%;
    border: 2px dashed #c5dbbe;
    background-color: #F3FFEF;
    border-radius: 8px;
    margin-top: 8px;
}

.mobileCssMain .orderSummaryOrderDetails {
    border: none !important;
    margin: 0;
}

.mobileCssMain .orderSummaryOrderDetails h4 {
    font-weight: 600;
    float: left;
    padding-bottom: 3px;
    font-size: 14px;
}

.mobileCssMain .orderSummaryDetailsTop {
    display: flex;
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.mobileCssMain .ActiveOrderDetailsData {
    float: left;
    width: 100%;
}

.mobileCssMain .orderDetailsPickupSlots {
    display: inline-flex;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    justify-content: space-between;
}

.mobileCssMain .OrderDetailsQRCode {
    float: left;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}

.mobileCssMain .OrderDetailsQRCode h3 {
    font-size: 17px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 20px;
}

.mobileCssMain .orderDetailsProd {
    float: left;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
}

.mobileCssMain .orderDetailsProd h3 {
    margin-right: 5px;
}

.mobileCssMain .orderDetailsProd p {
    font-size: 12px;
    color: #878787;
    padding: 0;
}

.mobileCssMain .prodQuantity {
    /* float: left;
    width: 100%; */
    text-align: center;
}

.mobileCssMain .prodQuantity p {
    font-size: 12px;
    font-weight: 500;
    color: #878787;
}

.mobileCssMain .prodQuantity .qty {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
}

.mobileCssMain .pickUpOrder {
    float: right;
}

.mobileCssMain .pickUpOrder p {
    font-size: 13px;
    color: #121210;
    text-align: right;
    font-weight: 600;
    margin-bottom: 2px;
}

.mobileCssMain .pickUpOrder h4 {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    width: 100%;
    text-align: right;
}

.mobileCssMain .OrderIDData {
    float: left;
    /* width: 100%; */
}

.mobileCssMain .OrderIDData h4 {
    font-weight: 600;
    color: #333333;
    padding-bottom: 1px;
    float: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 157px;
    font-size: 14px;
}

.mobileCssMain .OrderIDData p {
    font-size: 12px;
    color: #7b7b7b;
    float: left;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    margin-top: 3px;
}

.mobileCssMain .orderIncDrc {
    margin-bottom: 15px;
}

.mobileCssMain .orderListRightData .orderP p {
    font-size: 12px !important;
    color: #878787 !important;
    text-align: right;
}

.mobileCssMain .orderStarPrice h5 {
    color: #333333 !important;
    font-size: 18px !important;
    font-weight: 800 !important;
}

.mobileCssMain .topHeaderTypetwo {
    background: none !important;
}

.mobileCssMain .screenTitleOuter {
    margin-top: 15px;
    width: 100%;
    float: left;
}

.mobileCssMain .screenTitleOuter h2 {
    /* margin-bottom: 13px; */
}

.mobileCssMain .screenTitleOuter p {
    font-weight: 600;
    font-size: 16px;
}

.mobileCssMain .categoryMain {
    align-content: center;
    float: left;
    width: 100%;
    height: auto;
    padding: 30px 20px;
    align-items: center;
    /* border: 0.2px solid #878787; */
    text-align: center;
    border-radius: 8px;
    background-color: #f6f6f6;
    border: 1px solid #cccccc;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.mobileCssMain .categoryMain b {
    font-size: 13px;
    color: #333333;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 125px;
    float: left;
    margin-top: -5px;
}

.mobileCssMain .marketProductsOuter {
    float: left;
    width: 100%;
    margin: 20px 0;
    margin-top: 10px;
    margin-bottom: 0;
}

.mobileCssMain .categoryHoriSlider {
    float: left;
    width: 100%;
}

.mobileCssMain .image-slider {
    float: left;
    width: 100%;
}

.mobileCssMain .image-slider button {
    background-color: white;
    color: #333333;
    /* text color */
    border: 2px solid #333333;
    /* white border */
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.mobileCssMain .imageSliderOuter {
    float: left;
    width: 100%;
}

.mobileCssMain .productDetailInfo {
    padding: 0;
    box-shadow: none;
    border: none;
}

.mobileCssMain .productSummary {
    float: left;
    width: 100%;
    margin: 20px 0;
}

.mobileCssMain .productSummary h4 {
    margin-bottom: 10px;
}

.mobileCssMain .starPriceProduct {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.mobileCssMain .summaryItemp {
    padding-bottom: 12px;
}

.mobileCssMain .summaryItemp h3 {
    font-size: 14px;
}

.mobileCssMain .imgSliderOuter {
    width: 100%;
    min-height: 198px;
    float: left;
}

.mobileCssMain .imgSliderOuter img {
    width: 100%;
}

.mobileCssMain .horiCategory {
    width: 100%;
    display: flex;
    float: left;
    flex-direction: row;
    height: auto;
    margin-top: 10px;
    padding: 9px 0;
    overflow-x: auto;
}

.mobileCssMain .activeCategory {
    background: #121210;
}

.mobileCssMain .activeCategory b {
    color: #fff;
}

.mobileCssMain .activeCategory img {
    background: #fff;
    border-radius: 5px;
    padding: 1px;
}

.mobileCssMain .imageSliderOuter .carousel .slide img {
    height: 265px;
}

.mobileCssMain .imageSliderOuter .carousel .slide .legend {
    display: none;
}

.mobileCssMain .imageSliderOuter .carousel .control-dots {
    bottom: -11px;
}

.mobileCssMain .imageSliderOuter .carousel.carousel-slider {
    padding: 20px 0;
}

.mobileCssMain .imageSliderOuter .carousel .thumbs {
    display: none;
}

.mobileCssMain .imageSliderOuter .carousel .control-dots .dot {
    box-shadow: none;
    background: #a0a0a0;
    width: 24px;
    height: 4px;
    border-radius: 50px;
}

.mobileCssMain .imageSliderOuter .carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    background: #c82d33;
}

.mobileCssMain .imageSliderOuter .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 0;
}

.mobileCssMain .breakFtICo {
    position: relative;
    color: #878787;
}

.mobileCssMain .breakFtICo svg {
    font-size: 42px;
    position: absolute;
    left: -19px;
    z-index: 0;
    top: -18px;
}

.mobileCssMain .breakFtICo b {
    color: #fff;
    z-index: 1;
    position: absolute;
    display: inline-flex;
    background: #ef3838;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 100%;
    padding: 3px;
    height: 21px;
    min-width: 21px;
    top: -25px;
}

.mobileCssMain .activeOrd {
    /* background: linear-gradient(to bottom, #d5f4ff 0%, #00bbff 100%); */
    background: #121210;
    border: 1px solid #bababa;
}

.mobileCssMain .activeOrd h5 {
    color: #fff;
}

.mobileCssMain .activeOrd .breakFtICo svg {
    color: #fff;
}

.mobileCssMain .activeOrd .breakFtICo b {
    background: #ef3838;
    color: #fff;
}

.mobileCssMain .activeOrd .MuiSvgIcon-fontSizeMedium {
    color: #fff;
}

.mobileCssMain .stockLimited {
    font-size: 11px !important;
    font-weight: 600 !important;
    color: red !important;
    background: #ffe3e3;
    padding: 2px 7px !important;
    border-radius: 5px;
    border: 2px dashed #ffb3b3;
}

.mobileCssMain .insufficientStars {
    float: left;
    width: 100%;
    text-align: center;
}

.mobileCssMain .insufficientStars h3 {
    padding: 0 15px 20px 15px;
    text-align: center;
    font-size: 15px;
    color: #D35400;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 15px;
}

.mobileCssMain .specialInc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.mobileCssMain .countValue {
    font-size: 22px;
    font-weight: 600;
    padding: 5px;
    margin: 0;
}

.mobileCssMain .vegProd {
    width: 100%;
    float: left;
    border: none !important;
    box-shadow: none !important;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
}

.mobileCssMain .vegProdMainOuter {
    width: 100%;
    float: left;
    text-align: center;
}

.mobileCssMain .vegProdMain {
    width: 100%;
    display: inline-block;
    text-align: center;
    max-width: 205px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 10px;
    margin: 30px 0;
}

.mobileCssMain .vegProdData {
    float: left;
    width: 100%;
}

.mobileCssMain .vegProdData h5 {
    font-size: 22px;
    color: #333333;
    letter-spacing: 1px;
    font-weight: 400;

}

.mobileCssMain .vegProdData p {
    font-size: 15px;
    line-height: 22px;
    color: #878787;
    font-weight: 400;
    margin-top: 15px;
}

.mobileCssMain .addMoreProdOuter {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
}

.mobileCssMain .addProdBtn {
    border-radius: 30px !important;
    height: 48px !important;
    margin-top: 23px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.mobileCssMain .prodBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    float: left;
    width: 100%;
}

.mobileCssMain .countValue2 {
    margin: 0 3px;
}

.mobileCssMain .closeBtn {
    border: none;
    background: none;
    color: #121210;
}

.mobileCssMain .modal-footer {
    text-align: right;
}

.mobileCssMain .searchInput {
    float: left;
    width: 100%;
}

.mobileCssMain .searchInput .MuiTextField-root {
    border-radius: 8px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.mobileCssMain .emptyCartData {
    float: left;
    width: 100%;
    text-align: center;
}

.mobileCssMain .emptyCartData h3 {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
}

.mobileCssMain .emptyCartData p {
    font-size: 14px;
    color: #878787;
    font-weight: 500;
    line-height: 20px;
    margin: 10px 20px;
}

.mobileCssMain .boxImg {
    width: 100%;
    float: left;
}

.mobileCssMain .boxImg img {
    width: 100%;
}

.mobileCssMain .cartBtnOuter {
    margin: 40px 0 20px 0;
}

.mobileCssMain .cartBtnOuter Button {
    font-weight: 600 !important;
    color: #c82d33 !important;
    border-color: #c82d33 !important;
}

.mobileCssMain .topheaderIcons {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    float: left;
    align-items: center;
}

.mobileCssMain .topheaderIcons .notiIco {
    margin-right: 20px;
}

.mobileCssMain .topheaderIcons .searchIcon {
    margin-right: 20px;
}

.mobileCssMain .black {
    color: #555555 !important;
}

.mobileCssMain .profileBox {
    padding: 15px;
    padding-bottom: 5px;
    margin-top: 5px;
    float: left;
    width: 100%;
    border-radius: 8px;
    background-color: #f6f6f6;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.mobileCssMain .profileName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.mobileCssMain .profileName h2 {
    font-size: 18px;
    font-weight: 600;
    color: #c82d33;
}

.mobileCssMain .profileBox h4 {
    font-size: 15px;
    font-weight: 500;
    align-items: center;
}

.mobileCssMain .profileDetailsArrow {
    /* margin: 5px 0 0 0; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    /* gap: 10px; */
}

.mobileCssMain .profileArrowIcon {
    line-height: 0;
}

.mobileCssMain .profileArrowIcon svg {
    font-size: 16px;
    color: #121210;
}

.mobileCssMain .horiCategory {
    width: 100%;
    display: flex;
    float: left;
    flex-direction: row;
    height: auto;
    margin-top: 10px;
    padding: 9px 0;
    overflow-x: auto;
}

.mobileCssMain .categoryHori {
    height: 70px;
    padding: 10px;
    min-width: 130px;
    border: 1px solid #cccccc;
}

.mobileCssMain .categoryHori svg {
    color: #767676;
}

.mobileCssMain .activeCategory {
    background: #121210;
}

.mobileCssMain .activeCategory b {
    color: #fff;
}

.mobileCssMain .profileTabs {
    float: left;
    width: 100%;
}

.mobileCssMain .profileTabs button {
    font-size: 16px;
    font-weight: 400;
    color: #878787;
}

.mobileCssMain .profileRow1 {}

.mobileCssMain .profileCard {
    display: flex;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    float: left;
    min-height: 115px;
    position: relative;
    padding: 15px;
    flex-direction: column;
    border-radius: 10px;
    text-align: center;
}

.mobileCssMain .profileCard h2 {
    font-size: 20px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.mobileCssMain .profileCard b {
    font-size: 13px;
    color: #615a5a;
    font-weight: 600;
}

.mobileCssMain .starTitle h2 {
    margin-top: 20px !important;
    /* margin-bottom: 20px !important;
    font-size: 20px !important;
    font-weight: 100 !important; */

}

.mobileCssMain .starSummaryStar {
    margin-top: 5px;
    color: #FFE600;
}

.mobileCssMain .warningCount {
    border-radius: 50px;
    background-color: #c82d33;
    color: #ffffff;
    height: 24px;
    width: 24px;
    align-content: center;
    position: absolute;
    right: 30px;
    margin-top: 15px;
}

.mobileCssMain .warningCount h4 {
    text-align: center;
}

.mobileCssMain .profileTopTab {
    overflow: auto;
}

.mobileCssMain .profileTabs .profileTopTab .MuiTab-root {
    margin: 5px 5px !important;
    font-size: 14px !important;
    color: #000000 !important;
    font-weight: 600 !important;
    min-width: 100px;
    padding: 0 15px !important;
    letter-spacing: .5px !important;
}

.mobileCssMain .toptabActive {
    color: #c82d33;
    font-weight: 400;
}

.mobileCssMain .profileTabs .profileTopTab .Mui-selected {
    color: #c82d33 !important
}

.mobileCssMain .profileTabs .profileTopTab .MuiTabs-indicator {
    background: #c82d33 !important;
}



.mobileCssMain .profileData {
    float: left;
    width: 100%;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.mobileCssMain .personalDetails {
    width: 100%;
    float: left;
    margin: 20px 0;
}

.mobileCssMain .personalDetails h3 {
    font-size: 16px;
    font-weight: 300;
    color: #333333;

}

.mobileCssMain .profileRow {
    display: flex;
    margin-top: 15px;
}

.mobileCssMain .rowIcons {
    color: #878787;
    margin-right: 10px;
}

.mobileCssMain .rowInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mobileCssMain .rowInfo p {
    color: #878787;
    margin-bottom: 5px;
    font-size: 12px;

}

.mobileCssMain .rowInfo p~p {
    color: #333333;
    font-weight: 600;
    font-size: 11px;
}

.mobileCssMain .profileEmp {
    justify-content: space-between;
}

.mobileCssMain .leaveData {
    width: 100%;
    float: left;
}

.mobileCssMain .addIcon {
    margin: 0 10px;
    width: 28px !important;
    height: 28px !important;
}

.mobileCssMain .leaveData h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    float: left;

}

.mobileCssMain .leaveDataCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important; */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.mobileCssMain .leaveDataCard-left{
    width: 100%;
}

.mobileCssMain .leaveDataCard-left h4 {
    font-size: 14px;
    color: #000000;
    margin-bottom: 4px;
    font-weight: 400;
    text-align: justify;
    overflow-wrap: anywhere;
}

.mobileCssMain .leaveDataCard-left p {
    font-size: 11px;
    color: #bebebe;
    font-weight: 500;
    letter-spacing: 1px;
}

.mobileCssMain .leaveDataCard-right {
    text-align: right;
}

.mobileCssMain .leaveDataCard-right h4 {
    font-size: 12px;
    color: #9e9e9e;
    margin-bottom: 4px;
    font-weight: 500;
    letter-spacing: 1px;
}

.mobileCssMain .leaveDataCard-right p {
    font-size: 11px;
    color: #c82d33;
    font-weight: 600;
}

.mobileCssMain .addLeaveBtn {
    margin-top: 0 !important;
    font-weight: 400 !important;
}

.mobileCssMain .leaveFilter {
    float: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.mobileCssMain .leaveFilter h3 {
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    float: left;
}

.mobileCssMain .leaveAppForm {
    float: left;
    width: 100%;
}

.mobileCssMain .leaveAppForm h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
}

.mobileCssMain .docData h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    float: left;
}

.mobileCssMain .docData .leaveFilter p {
    font-size: 14px;
    color: #c82d33;
    font-weight: 500;
}

.mobileCssMain .docListOuter {
    float: left;
    width: 100%;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 0;
}

.mobileCssMain .docListInner {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.mobileCssMain .docListLeft {
    float: left;
    width: 100%;
    max-width: 240px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mobileCssMain .docName p {
    font-size: 12px;
    font-weight: 600;
    color: #2b2929;
    margin-bottom: 5px;
}

.mobileCssMain .docName p~p {
    font-size: 12px;
    font-weight: 400;
    color: #b5b5b5;
}

.mobileCssMain .loginInput {
    border: 1px solid #cccccc !important;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: none;
    padding: 15px;
    font-size: 14px;
    color: #001f3f !important;
    font-weight: 500;
}

.mobileCssMain .txtAreaInput {
    border: 1px solid #cccccc !important;
    width: 100%;
    height: 120px;
    border-radius: 8px;
    border: none;
    padding: 15px;
    font-size: 14px;
    color: #001f3f !important;
    font-weight: 500;
}

.mobileCssMain .errorSt {
    color: red !important;
    text-align: left;
    font-size: 13px;
    margin-top: 2px;
    font-weight: 500;
}

.mobileCssMain .docListOuter h5 {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
}

.mobileCssMain .docIcon {
    align-content: center;
    text-align: center;
    background-color: #F3FFEF;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    color: #555555;
}

.mobileCssMain .divider {
    float: left;
    width: 100%;
    border-bottom: 1px solid #F5F5F5;
    margin: 10px 0;
}

.mobileCssMain .red {
    color: #c82d33;
}

.mobileCssMain .head {
    color: #333333 !important;
}

.mobileCssMain .buyHistory {
    float: left;
    width: 100%;
}

.mobileCssMain .buyHistory h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    float: left;
}

.mobileCssMain .orderHis {
    align-items: flex-end !important;
}

.mobileCssMain .warningData {
    float: left;
    width: 100%;
}

.mobileCssMain .warningData h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    float: left;
    margin-bottom: 15px;
}

.mobileCssMain .warnCard {
    float: left;
    width: 100%;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    margin: 0 0 20px !important;
}

.mobileCssMain .warnCard h2 {
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    margin-bottom: 2px;
    overflow-wrap: anywhere;
}

.mobileCssMain .warnCard h6 {
    font-size: 11px;
    color: #333333;
    font-weight: 400;
    margin-bottom: 2px;
}

.mobileCssMain .warnCard p {
    font-size: 11px;
    color: #878787;
    font-weight: 400;
}

.mobileCssMain .warnDetail {
    float: left;
    width: 100%;
    margin: 20px 0;
}

.mobileCssMain .warnDetailInfo {
    margin: 10px 0;
}

.mobileCssMain .warnDetailInfo p {
    font-size: 14px;
    color: #878787;
    font-weight: 400;
    line-height: 21px;
    text-align: justify;
    overflow-wrap: anywhere;
}


.mobileCssMain .buyHistory .MuiTypography-root {
    padding: 0 !important;
    margin-top: 15px !important;
}

.mobileCssMain .buyHistory .specialMenu h3 {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 4px;
    color: #333333;
    letter-spacing: 0.5px;
}

.mobileCssMain .buyHistory .specialMenu p {
    font-size: 11px;
    font-weight: 300;
    padding-bottom: 4px;
    color: #333333;
}

.mobileCssMain .lightTextcolor {
    color: #878787 !important;
}

.mobileCssMain .fixedBtnOuter {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 15px;
    width: 100%;
    z-index: 99;
}

.mobileCssMain .fixedBtnOuter Button {
    width: 100%;
    gap: 10px;
}

.mobileCssMain .qrOuter {
    margin: 5px 0;
    float: left;
    width: 100%;
}

.mobileCssMain .orderMore {
    float: left;
    width: 100%;
    text-align: center;
}

.mobileCssMain .orderMore a {
    color: #121210;
    font-size: 13px;
    text-decoration: underline;
}

.mobileCssMain .modalContainer {
    padding: 4px !important;
}

.mobileCssMain .outerIncdec .MuiButtonBase-root {
    min-width: 27px;
    padding: 0;
}

.mobileCssMain .contentTopSpacing .MuiTab-root {
    padding: 0px;
    font-size: 10px !important;
    min-width: 70px;
    max-width: 70px;
    padding-bottom: 5px;
}

.mobileCssMain .contentTopSpacing .tabsImgOuter {
    padding: 3px;
}

.mobileCssMain .contentTopSpacing .MuiTabs-flexContainer {
    justify-content: space-around;
    padding: 0 10px;
}



.mobileCssMain .buyHistory .MuiTab-root {
    padding: 0px;
    font-size: 11px !important;
    min-width: fit-content;
    max-width: fit-content;
    padding-bottom: 5px;
}

.mobileCssMain .buyHistory .tabsImgOuter {
    padding: 3px;
}

.mobileCssMain .buyHistory .MuiTabs-flexContainer {
    justify-content: space-around;
    padding: 0px;
}


.mobileCssMain select {
    background: url("../images/selectIcon.svg") no-repeat !important;
    background-position: calc(100% - 0.75rem) center !important;
    appearance: none !important;
    padding-right: 2rem !important;
}

.mobileCssMain .fixedBottomPadding {
    padding-bottom: 80px;
    float: left;
    width: 100%;
    position: relative;
}

.mobileCssMain .confirmStar .starIcon {
    margin-bottom: -10px;
}

.mobileCssMain .confirmStar h5 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: -10px;
}

/* .{
    margin-right: 15px;
} */


.mobileCssMain .headerDataIcons {
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.mobileCssMain .headerGreyIcon {
    color: #555555;
    gap: 15px;

}

.mobileCssMain .myToggleMain .MuiToggleButtonGroup-groupedHorizontal {
    padding: 0;
    border: none;
}

.mobileCssMain .myToggleMain .Mui-selected .categoryHori {
    background: #121210;
    border: 1px solid #ffffff;
}

.mobileCssMain .myToggleMain .Mui-selected {
    background: transparent !important;
}

.mobileCssMain .myToggleMain .horiCategory .MuiToggleButtonGroup-root {
    gap: 10px;
    padding: 0 1px;
}

.mobileCssMain .myToggleMain .Mui-selected .categoryMain b {
    color: #fff;
}

.mobileCssMain .myToggleMain .Mui-selected .categoryHori svg {
    color: #fff;
}

.css-16tsnoi {
    display: none !important;
}

.PrivateSwipeArea-root {
    display: none !important;
}

.mobileCssMain .breakfastP {
    width: 100%;
    float: left;
    margin-top: 15px;
}

.mobileCssMain .breakfastP p {
    color: #686565;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

.forCartAlign {
    position: relative;
}

.cartNew {
    position: absolute;
    right: 9px;
    top: 14px;
    color: #e8a217;
}

.removeFromCart {
    color: #f53636 !important;
}

.newCountSt {
    height: 35px;
    display: flex;
}

.newCountSt .outerIncdec {
    display: flex;
    align-items: center;
}

.newCountSt .outerIncdec b {
    font-size: 17px;
    line-height: 0;
    padding: 0 1px;
    font-weight: 600;
    color: #fff;
}

.addToCartIco {
    position: absolute !important;
    right: 11px;
    top: 11px;
}

.addToCartIco svg {
    color: #c82d33;
}

.mobileCssMain .newLunchDinner .orderNotiDiv {
    box-shadow: none;
    border: none;
    padding: 15px 0;
    padding-left: 65px;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
    margin: 0;
}

.mobileCssMain .newLunchDinner .orderNotiDiv:last-child {
    border-bottom: none !important;
}

.mobileCssMain .newLunchDinner .orderNotiDiv .ordIcoDiv {
    margin-left: 0;
    left: 5px;
}

.mobileCssMain .newLunchDinner .lunchDener b {
    margin-left: 0;
    text-transform: capitalize;
}

.mobileCssMain .newLunchDinner {
    padding-bottom: 0;
}

.countWithAddBtMain {
    text-align: right;
    /* margin-bottom: 13px; */
}

.marketProductsOuter .countWithAddBtMain {
    /* margin-bottom: 13px; */
}

.beforeAddBt {
    background: #121210 !important;
    color: #fff !important;
    border-radius: 100% !important;
    text-transform: capitalize !important;
    padding: 2px 5px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    min-width: 35px !important;
    height: 35px;
    /* border: 1px solid #c82d33 !important; */
}

.countWithAddBtMain .outerIncdec {
    display: none;
}

.addedCount .outerIncdec {
    display: flex !important;
    align-items: center;
    border: 1px solid #c82d33;
    border-radius: 50px;
    background: linear-gradient(to bottom, #ff454c 0%, #c82d33 47%, #ff454c 100%);
}

.addedCount .beforeAddBt {
    display: none !important;
}

.mobileCssMain .marginBtMins {
    margin-bottom: 0 !important;
}

.mobileCssMain .forBottomPosition {
    position: absolute;
    right: 10px;
    bottom: 12px;
}

.mobileCssMain .forBottomPosition .countValue {
    line-height: 0;
    font-weight: 600;
    font-size: 17px;
    color: #fff;
}

.mobileCssMain .itemCountSt {
    margin-left: 10px;
    font-size: 13px !important;
    font-weight: 500 !important;
    padding: 0 !important;
}

.botSpace {
    margin-bottom: 10px !important;
}

.forTopPosition .newCountSt {
    /* position: static; */
}

.breakfastBtn .newCountSt {
    width: 100%;
    left: 0;
    padding: 0 15px;
    text-align: center;
}

.breakfastBtn .addedCount .outerIncdec {
    display: flex !important;
    width: 100%;
    gap: 27px;
    justify-content: center;
    background: transparent;
    border: none;
}

.activeOrd .beforeAddBt {
    /* background: linear-gradient(to bottom, #21c2fc 0%, #c2efff 100%) !important;
    color: #000 !important; */
    background: linear-gradient(to bottom, #c82d33 0%, #ff2b34 100%) !important;
    color: #fff !important;
}

.breakfastBtn .newContTopSpace .beforeAddBt {
    border-radius: 5px !important;
    border: none !important;
}

.breakfastBtn .newContTopSpace {
    margin-top: 12px;
}

.mobileCssMain .fixedBtnWithBotTab {
    bottom: 70px;
}

.withBotomTabFixBt {
    padding-bottom: 148px;
    float: left;
    width: 100%;
}

.activeComplementory {
    border: 1px solid #c86609 !important;
    background: linear-gradient(to bottom, #ffeddc 0%, #ff820a 100%);
}

.activeComplementory .beforeAddBt {
    background: linear-gradient(to bottom, #ff8714 0%, #ffe0c3 100%) !important;
    color: #000 !important;
}

.activeComplementory .orderFlag {
    background: linear-gradient(to bottom, #ffce9d 0%, #ff7d00 100%);
    color: #000;
}

.mobileCssMain .activeComplementory .MuiSvgIcon-fontSizeMedium {
    color: #fff;
}

.breakfastBtn .listMainStar {
    justify-content: center;
    position: absolute;
    top: 2px;
    right: 5px;
    gap: 4px;
}

.breakfastBtn .listMainStar svg {
    margin-right: 2px;
    color: #FFE600 !important;
}

.activeOrd .listMainStar {
    color: #fff !important;
}

.orderFlag {
    position: absolute;
    top: 0px;
    background: linear-gradient(to bottom, #83d5f3 0%, #00bbff 100%);
    width: 100%;
    transform: rotate(0deg);
    left: 0;
    font-size: 12px;
    font-weight: 600;
    padding: 4px;
    text-align: left;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 57px;
}

.activeComplementory .listMainStar svg {
    color: #ffe600 !important;
}

.activeComplementory .IncDrcBtn {
    color: #000 !important;
}

.activeComplementory .breakFtICo {
    color: #000 !important;
}

.activeComplementory .breakFtICo b {
    background: #ef3838;
    color: #fff;
}

.mobileCssMain .activeOrdersTab {
    margin-top: 15px;
}

.mobileCssMain .setTimeSt {
    float: left;
    width: 100%;
    justify-content: space-between;
}

.mobileCssMain .removeFlex {
    display: block;
}

.removeFlex h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
    padding: 0 !important;
}

.mobileCssMain .activeOrdersList .orderMenuListWithViewBt {
    height: calc(100vh - 415px);
}

.mobileCssMain .activeOrdersList .specialMenu p {
    margin: 2px 0;
    white-space: nowrap;
}

.newProductOuter {
    float: left;
}

.newProductOuter .productBoxtBottom {
    float: left;
    width: 100%;
    margin-top: 5px;
}

.mobileCssMain .newProductOuter h3 {
    /* text-align: center; */
}

.removeCartSt {
    width: 48px;
    height: 48px;
    background: #ffd2d2;
    position: absolute;
    top: 4px;
    right: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f40f0f;
    border: 1px solid #ff8484;
}

.distIco {
    position: absolute;
    color: #f40f0f;
    font-size: 18px !important;
    right: 0;
    top: -4px;
    background: #fff;
    border-radius: 100%;
    padding: 1px;
}

.newDashContDiv {
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.orderMealStTx {
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    float: left;
    margin-bottom: 10px;
}

.profileTabs .MuiTypography-root {
    height: calc(100vh - 395px);
}

.mobileCssMain .newSearchTopSpace {
    margin-bottom: 0;
    margin-top: 40px;
}

.newMyToggleMain {
    margin-top: 0;
    width: 100%;
    float: left;
}

.newMyToggleMain .horiCategory {
    margin-top: 0;
    padding-top: 0;
}

.newContTopSpace {
    margin-top: 23px;
    height: 33px;
}

.newContTopSpace .beforeAddBt {
    min-width: 75px !important;
    height: 30px;
}

.menuNotAvailMain {
    width: 100%;
    float: left;
    text-align: center;
}

.menuNotAvailMain img {
    width: 190px;
}

.moreSt {
    float: left;
    width: 100%;
    text-align: center;
    background: #121210;
    border-radius: 5px;
    padding: 5px;
}

.moreSt a {
    text-decoration: none !important;
    color: #fff !important;
    font-weight: 600;
}

.menuNotAvailMain p {
    margin-top: -15px;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #4e4a4a;
}

.mobileCssMain .newSummaryMain {
    margin-top: 7px;
}

.mobileCssMain .myOrderPrice {
    min-height: 90px;
}

.mobileCssMain .myOrderPrice h2 {
    font-size: 22px;
}

.heightIncr {
    padding: 20px 10px 20px 60px !important;
}

.heightIncr h2 {
    margin-bottom: 0 !important;
}

.mobileCssMain .newProductOuter p {
    float: left;
    width: 100%;
}

.newUserNm {
    width: 100%;
    text-align: center;
    margin-top: -29px;
    font-size: 18px;
    font-weight: 700;
}

.newUserNm b {
    background: #fff;
    padding: 2px 9px;
    border-radius: 5px;
}

.bottCartBt {
    display: flex;
    gap: 10px;
    align-items: center;
}

.usernamest {
    color: #c82d33 !important;
    width: 100%;
    float: left;
    font-size: 17px !important;
}

.newSpecialOf {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.borderRadius {
    border-radius: 8px !important;
}

.posiRele {
    position: relative;
}

.countEm {
    position: absolute;
    top: -5px;
    right: -6px;
    background: red;
    padding: 3px 4px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 12px;
    font-style: normal;
    border: 1px solid #fff;
    font-weight: 700;
}

.mobileCssMain .MuiBox-root .MuiTabs-indicator {
    background-color: #c82d33 !important;
}

.mobileCssMain .notiContent {
    float: left;
    width: 100%;
    margin: 10px 0;
}

.mobileCssMain .notiContent .notiText {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.mobileCssMain .notiContent .notiText p {
    padding: 12px;
    color: #878787;
    font-size: 14px;
    font-weight: 400;
}


.mobileCssMain .notiContent .unreadNoti p {
    font-weight: 600 !important;
    color: #121210 !important;
}

.mobileCssMain .notiContent .unreadNoti span {
    background: green;
    border-radius: 8px;
    color: #ffffff;
    padding: 0 8px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.mobileCssMain .notiContent .notiLeftContent {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    margin-bottom: 10px;

}

.mobileCssMain .notiContent .notiClose {
    color: #ff030b;
}

.mobileCssMain .notiContent .notiTitle {
    color: #333333;
    font-size: 16px;
    margin-bottom: -8px;
}

.mobileCssMain .docListInner .docHeading {
    font-size: 16px !important;
    color: #333333 !important;
}

.mobileCssMain .breakfastOrderColor {
    border-left: 8px solid #D9B700 !important;
}

.mobileCssMain .lunchOrderColor {
    border-left: 8px solid #66cdaa !important;
}

.mobileCssMain .dinnerOrderColor {
    border-left: 8px solid #3e3768 !important;
}

.ordSucHed {
    width: 100%;
    float: left;
    font-size: 18px;
    color: green;
    font-weight: 600;
    letter-spacing: 1px;
}

.mobileCssMain .productDetailInfo .countValue {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    line-height: 0;
    color: #fff;
}

.nodatDiv {
    width: 100%;
    float: left;
    text-align: center;
}

.nodatDiv img {
    width: 100%;
    max-width: 320px;
}

.reordDi {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mobileCssMain .newCon {
    font-size: 21px;
    font-weight: 500;
    padding: 14px 1px;
    line-height: 0;
    color: #ffff;
}

.confirmSwipeMain {
    /* height: calc(100vh - 454px); */
    display: flex;
    align-items: center;
    text-align: center;
}

.myNewReordTabScroll {
    height: calc(100vh - 402px) !important;
}

.confirmSwipeMain .confirmStar {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    line-height: normal;
    margin-top: -10px;
}

.resrPas {
    font-size: 20px !important;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.resrPas svg {
    color: #c82d33;
    font-size: 48px;
}

.confirmSwipeMain .confirmStar h5 {
    font-size: 48px;
    font-weight: 900;
    line-height: normal;
}

.confirmSwipeMain .confirmStar svg {
    margin: 0 !important;
    font-size: 40px;
}

.mobNotiFicMain {}

.mobNotiFicMain .MuiPaper-elevation {
    width: 100%;
}

.mobNotiFicMain .MuiPaper-rounded {
    border-radius: 7px 7px 20px 20px;
    background: #c82d33;
    top: 49px !important;
    position: relative;
    overflow-x: initial;
    overflow-y: initial;
    border-bottom: 10px solid #8c0f14;
}

.mobNotiFicMain .MuiPaper-rounded::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #c82d33;
    position: absolute;
    top: -8px;
    right: 6px;
}

.mobNotiFicMain .MuiPaper-rounded .MuiMenu-list .MuiMenuItem-root {
    color: #fff;
    white-space: normal;
    border-bottom: 1px dashed #ffc5c7;
    padding-bottom: 13px;
    margin-bottom: 7px;
    font-size: 13px;
    letter-spacing: 1px;
}

.mobNotiFicMain .MuiPaper-rounded .MuiMenu-list .MuiMenuItem-root:last-child {
    border-bottom: none;
    margin-bottom: 0;
}


/* Sagar Ticket Page */
.FloatBtnView {
    position: fixed;
    bottom: 15px;
    right: 15px;
}

.complainBox .TicketTitle {
    font-size: 14px;
    color: black;
    font-weight: 600
}

.complainBox .TicketDescription {
    font-Size: 14px;
    color: rgb(111, 111, 111);
    font-weight: 500;
}

.complainBox .TicketNumberNIconView {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.TicketNumberNIconView .TicketNumberView {
    height: 25px;
    background-Color: red;
    border-Radius: 20px;
    padding-Inline: 10px;
    justify-content: center;
}

.TicketNumberView .TicketNumberTxt {
    font-Size: 12px;
    font-weight: 600;
    color: white;
    margin-Top: 4px
}

.TicketNumberNIconView .TicketReopenBtn {
    height: 25px;
    background-Color: rgba(5, 161, 57, 0.896);
    border-Radius: 20px;
    padding-Inline: 10px;
    justify-content: center;
}

.TicketReopenBtn .TicketNumberTxt {
    font-Size: 12px;
    font-weight: 600;
    color: white;
    margin-Top: 4px
}

.complainBox .TickeImgNameTimeView {
    display: flex;
    flex-Direction: row;
    justify-Content: space-between;
}

.TicketTimeStatusView {
    display: flex;
    flex-direction: column;
}

.TickeImgNameTimeView .TicketTimeStatusView .TicketTimeStatusTxt {
    font-Size: 12px;
    color: rgb(111, 111, 111);
    font-weight: 600;
    display: flex;
    justify-Content: flex-end
}

.TicketImgNameView {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.TickeImgNameTimeView .TicketImgNameView .TicketProfileImg {
    height: 50px;
    width: 50px;
    border-Radius: 25px;
}

.TicketUserName {
    font-Size: 14px;
    color: black;
    font-weight: 600;
}

.TicketUserMoNo {
    font-Size: 12px;
    color: rgb(111, 111, 111);
    font-weight: 500;
}

.FileAttachment {
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 10px;
    height: 30px;
    align-items: center;
    padding-inline: 8px;
    position: relative;
    cursor: pointer;
}

.FileView {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-Top: 10px;
    background-Color: rgb(227, 227, 227);
    align-items: center;
    padding: 5px;
    border-radius: 5px;
}

.FileAttachment input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
}

.ChatInputView {
    width: 100%;
    height: 'auto';
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #afafaf;

}

.ChatInputView .inputChatBox {
    width: 100%;
    bottom: 15px;
    height: 48px;
    padding-left: 5px;
    border-width: 0;
}

.inputBottomView {
    display: flex;
    width: 93%;
    align-items: center;
}

.chatMsgView1 {
    height: auto;
    width: auto;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.chatMsgView1 .TicketProfileImg {
    height: 40px;
    width: 40px;
    border-Radius: 25px;
}

.chatMsgView1 .ChatView1 {
    background-color: rgb(255, 207, 207);
    padding: 5px 10px;
    height: auto;
    width: auto;
    max-width: 260px;
    border-radius: 5px;
}

.ChatView1 p {
    font-size: 14px;
    color: #5b0000;
    font-weight: 500;
}

.chatMsgView2 {
    height: auto;
    width: auto;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.chatMsgView2 .TicketProfileImg {
    height: 40px;
    width: 40px;
    border-Radius: 25px;
}

.chatMsgView2 .ChatView2 {
    background-color: rgb(252, 159, 159);
    padding: 5px 10px;
    height: auto;
    width: auto;
    max-width: 260px;
    border-radius: 5px;
}

.ChatView2 p {
    font-size: 14px;
    color: #5b0000;
    font-weight: 500;
}

.chatNameStamp {
    font-size: 11px !important;
    color: rgb(85, 5, 5) !important;
    font-weight: 500 !;
}

.chatTimeStamp {
    font-size: 10px !important;
    color: #343434 !important;
    font-weight: 400 !important;
}

.leftChat {
    display: flex;
    justify-content: flex-start;
}

.rightChat {
    display: flex;
    justify-content: flex-end;
}

.margineTopDiv {
    margin-top: 15px;
}

.TicketTabView .MuiTypography-root {
    height: calc(100vh - 280px) !important;
}



/* start manager flow css added by vaishali  */

.managerTopTabs {
    float: left;
    width: 100%;
}

.managerTopTabs .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-around;
}

.managerMain {
    float: left;
    width: 100%;
}

.managerServices {
    float: left;
    width: 100%;
    margin-top: 5px;
}

.managerMain .empList {
    margin-top: 10px;
}

.managerMain .empListData p {
    font-size: 13px !important;
    color: #121210 !important;
    font-weight: 400 !important;
}

.managerMain .empListDataright p {
    font-size: 11px !important;
    color: #878787 !important;
    font-weight: 400 !important;
}

.managerMain .empListDataright h4 {
    font-size: 14px !important;
    color: #121210;
}


.managerMain .MuiTabs-flexContainer {
    justify-content: space-between !important;
}

.managerMain .pendingLeavesOuter {
    float: left;
    width: 100%;
}

.pendingLeavesOuterMain .MuiTypography-root {
    padding: 10px 2px !important;
}

.managerMain .approvependingLeave {
    align-items: flex-end !important;
}

.managerMain .leaveListSearch {
    margin-bottom: 0 !important;
}

.managerMain .leaverFilterIcon {
    margin: 0 0 0 10px !important;
}

.managerMain .empLeavedetail {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.managerMain .empLeavedetail .leaveStatus {
    color: #121210;
}

.managerMain .empLeavedetail span {
    color: #ff030b !important;
    font-weight: 600 !important;
}

.managerMain .empLeaveButtons {
    width: 100%;
    float: left;
}

.managerMain .empLeaveButtons .leaveGreenBtn {
    background-color: #4C8F2A !important;
}

.managerMain .empLeaveButtons .leaveRedBtn {
    background-color: #ff030b !important;
}



.empLeaveButtons .leaveCancelBtn {
    border: 1px solid #686565 !important;
    background-color: #ffffff !important;
    color: #686565 !important;
}

.leaveComment {
    height: 98px !important;
}

.empLeaveHeading h3 {
    font-size: 20px !important;
}

.empWarnList {
    float: left;
    width: 100%;
}

.empWarnList h5 {
    font-size: 13px;
    font-weight: 500;
    color: #c82d33;
}

.empWarnList h6 {
    font-size: 11px !important;
    color: #121210 !important;
    margin-bottom: 4px !important;
    font-weight: 400 !important;
}


.EmpLeaveHistoryTabs {
    float: left;
    width: 100%;
}

.EmpLeaveHistoryTabs .MuiTypography-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.leavegreen {
    color: #0a7400 !important;
}

.profileTabs .leaveListSearch {
    margin-bottom: 0 !important;
}

.MEmpleaverFilterIcon {
    margin: 0 0 0 10px !important;
}

.profileName span {
    font-size: 16px;
    color: #121210;
}

/* warning details comments */
.warnDetailCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
}

.warnDetail h4 {
    font-size: 12px;
    color: #c82d33;
    font-weight: 400;
}

.bottomTextAreaOut {
    width: 100%;
    float: left;
    position: relative;
}

.bottomTextAreaOut svg {
    position: absolute !important;
    right: 10px !important;
    top: 15px !important;
}

.managerMain .ticketDetailsMain {
    width: 100%;
    float: left;
}

.managerMain .ticketDatNtime {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    top: 10px;
    right: 17px;
    color: #c1c1c1;
}

.managerMain .tickekDeailsTop {
    width: 100%;
    float: left;
    border-radius: 10px 10px 0 0;
    background: #c82d33;
}

.managerMain .tickekDeailsTop .suportTicList {
    margin-bottom: 0;
    border: none;
}

.managerMain .tickekDeailsTop .proNameNMob h2 {
    color: #fff;
}

.managerMain .tickekDeailsTop .proNameNMob p {
    color: #fff;
}

.managerMain .tickekDeailsTop .suportListBotLft h2 {
    color: #ffd7d7;
}

.managerMain .tickekDeailsTop .suportListBotLft p {
    color: #ffc3c3;
}

.managerMain .tickekDeailsTop .ticketDatNtime {
    color: #fff;
}

.managerMain .tickekDeailsTop .tickLabl {
    background: #e63940;
    top: -4px;
    border-radius: 2px 2px 9px 9px;
}

.managerMain .ticketDetailsMain .disctionBottomContMain {
    width: 100%;
    float: left;
    padding: 0 !important;
    border: none !important;
    border-radius: 0px !important;
    background: #fff;
}

.managerMain .ticketDetailsMain .disctionComntScrol {
    max-height: 540px;
    width: 100%;
    float: left;
    overflow: auto;
    padding-bottom: 12px;
    padding-right: 20px;
}

.managerMain .ticketDetailsMain .ticketChatMain {
    padding-right: 0;
}

.managerMain .ticketDetailsMain .ticketChatLeft {
    width: 75%;
    /* max-width: 460px; */
    float: left;
    margin-left: 44px;
    position: relative;
    margin-bottom: 10px !important;
}

.managerMain .ticketDetailsMain .parkSocityInner {
    width: 100%;
    float: left;
    padding: 13px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0px 19px 6px -17px rgba(0, 0, 0, 0.09);
}

.managerMain .ticketDetailsMain .parkSocityInnerTicket {
    cursor: pointer;
}

.managerMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket {
    padding: 11px 13px;
    margin-bottom: 5px !important;
    background: #f5f5f5 !important;
    border: 0px none;
    box-shadow: 0px 0px 0px -17px rgba(0, 0, 0, 0);
    border-radius: 0px 15px 15px 15px;
}

.managerMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 13px solid transparent;
    border-right: 11px solid #eaeaea;
    left: -8px;
    top: 0;
}

.managerMain .ticketDetailsMain .parkSocityTop {
    width: 100%;
    float: left;
    padding-left: 46px;
}

.managerMain .ticketDetailsMain .ticketChatMain .parkSocityTop {
    padding-left: 0;
}

.managerMain .ticketDetailsMain .parkSocityTopPro {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    left: 13px;
    background: #f7f7f7;
    /* background-image: url(https://cdn1.vectorstock.com/i/1000x1000/23/70/man-avatar-icon-flat-vector-19152370.jpg); */
    background-size: 100%;
    background-repeat: no-repeat;
    border: 1px solid #c2c2c2;
}

.managerMain .ticketDetailsMain .parkSocityInnerTicket .parkSocityTopPro {
    left: 19px;
}

.managerMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parkSocityTopPro {
    left: -43px;
    right: auto;
    top: 2px;
}

.managerMain .ticketDetailsMain .parkSocityTopPro img {
    width: 100%;
    height: 36px;
    object-fit: cover;
    background: #fff;
}

.managerMain .ticketDetailsMain .parContTx {
    width: 100%;
    float: left;
    font-size: 12px;
    color: #565656;
    margin: 2px 0 0;
}

.managerMain .ticketDetailsMain .parkSocityInnerTicket .parContTx {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 20px;
}

.managerMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parContTx {
    margin-top: 0px;
    color: #878787 !important;
    font-weight: 400 !important;
    word-wrap: break-word;
}

.managerMain .ticketDetailsMain .ticketChatRight {
    width: 75%;
    /* max-width: 460px; */
    float: right;
    margin-right: 44px;
    position: relative;
    margin-bottom: 5px !important;
}

.managerMain .ticketDetailsMain .parContTxDate {
    width: 100%;
    margin-top: 0px !important;
    text-align: left;
    font-size: 11px !important;
    color: #7aaed3;
    letter-spacing: 1px;
}

.managerMain .ticketDetailsMain .ticketChatLeft .parkSocityInnerTicket .parContTxDate {
    margin-top: 5px;
    color: #676767;
    font-weight: 500;
    text-align: left;
    font-style: normal !important;
    font-weight: 500;
    letter-spacing: 0.6px !important;
}

.managerMain .ticketDetailsMain .ticketChatRight {
    clear: both;
}

.managerMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket {
    text-align: right;
    padding: 11px 13px;
    background: #FFECED !important;
    border: 0px none;
    box-shadow: 0px 0px 0px -17px rgba(0, 0, 0, 0);
    position: relative;
    border-radius: 15px 0px 15px 15px;
    margin-bottom: 5px !important;
}

.managerMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 13px solid transparent;
    border-left: 11px solid #FFECED !important;
    right: -8px;
    top: 0;
}

.managerMain .ticketDetailsMain .ticketChatMain .parkSocityTop {
    padding-left: 0;
}

.managerMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parkSocityTopPro {
    right: -43px;
    left: auto;
    top: 2px;
}

.managerMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parContTx {
    margin-top: 5px;
    color: #878787;
    font-weight: 500;
}

.managerMain .ticketDetailsMain .ticketChatRight .parkSocityInnerTicket .parContTxDate {
    margin-top: 5px;
    color: #0a7400;
    text-align: right;
    font-style: normal !important;
    font-weight: 500;
    letter-spacing: 0.6px !important;
}

.managerMain .ticketDetailsMain .typeComentOut {
    width: 100%;
    float: left;
    padding: 12px 0px 0px;
    border-top: 1px dashed #ccc;
    position: relative;
}

.managerMain .ticketDetailsMain .typeComentOutIco {
    width: 100%;
    float: left;
    position: relative;
    padding-right: 50px;
}

.managerMain .ticketDetailsMain .typeComentOut svg {
    position: absolute;
    right: 11px;
    top: 11px;
    color: #08AE36;
    cursor: pointer;
}

.managerMain .ticketDetailsMain .typeComentOut svg {
    right: 0px;
    top: 11px !important;
    font-size: 28px;
}

.managerMain .ticketDetailsMain .textAriaChat {
    width: 100%;
    border: 1px solid #ccc;
    padding: 9px 15px;
    outline: none;
    border-radius: 5px;
    font-size: 12px;
}

.managerMain .ticketDetailsMain .parkSocityTop h4 {
    font-weight: 500;
    font-size: 13px;
    color: #121210 !important;
    margin-bottom: 2px;
}

.managerMain .timeAlignRight {
    text-align: right !important;
}

.cmmtIcon {
    float: left;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.cmmtIcon svg {
    position: static !important;
}

/* warning details comments end */

/* end manager flow css added by vaishali  */


/* Profile Details New Css */
.newProDetailsBgMain {
    width: 100%;
    float: left;
    background: #001F3F;
    margin-top: 0 !important;
    padding: 15px;
    color: #fff;
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 45px !important;
    position: fixed !important;
    z-index: 9;
    top: 49px;
    padding-bottom: 0;
}

.fixedNewProDetailsBgMain {
    width: 100%;
    float: left;
    height: 150px;
}

.newProDetailsBgMain h2 {
    color: #fff !important;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newProDetailsBgMain button svg {
    color: #fff !important;
}

.newProDetailsPr {
    width: 100%;
    float: left;
    position: relative;
    margin-top: 15px;
    padding-left: 100px;
    padding-bottom: 10px;
    min-height: 60px;
}

.newProDetailsProDivOut {
    width: 85px;
    height: 85px;
    background: #000;
    border-radius: 100%;
    position: absolute;
    left: 0;
    bottom: -20px;
}

.newProDetailsProDiv {
    width: 85px;
    height: 85px;
    background: #000;
    border-radius: 100%;
    overflow: hidden;
    border: 4px solid #fff;
}

.newProDetailsProDiv img {
    width: 100%;
    height: 85px;
    object-fit: cover;
}

.sideProBar {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.newProDetailsPr h1 {
    font-size: 18px;
    font-weight: 400;
}

.newProDetailsPr p {
    font-size: 14px;
    font-weight: 400;
}

.newProEdiBBt {
    position: absolute !important;
    right: -8px;
    bottom: 3px;
    background: #fff !important;
    width: 30px;
    height: 30px;
    overflow: hidden !important;
}

.newProEdiBBt input {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    height: 100%;
}

.myProfileNewTbsMain {
    width: 100%;
    float: left;
}

.myProfileNewTbsMain .MuiTab-root {
    padding-right: 16px !important;
}

.myProfileNewTbsMain .MuiBox-root {
    border: none;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
}

.myProfileNewTbsIner {
    padding-top: 15px;
    float: left;
    width: 100%;
}

.myProfileNewTbsMain .profileData {
    position: relative;
}

.newProEdiIco {
    position: absolute;
    right: 10px;
    top: 19px;
}

.mobModalMain .MuiDialog-paper {
    margin: 0 !important;
    max-width: calc(100% - 20px) !important;
    width: 100%;
}

.mobModalMain .MuiDialogTitle-root {
    font-size: 15px;
    font-weight: 500;
}

.mobModalMain .forModalSpace {
    padding: 15px 0;
}

.mobModalMain .MuiDialogContent-root {
    padding: 20px;
}

.modBBt {
    width: 100%;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #fff !important;
    cursor: pointer !important;
    background-color: #c82d33 !important;
    height: 45px;
    border-radius: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    text-transform: capitalize !important;
    display: inline-flex !important;
}


/* breakfast items new layout */

.newBreakfastLayoutMain {
    float: left;
    width: 100%;
}

.mobileCssMain .newBreakCloseOuter {
    position: relative;
}

.mobileCssMain .newBreakCloseOuter .closeIcon {
    color: #ffffff;
    background-color: #5b5959;
    position: absolute;
    top: 10px;
    right: 10px;
}

.mobileCssMain .newBreakIcon {
    text-align: center;
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mobileCssMain .newBreakItemOuter {
    padding: 10px !important;
    background-color: #9d9d9d !important;
    border-radius: 8px;
    margin: 10px 0;
}

.mobileCssMain .newBreakItemOuter h5 {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff !important;
    text-align: center !important;
    margin-bottom: 4px;
}

.mobileCssMain .newBreakItemOuter svg {
    color: #ffffff !important;
    width: 48px;
    height: auto;
}

.mobileCssMain .newBreakLayoutAddIcon {
    margin: 10px 20px !important;
    width: 34px !important;
}

.mobileCssMain .newBreakActive {
    background-color: #121210 !important;
}

/* breakfast items new layout end */


/* End */


.kailasIncreDi {
    width: 100%;
    float: left;
}

.kailasIncreDi .newContTopSpace {
    display: flex;
    align-items: center;
}

.CoffeeIconK {
    font-size: 50px !important;
}

.SportsSoccerIconK {
    font-size: 50px !important;
}

.kailasIncreDi .breakFtICo svg {
    display: none;
}

.kailasIncreDi .newCountSt .outerIncdec b {
    position: static;
    height: 40px;
    min-width: 40px;
}

.kailasIncreDi .IncDrcBtn svg {
    font-size: 40px;
}

.kailasIncreDi .addedCount .outerIncdec {
    gap: 10px;
}

.kailasIncreDi h5 {
    margin-top: 0 !important;
}

.kailasIncreDi .closeIcon {
    color: #ffffff;
    background-color: #5b5959;
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
}

.activeOrd .closeIcon {
    display: block;
}



/* new css for touchable target area  */

.loginBtnOuter {
    float: right !important;
    text-transform: capitalize !important;
    height: 48px !important;
    margin-top: -20px !important;
}

.TouchableIconBtn {
    /* width: 48px !important; */
    /* height: 48px !important; */
}

.TouchableORBtn {
    float: right;
    width: auto !important;

}

.logoutIcon {
    font-size: 24px !important;
    margin-right: -20px !important;
    color: #000000 !important;
}

.profileOrderCard {
    display: flex;
    flex-direction: column;
}

/* end new css for touchable target area  */


/* SplashVideo */
.spashVideoMain {
    width: 100%;
    float: left;
    background: linear-gradient(to bottom, #040608 0%, #070707 100%) !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spashVideoMain video {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    top: -70px;
}

/* End */

.descriSt {
    margin-top: 10px;
    border-top: 1px dashed #ccc;
    padding-top: 10px;
}

.descriSt h2 {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.shwoAndHideQut .IncDrcBtn {
    display: none !important;
    ;
}

.shwoAndHideQut .countValue {
    padding: 8px 15px;
}

.cateStMain {
    position: absolute;
    top:0;
    left: 11px;
    background: #eee;
    padding: 0px 11px;
    color: #ffffff !important;
    margin-bottom: 0 !important;
    border-radius: 0px 0px 5px 5px;
    font-size: 11px !important;
    background-color: #66CDAA !important;
}

.newSwipS .styles_rsbcText__16wA0 {
    padding: 0 15px;
    color: #464646;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.newSwipS .styles_rsbcSliderText__3h08j {
    font-size: 17px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.newSwipS .styles_rsbContainerUnlocked__1T0vd {
    width: 70% !important;
    margin-left: 15%;
    transition: 0.5s;
    cursor: default;
}

.forgotError .errorSt {
    width: auto !important;
    text-align: left !important;
    float: left !important;
    margin-top: -3px !important;
    background: #fff !important;
    padding: 0 5px !important;
    margin-left: -4px !important;
}

.addedResetTx {
    justify-content: space-between !important;
}

.addedResetTx h2 {
    font-size: 11px;
    color: #333;
}

.dashLogoOut {
    width: 50px;
    height: 40px;
    border-radius: 5px;
    overflow: hidden;
}

.dashLogoOut img {
    width: 100%;
    height: 40px;
    object-fit: contain;
}

.LeaveDetailsDate {
    width: 100%;
    float: left;
    margin-bottom: 15px;
    margin-top: 15px;
}


/* colors for active orders - lunch, dinner, breakfast, star cafe  */

.mobilelunchOrderColor {
    background: #e2fff5 !important;
    border-color: #66CDAA !important;
}

.mobiledinnerOrderColor {
    border-color: #3E3768 !important;
    background-color: #e9e5ff !important;
}

.mobilebreakfastOrderColor {
    background: #fff9d8 !important;
    border-color: #D9B700 !important;
}

.eyeWithError svg {
    top: 45px !important;
}

.mobModalBdScroll form {
    overflow: auto;
    height: calc(100vh - 200px);
}

.placedOrdertx {
    font-size: 13px;
    color: red;
}

::-webkit-scrollbar {
    width: 0;
    opacity: 0 !important;
    visibility: hidden;
}

::-webkit-scrollbar-track {
    opacity: 0 !important;
    visibility: hidden;
}

::-webkit-scrollbar-thumb {
    opacity: 0 !important;
    visibility: hidden;
}

.leaveNewLayoutDate{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5px;
}

.leaveNewLayoutTitle{
    font-size: 12px;
    color: #868686;
    font-weight: 300;
}

.leaveComments{
    font-size: 14px;
    color: #333333;
    font-weight: 300;
    margin-bottom: 10px;
}
.leaveDetailsNewForTop{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
}
.tnkTx{
    font-size: 17px !important;
    color: green !important;
    margin: 20px 0;
    background: #caffc9;
    padding: 9px 5px;
    border-radius: 9px;
    border: 1px solid green;
    font-weight: 600 !important;
}
.showFullTx .productOuter h3{
    white-space: normal;
    font-size: 11px;
    color: #000;
    /* min-height: 50px; */
}
.showFullTx .productOuter{
    min-height: 250px;
}
.showFullTx .productBoxtBottom{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 9px 10px;
}
.minHe{
    height: calc(100vh - 475px);
}
.stickyDiv{
    position: sticky;
    top: 65px;
    z-index: 9;
    background: #fff;
}
.botSticky{
    position: sticky !important;
    bottom: 84px;
    background: #fff;
}
.fromDToDateMain{
    width: 100%;
    float: left;
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}
.fromDToDateMain .MuiFormControl-root {
    min-width: auto !important;
}
.fromDToDateMain .MuiInputBase-root{
    border-radius: 10px;
}
.mobileCssMain .tpAlign{
    align-items: flex-start !important;
}
.spendingSta{

}
.spendingSta b{
    color: #c82d33;
    font-size: 17px;
}


/* for new acgtive order layout  */

.newActiveOrderLayout{
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    padding-left: 0 !important;
    padding: 10px !important;
    padding-top: 25px !important;
    flex-direction: column !important;
    border-left: 5px solid #66CDAA !important;
   
}
.newActiveOrderLayoutInside{
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #e5e5e5 !important;
    border-radius: 0 !important;
}
.cafeCupIcon{
    left: 0 !important;
}
.orderPickedDate{
    width: 100%;
    display: flex;
    align-items: center;
    float: left;
    flex-direction: row !important;
    justify-content: space-between;
}
.orderPickedDate p{
    font-size: 12px !important;
    color: #868686;
}
.orderPickedDate h3{
    font-weight: 600 !important;
    color: #333333 !important;
    font-size: 13px;
}

.orderPickedDate .orderPickedDateRight{
    text-align: right !important;
}

.scanQROuter{
    width: 34px;
    height: 34px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}
.scanQROuter img{
    width: 100%;
    height: auto;
}

.reloadStarMainMob{
    width: 100%;
    float: left;
    padding: 20px 0;
}
.reloadStarMainMob .popUpExchImgOuter h2 {
    font-weight: 400;
    font-size: 14px;
}
.reloadStarMainMob .setExchImgDiv {
    width: 50px;
    height: 50%;
}
.reloadStiBt{
    text-align: center;
    position: sticky;
    bottom: -10px;
}
.rejectedReasn{
    color: red;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
}
.orderNotiDivNewAd{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.orderNotiDivNewAdOut{
    gap: 15px;
    align-items: flex-start !important;
}
.warnDetailCardLft{
    width: 100%;
}
.warnDetailCardRig{
    text-align: right;
    width: 100%;
}
.reloadStarMainMob .addStarContD p{
    text-align: center;
}
.totalStarReNDeduc{
    display: flex;
    font-size: 11px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.totalStarReNDeduc .spendingSta{
    display: flex;
    align-items: center;
    gap: 5px;
    border-right: 1px solid #ccc;
    padding-right: 8px;
}
.totalStarReNDeduc .spendingSta:last-child{
    padding: 0;
    border: none;
}
.deducRefnd{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 5px 10px;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
}
.bottomCon{
    padding-bottom: 55px !important;
}
.bottomCon .ordIcoDiv{
    top: 16px;
    margin-top: 0;
}